import {
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  FormControlLabel,
  Switch,
  TextField,
} from "@mui/material";
import React, { useState } from "react";

const VersionDialog = ({ open, onClose, onCreateVersion }) => {
  const [description, setDescription] = useState("");
  const [activateImmediately, setActivateImmediately] = useState(false);

  const handleCreate = () => {
    onCreateVersion(description, activateImmediately);
    setDescription("");
    setActivateImmediately(false);
    onClose();
  };

  return (
    <Dialog open={open} onClose={onClose}>
      <DialogTitle>Create New Version</DialogTitle>
      <DialogContent>
        <TextField
          autoFocus
          margin="dense"
          label="Version Description"
          type="text"
          fullWidth
          multiline
          rows={4}
          value={description}
          onChange={(e) => setDescription(e.target.value)}
        />
        <FormControlLabel
          control={
            <Switch
              checked={activateImmediately}
              onChange={(e) => setActivateImmediately(e.target.checked)}
              color="primary"
            />
          }
          label="Activate Immediately"
        />
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleCreate} color="primary" variant="contained">
          Create
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default VersionDialog;
