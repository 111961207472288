import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import api from "src/common/utils/api.js";
import { supabase } from "../../../common/utils/supabase";

const API_BASE_URL = "https://api.plot-twist.ai/api/v1";

const getTimestamp = () => new Date().toISOString();
const logWithTimestamp = (message, data = null) => {
  const timestamp = getTimestamp();
  const formattedMessage = JSON.stringify(message);
  const formattedData = JSON.stringify(data);
  if (data) {
    console.log(`[${timestamp}] ${formattedMessage}`, formattedData);
  } else {
    console.log(`[${timestamp}] ${formattedMessage}`);
  }
};

const getAuthToken = async () => {
  const {
    data: { session },
  } = await supabase.auth.getSession();
  if (!session) {
    const { data, error } = await supabase.auth.refreshSession();
    if (error) throw error;
    return data.session?.access_token;
  }
  return session.access_token;
};

export const fetchConversations = createAsyncThunk(
  "chat/fetchConversations",
  async (includeArchived = false) => {
    const response = await api.get(
      `/chat/conversations/?include_archived=${includeArchived}`
    );
    return response.data;
  }
);

export const bulkUpdateConversations = createAsyncThunk(
  "chat/bulkUpdateConversations",
  async ({ conversation_ids, update_data }) => {
    const response = await api.put("/chat/conversations/bulk-update/", {
      conversation_ids,
      update_data,
    });
    return response.data;
  }
);

export const bulkDeleteConversations = createAsyncThunk(
  "chat/bulkDeleteConversations",
  async ({ conversation_ids }) => {
    await api.delete("/chat/conversations/bulk-delete/", {
      data: { conversation_ids },
    });
    return conversation_ids;
  }
);

export const createConversation = createAsyncThunk(
  "chat/createConversation",
  async () => {
    const now = new Date();
    const formattedDate = now.toLocaleString("en-US", {
      day: "2-digit",
      hour: "2-digit",
      hour12: false,
      minute: "2-digit",
      month: "2-digit",
      year: "numeric",
    });
    const name = `New chat - ${formattedDate}`;
    const response = await api.post("/chat/conversations/", { name });
    return response.data;
  }
);

export const updateConversationName = createAsyncThunk(
  "chat/updateConversationName",
  async ({ conversationId, newName }) => {
    const response = await api.put(`/chat/conversations/${conversationId}/`, {
      name: newName,
    });
    return response.data;
  }
);

export const fetchConversationHistory = createAsyncThunk(
  "chat/fetchConversationHistory",
  async (conversationId) => {
    const response = await api.get(
      `/chat/conversations/${conversationId}/messages/`
    );
    return response.data;
  }
);

export const sendMessage = createAsyncThunk(
  "chat/sendMessage",
  async ({ content, conversation_id }, { dispatch }) => {
    // Immediately add the user message to the state
    dispatch(
      chatSlice.actions.addMessage({
        uuid: Date.now().toString(),
        text: content,
        sender: "user",
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      })
    );

    const response = await api.post("/chat/", { content, conversation_id });
    return response.data;
  }
);

export const updateMessage = createAsyncThunk(
  "chat/updateMessage",
  async ({ content, messageId }) => {
    const response = await api.put(`/chat/messages/${messageId}/`, { content });
    return response.data;
  }
);

export const deleteConversation = createAsyncThunk(
  "chat/deleteConversation",
  async (conversationId) => {
    await api.delete(`/chat/conversations/${conversationId}/`);
    return conversationId;
  }
);

export const starConversation = createAsyncThunk(
  "chat/starConversation",
  async ({ conversationId, isStarred }) => {
    const response = await api.put(
      `/chat/conversations/${conversationId}/star/`,
      { is_starred: isStarred }
    );
    return response.data;
  }
);

export const fetchConversationDetails = createAsyncThunk(
  "chat/fetchConversationDetails",
  async (conversationId) => {
    const response = await api.get(`/chat/conversations/${conversationId}/`);
    return response.data;
  }
);

export const sendStreamingMessage = createAsyncThunk(
  "chat/sendStreamingMessage",
  async ({ content, conversation_id }, { dispatch }) => {
    dispatch(
      chatSlice.actions.addMessage({
        uuid: Date.now().toString(),
        text: content,
        sender: "user",
        created_at: new Date().toISOString(),
        updated_at: new Date().toISOString(),
      })
    );
    try {
      let token = await getAuthToken();
      if (!token) {
        throw new Error("No authentication token available");
      }

      const fetchWithAuth = async (url, options) => {
        const response = await fetch(url, {
          ...options,
          headers: {
            ...options.headers,
            Authorization: `Bearer ${token}`,
          },
        });

        if (response.status === 401) {
          // Token might be expired, try to refresh
          token = await getAuthToken();
          if (!token) throw new Error("Failed to refresh authentication token");

          // Retry the request with the new token
          return fetch(url, {
            ...options,
            headers: {
              ...options.headers,
              Authorization: `Bearer ${token}`,
            },
          });
        }

        return response;
      };

      const response = await fetchWithAuth(`${API_BASE_URL}/chat/stream/`, {
        body: JSON.stringify({ content, conversation_id }),
        headers: {
          "Content-Type": "application/json",
        },
        method: "POST",
      });

      if (!response.ok) {
        throw new Error(`HTTP error! status: ${response.status}`);
      }

      const reader = response.body.getReader();
      const decoder = new TextDecoder();

      let buffer = [];
      let completeMessage = "";

      while (true) {
        const { done, value } = await reader.read();
        if (done) {
          logWithTimestamp("Stream complete");
          break;
        }

        buffer.push(decoder.decode(value, { stream: true }));

        while (buffer.length > 0) {
          const chunk = buffer.shift();
          if (chunk.startsWith("data: ")) {
            const datas = chunk.slice(6);
            for (let data of datas.split("data: ")) {
              if (data.trim()) {
                const formatted = data.substring(0, data.length - 2);
                completeMessage += formatted;
                const currentMessage = completeMessage;
                requestAnimationFrame(() => {
                  dispatch(
                    chatSlice.actions.setStreamedMessage(currentMessage)
                  );
                });
              }
            }
          } else if (chunk.startsWith("event: ")) {
            logWithTimestamp("Ignoring event message");
          }
        }
      }

      // Process any remaining data in the buffer
      if (buffer) {
        const chunk = buffer.shift();
        if (typeof chunk === "string") {
          if (chunk.startsWith("data: ")) {
            const data = chunk.slice(6);
            completeMessage += data;
            dispatch(chatSlice.actions.setStreamedMessage(completeMessage));
          }
        }
      }

      dispatch(chatSlice.actions.finalizeStreamedMessage(completeMessage));
    } catch (error) {
      logWithTimestamp("Error in sendStreamingMessage:", error);
      throw error;
    }
  }
);

const chatSlice = createSlice({
  initialState: {
    conversations: [],
    currentConversation: null,
    error: null,
    includeArchived: false,
    messages: [],
    status: "idle",
    streamedMessage: "",
  },
  name: "chat",
  reducers: {
    addMessage: (state, action) => {
      state.messages.push(action.payload);
    },
    clearConversationData: (state) => {
      state.currentConversation = null;
      state.messages = [];
      state.streamedMessage = "";
    },
    clearCurrentConversation: (state) => {
      state.currentConversation = null;
      state.messages = [];
    },
    finalizeStreamedMessage: (state, action) => {
      logWithTimestamp("Finalizing streamed message");
      if (action.payload) {
        state.messages.push({
          created_at: new Date().toISOString(),
          sender: "assistant",
          text: action.payload,
          updated_at: new Date().toISOString(),
          uuid: Date.now().toString(),
        });
        state.streamedMessage = "";
      } else {
        logWithTimestamp("No streamed message to finalize");
      }
    },
    setCurrentConversation: (state, action) => {
      state.currentConversation = action.payload;
    },
    setStreamedMessage: (state, action) => {
      state.streamedMessage = action.payload;
    },
    addMessage: (state, action) => {
      state.messages.push(action.payload);
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(fetchConversations.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchConversations.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.conversations = action.payload;
      })
      .addCase(fetchConversations.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createConversation.fulfilled, (state, action) => {
        state.conversations.unshift(action.payload);
        state.currentConversation = action.payload;
      })
      .addCase(fetchConversationHistory.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchConversationHistory.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.messages = action.payload;
      })
      .addCase(fetchConversationHistory.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(sendMessage.fulfilled, (state, action) => {
        state.messages.push(action.payload.user_message);
        state.messages.push(action.payload.assistant_message);
      })
      .addCase(updateMessage.fulfilled, (state, action) => {
        const index = state.messages.findIndex(
          (msg) => msg.uuid === action.payload.uuid
        );
        if (index !== -1) {
          state.messages[index] = action.payload;
        }
      })
      .addCase(deleteConversation.fulfilled, (state, action) => {
        state.conversations = state.conversations.filter(
          (conv) => conv.id !== action.payload
        );
        if (
          state.currentConversation &&
          state.currentConversation.id === action.payload
        ) {
          state.currentConversation = null;
          state.messages = [];
        }
      })
      .addCase(starConversation.fulfilled, (state, action) => {
        const index = state.conversations.findIndex(
          (conv) => conv.id === action.payload.id
        );
        if (index !== -1) {
          state.conversations[index] = action.payload;
        }
        if (
          state.currentConversation &&
          state.currentConversation.id === action.payload.id
        ) {
          state.currentConversation = action.payload;
        }
      })
      .addCase(sendStreamingMessage.pending, (state) => {
        state.status = "loading";
        state.streamedMessage = "";
      })
      .addCase(sendStreamingMessage.fulfilled, (state) => {
        state.status = "succeeded";
      })
      .addCase(sendStreamingMessage.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(bulkUpdateConversations.fulfilled, (state, action) => {
        state.conversations = state.conversations.map(
          (conv) =>
            action.payload.find((updated) => updated.id === conv.id) || conv
        );
      })
      .addCase(bulkDeleteConversations.fulfilled, (state, action) => {
        state.conversations = state.conversations.filter(
          (conv) => !action.payload.includes(conv.id)
        );
      })
      .addCase(updateConversationName.fulfilled, (state, action) => {
        const index = state.conversations.findIndex(
          (conv) => conv.id === action.payload.id
        );
        if (index !== -1) {
          state.conversations[index] = action.payload;
        }
        if (
          state.currentConversation &&
          state.currentConversation.id === action.payload.id
        ) {
          state.currentConversation = action.payload;
        }
      })
      .addCase(fetchConversationDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchConversationDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.currentConversation = action.payload;
      })
      .addCase(fetchConversationDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export const {
  addMessage,
  clearConversationData,
  clearCurrentConversation,
  finalizeStreamedMessage,
  setCurrentConversation,
  setStreamedMessage,
} = chatSlice.actions;

export default chatSlice.reducer;
