// src/features/jobs/slices/jobSlice.js

import { createSlice } from "@reduxjs/toolkit";
import { fetchJobs, fetchJobDetails } from "../services/jobService";

const jobSlice = createSlice({
  name: "jobs",
  initialState: {
    jobs: [],
    status: "idle",
    error: null,
    totalCount: 0,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchJobs.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchJobs.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.jobs = action.payload.items;
        state.totalCount = action.payload.total;
      })
      .addCase(fetchJobs.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchJobDetails.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchJobDetails.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.currentJob = action.payload;
      })
      .addCase(fetchJobDetails.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default jobSlice.reducer;
