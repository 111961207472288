import CloseIcon from "@mui/icons-material/Close";
import EditIcon from "@mui/icons-material/Edit";
import { Box, Button, IconButton, TextField, Typography } from "@mui/material";
import React, { useState } from "react";
import { useReactFlow } from "reactflow";

const BlockDetails = ({ node, onClose }) => {
  const [isEditing, setIsEditing] = useState(false);
  const [nodeName, setNodeName] = useState(node.data.label);
  const { setNodes } = useReactFlow();

  const handleNameChange = () => {
    setIsEditing(false);
    setNodes((nds) =>
      nds.map((n) => {
        if (n.id === node.id) {
          return { ...n, data: { ...n.data, label: nodeName } };
        }
        return n;
      })
    );
  };

  return (
    <Box
      sx={{
        position: "absolute",
        top: 120,
        right: 10,
        width: "300px",
        height: "calc(100% - 64px)",
        backgroundColor: "white",
        boxShadow: "-2px 0 5px rgba(0, 0, 0, 0.1)",
        padding: 2,
        overflowY: "auto",
      }}
    >
      <Box
        sx={{
          display: "flex",
          justifyContent: "space-between",
          alignItems: "center",
          mb: 2,
        }}
      >
        {isEditing ? (
          <TextField
            value={nodeName}
            onChange={(e) => setNodeName(e.target.value)}
            onBlur={handleNameChange}
            onKeyPress={(e) => e.key === "Enter" && handleNameChange()}
            autoFocus
          />
        ) : (
          <Typography variant="h6">{node.data.label}</Typography>
        )}
        <Box>
          <IconButton onClick={() => setIsEditing(!isEditing)}>
            <EditIcon />
          </IconButton>
          <IconButton onClick={onClose}>
            <CloseIcon />
          </IconButton>
        </Box>
      </Box>
      <Typography variant="subtitle1" sx={{ mb: 1 }}>
        INPUT FIELD
      </Typography>
      {node.data.fields &&
        node.data.fields.map((field, index) => (
          <Box
            key={index}
            sx={{ display: "flex", alignItems: "center", mb: 1 }}
          >
            <Typography>{field.icon}</Typography>
            <Typography sx={{ ml: 1 }}>{field.name}</Typography>
          </Box>
        ))}
      <Button variant="outlined" fullWidth sx={{ mt: 2 }}>
        + Add Input Field
      </Button>
    </Box>
  );
};

export default BlockDetails;
