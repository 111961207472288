import { createTheme } from "@mui/material/styles";
import baseTheme from "../theme/baseTheme";

const lightTheme = createTheme({
  ...baseTheme,
  palette: {
    mode: "light",
    primary: {
      main: "#3498db",
      light: "#5dade2",
      dark: "#2980b9",
    },
    secondary: {
      main: "#2ecc71",
      light: "#58d68d",
      dark: "#27ae60",
    },
    background: {
      default: "#f4f6f8",
    },
  },
});

export default lightTheme;
