import React, { useState, useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTaskTemplates } from "../../taskTemplates/services/taskTemplateService";
import { createTask } from "../services/taskService";
import { useNavigate } from "react-router-dom";
import {
  Typography,
  TextField,
  Button,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  CircularProgress,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";

const Form = styled("form")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  gap: theme.spacing(2),
  maxWidth: 400,
  margin: "0 auto",
}));

function CreateTask() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { taskTemplates, status } = useSelector((state) => state.taskTemplates);
  const [taskData, setTaskData] = useState({
    title: "",
    templateId: "",
    content: "",
  });

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchTaskTemplates());
    }
  }, [status, dispatch]);

  const handleChange = (e) => {
    setTaskData({ ...taskData, [e.target.name]: e.target.value });
  };

  const handleSubmit = async (e) => {
    e.preventDefault();
    try {
      await dispatch(createTask(taskData)).unwrap();
      navigate("/app/dashboard");
    } catch (err) {
      console.error("Failed to create the task:", err);
    }
  };

  if (status === "loading") {
    return <CircularProgress />;
  }

  if (status === "failed") {
    return <Typography color="error">Error loading task templates</Typography>;
  }

  return (
    <Form onSubmit={handleSubmit}>
      <Typography variant="h4" gutterBottom>
        Create New Task
      </Typography>
      <TextField
        name="title"
        label="Task Title"
        value={taskData.title}
        onChange={handleChange}
        required
        fullWidth
      />
      <FormControl fullWidth>
        <InputLabel id="template-select-label">Task Template</InputLabel>
        <Select
          labelId="template-select-label"
          name="templateId"
          value={taskData.templateId}
          onChange={handleChange}
          required
          label="Task Template"
        >
          {Array.isArray(taskTemplates) && taskTemplates.length > 0 ? (
            taskTemplates.map((template) => (
              <MenuItem key={template.id} value={template.id}>
                {template.title}
              </MenuItem>
            ))
          ) : (
            <MenuItem disabled>No templates available</MenuItem>
          )}
        </Select>
      </FormControl>
      <TextField
        name="content"
        label="Task Content"
        multiline
        rows={4}
        value={taskData.content}
        onChange={handleChange}
        required
        fullWidth
      />
      <Button type="submit" variant="contained" color="primary" fullWidth>
        Create Task
      </Button>
    </Form>
  );
}

export default CreateTask;
