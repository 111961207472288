import AddIcon from "@mui/icons-material/Add";
import { IconButton } from "@mui/material";
import React, { useState } from "react";
import { getBezierPath } from "reactflow";

const CustomEdge = ({
  id,
  sourceX,
  sourceY,
  targetX,
  targetY,
  sourcePosition,
  targetPosition,
  style = {},
  data,
  markerEnd,
}) => {
  const [edgePath, labelX, labelY] = getBezierPath({
    sourceX,
    sourceY,
    sourcePosition,
    targetX,
    targetY,
    targetPosition,
  });

  const [isHovered, setIsHovered] = useState(false);

  const onEdgeClick = (evt, id) => {
    evt.stopPropagation();
    alert(`Add block between edge ${id}`);
  };

  return (
    <>
      <path
        id={id}
        style={style}
        className="react-flow__edge-path"
        d={edgePath}
        markerEnd={markerEnd}
        onMouseEnter={() => setIsHovered(true)}
        onMouseLeave={() => setIsHovered(false)}
      />
      {isHovered && (
        <foreignObject
          width={40}
          height={40}
          x={labelX - 20}
          y={labelY - 20}
          className="edgebutton-foreignobject"
          requiredExtensions="http://www.w3.org/1999/xhtml"
        >
          <IconButton
            onClick={(event) => onEdgeClick(event, id)}
            style={{
              width: 40,
              height: 40,
              background: "white",
              borderRadius: "50%",
            }}
          >
            <AddIcon />
          </IconButton>
        </foreignObject>
      )}
    </>
  );
};

export default CustomEdge;
