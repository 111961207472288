import React from "react";
import { Box, Typography } from "@mui/material";
import { styled, keyframes } from "@mui/material/styles";
import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";

const OverlayContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  bottom: 0,
  backgroundColor: "rgba(255, 255, 255, 0.9)",
  display: "flex",
  flexDirection: "column",
  justifyContent: "flex-start",
  alignItems: "flex-start",
  padding: theme.spacing(2),
  zIndex: 1,
}));

const StatusItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(1),
  position: "relative",
}));

const StatusText = styled(Typography)(({ theme, completed, current, failed }) => ({
  color: failed ? theme.palette.error.main :
         current ? theme.palette.primary.main :
         completed ? theme.palette.text.secondary : theme.palette.text.primary,
  marginLeft: theme.spacing(1),
}));

const StatusIconContainer = styled(Box)(({ theme }) => ({
  width: 24,
  height: 24,
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  position: "relative",
}));

const ConnectingLine = styled(Box)(({ theme, isLast }) => ({
  position: "absolute",
  top: 24,
  left: 12,
  width: 2,
  height: isLast ? 0 : "calc(100% + 8px)",
  backgroundColor: theme.palette.divider,
}));

const dotAnimation = keyframes`
  0% { content: '.  '; }
  33% { content: '.. '; }
  66% { content: '...'; }
  100% { content: '   '; }
`;

const AnimatedDots = styled('div')(({ theme }) => ({
  width: 24,
  height: 24,
  borderRadius: '50%',
  backgroundColor: theme.palette.primary.main,
  display: 'flex',
  justifyContent: 'center',
  alignItems: 'center',
  color: theme.palette.common.white,
  fontSize: '18px',
  lineHeight: '24px',
  '&::after': {
    content: "'.'",
    animation: `${dotAnimation} 1.5s steps(4, end) infinite`,
  },
}));

const StatusOverlay = ({ currentStatus, completedStatuses }) => {
  const allStatuses = [...completedStatuses, currentStatus].filter((status, index, self) => 
    self.indexOf(status) === index
  );

  return (
    <OverlayContainer>
      {allStatuses.map((status, index) => {
        const isCompleted = completedStatuses.includes(status);
        const isCurrent = currentStatus === status;
        const isFailed = currentStatus === "failed" && isCurrent;
        const isLast = index === allStatuses.length - 1;

        return (
          <StatusItem key={index}>
            <StatusIconContainer>
              {isCompleted ? (
                <CheckCircleIcon color="success" />
              ) : isCurrent ? (
                isFailed ? (
                  <ErrorIcon color="error" />
                ) : (
                  <AnimatedDots />
                )
              ) : null}
              {!isLast && <ConnectingLine isLast={isLast} />}
            </StatusIconContainer>
            <StatusText completed={isCompleted} current={isCurrent} failed={isFailed}>
              {status}
            </StatusText>
          </StatusItem>
        );
      })}
    </OverlayContainer>
  );
};

export default StatusOverlay;