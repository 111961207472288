import React from "react";
import { Typography, Button, Box } from "@mui/material";
import { styled } from "@mui/material/styles";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.bubble.css";

const Root = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
}));

const ButtonsContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: theme.spacing(2),
}));

const ActionButton = styled(Button)(({ theme }) => ({
  marginLeft: theme.spacing(1),
}));

const RichTextOutput = styled(Box)(({ theme }) => ({
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
  padding: theme.spacing(1),
  "& .ql-editor": {
    whiteSpace: "pre-wrap",
  },
}));

const PreserveWhitespace = styled(Box)({
  whiteSpace: "pre-wrap",
  wordWrap: "break-word",
});

const RichTextWidget = ({ value }) => {
  // Convert newline characters to <br> tags
  const formattedValue = value ? value.replace(/\n/g, "<br>") : "";

  return (
    <RichTextOutput>
      <ReactQuill
        value={formattedValue}
        readOnly={true}
        theme="bubble"
        modules={{ toolbar: false }}
      />
    </RichTextOutput>
  );
};

const OutputView = ({ schema, uiSchema, formData, onButtonClick }) => {
  if (!schema) {
    return <Typography color="error">Missing schema</Typography>;
  }

  if (!formData) {
    return <Typography color="error">Missing data</Typography>;
  }

  const customWidgets = {
    TextWidget: ({ value }) => (
      <PreserveWhitespace>
        <Typography component="span">{value}</Typography>
      </PreserveWhitespace>
    ),
    TextareaWidget: ({ value }) => (
      <PreserveWhitespace>{value}</PreserveWhitespace>
    ),
    "rich-text": RichTextWidget,
  };

  const customUiSchema = {
    ...uiSchema,
    "ui:readonly": true,
    "ui:options": {
      ...uiSchema["ui:options"],
      widgetMap: {
        ...uiSchema["ui:options"]?.widgetMap,
        "rich-text": "rich-text",
      },
    },
  };

  return (
    <Root>
      <Form
        schema={schema}
        uiSchema={customUiSchema}
        formData={formData}
        validator={validator}
        disabled={true}
        showErrorList={false}
        widgets={customWidgets}
      >
        <></>
      </Form>
      {uiSchema["ui:customButtons"] && (
        <ButtonsContainer>
          {uiSchema["ui:customButtons"].map((button, index) => (
            <ActionButton
              key={index}
              variant="contained"
              color="primary"
              onClick={() => onButtonClick(button.action, formData)}
            >
              {button.label}
            </ActionButton>
          ))}
        </ButtonsContainer>
      )}
    </Root>
  );
};

export default OutputView;
