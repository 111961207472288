import {
  Add as AddIcon,
  Delete as DeleteIcon,
  DragIndicator as DragIcon,
  Settings as SettingsIcon,
} from "@mui/icons-material";
import { Box, Button, IconButton, Paper, TextField } from "@mui/material";
import React, { useEffect, useState } from "react";
import { DragDropContext, Draggable, Droppable } from "react-beautiful-dnd";
import AdvancedSettingsDialog from "./AdvancedSettingsDialog";
import JSONPreviewDialog from "./JSONPreviewDialog";

const fieldTypes = [
  { value: "string", label: "Text" },
  { value: "number", label: "Number" },
  { value: "boolean", label: "Checkbox" },
  { value: "array", label: "List" },
];

const cleanEnumArray = (enumArray) => {
  return [...new Set(enumArray)].filter(
    (item) => item !== null && item !== undefined && item !== ""
  );
};

const logEnumChanges = (source, oldEnum, newEnum) => {
  console.log(`Enum changed in ${source}:`);
  console.log("Old enum:", oldEnum);
  console.log("New enum:", newEnum);
  if (newEnum.length !== new Set(newEnum).size) {
    console.warn("WARNING: New enum contains duplicates!");
    console.log(
      "Duplicate values:",
      newEnum.filter((item, index) => newEnum.indexOf(item) !== index)
    );
  }
};

const CustomFormBuilder = ({
  schema,
  uiSchema,
  onChange,
  jinjaVariables = [],
  prompt,
}) => {
  const [fields, setFields] = useState([]);
  const [editingField, setEditingField] = useState(null);
  const [isAdvancedSettingsOpen, setIsAdvancedSettingsOpen] = useState(false);
  const [isJSONPreviewOpen, setIsJSONPreviewOpen] = useState(false);
  const [currentSchema, setCurrentSchema] = useState(schema);
  const [currentUiSchema, setCurrentUiSchema] = useState(uiSchema);

  useEffect(() => {
    const schemaFields = Object.entries(schema.properties || {}).map(
      ([key, value]) => ({
        id: key,
        type: value.type,
        title: value.title || key,
        description: value.description || "",
        required: schema.required?.includes(key) || false,
        ...value,
        uiOptions: uiSchema[key] || {},
      })
    );

    if (uiSchema["ui:order"]) {
      schemaFields.sort((a, b) => {
        const orderA = uiSchema["ui:order"].indexOf(a.id);
        const orderB = uiSchema["ui:order"].indexOf(b.id);
        return orderA - orderB;
      });
    }

    setFields(schemaFields);
    setCurrentSchema(schema);
    setCurrentUiSchema(uiSchema);
  }, [schema, uiSchema]);

  const onDragEnd = (result) => {
    if (!result.destination) return;
    const newFields = Array.from(fields);
    const [reorderedItem] = newFields.splice(result.source.index, 1);
    newFields.splice(result.destination.index, 0, reorderedItem);
    setFields(newFields);
    updateSchema(newFields);
  };

  const addField = () => {
    const newField = {
      id: `field_${Date.now()}`,
      type: "string",
      title: "New Field",
      description: "Field description",
      required: false,
      uiOptions: {},
    };
    const newFields = [...fields, newField];
    setFields(newFields);
    updateSchema(newFields);
  };

  const deleteField = (id) => {
    const newFields = fields.filter((field) => field.id !== id);
    setFields(newFields);
    updateSchema(newFields);
  };

  const handleFieldChange = (id, key, value) => {
    const newFields = fields.map((field) =>
      field.id === id ? { ...field, [key]: value } : field
    );
    setFields(newFields);
    updateSchema(newFields);
  };

  const openAdvancedSettings = (field) => {
    setEditingField(field);
    setIsAdvancedSettingsOpen(true);
  };

  const handleAdvancedSettingsClose = (updatedField) => {
    if (updatedField && updatedField.enum) {
      logEnumChanges("CustomFormBuilder", editingField.enum, updatedField.enum);
    }
    if (updatedField) {
      const newFields = fields.map((f) => {
        if (f.id === updatedField.id) {
          // Clean up enum values if present
          if (updatedField.enum && Array.isArray(updatedField.enum)) {
            updatedField.enum = cleanEnumArray(updatedField.enum);
          }
          return { ...f, ...updatedField };
        }
        return f;
      });
      setFields(newFields);
      updateSchema(newFields);
    }
    setIsAdvancedSettingsOpen(false);
    setEditingField(null);
  };

  const updateSchema = (newFields) => {
    const newSchema = {
      type: "object",
      properties: newFields.reduce((acc, field) => {
        acc[field.id] = {
          type: field.enum && field.enum.length > 0 ? "string" : field.type,
          title: field.title,
          description: field.description,
          ...(field.enum && { enum: cleanEnumArray(field.enum) }),
          ...(field.default !== undefined && { default: field.default }),
          ...(field.items && { items: field.items }),
          ...(field.properties && { properties: field.properties }),
          ...(field.minLength !== undefined && { minLength: field.minLength }),
          ...(field.maxLength !== undefined && { maxLength: field.maxLength }),
          ...(field.minimum !== undefined && { minimum: field.minimum }),
          ...(field.maximum !== undefined && { maximum: field.maximum }),
          ...(field.multipleOf !== undefined && {
            multipleOf: field.multipleOf,
          }),
        };
        return acc;
      }, {}),
      required: newFields
        .filter((field) => field.required)
        .map((field) => field.id),
    };

    const newUiSchema = newFields.reduce((acc, field) => {
      acc[field.id] = {
        ...currentUiSchema[field.id],
        "ui:description": field.description,
        ...field.uiOptions,
      };
      return acc;
    }, {});

    newUiSchema["ui:order"] = newFields.map((field) => field.id);

    setCurrentSchema(newSchema);
    setCurrentUiSchema(newUiSchema);
    onChange(newSchema, newUiSchema);
  };

  const getUnassignedJinjaVariables = () => {
    const assignedVariables = fields.map((field) => field.id);
    return jinjaVariables.filter(
      (variable) => !assignedVariables.includes(variable)
    );
  };

  const addMissingJinjaVariables = () => {
    const unassignedVariables = getUnassignedJinjaVariables();
    const newFields = [
      ...fields,
      ...unassignedVariables.map((variable) => ({
        id: variable,
        type: "string",
        title: variable,
        description: `Value for ${variable}`,
        required: false,
        uiOptions: {},
      })),
    ];
    setFields(newFields);
    updateSchema(newFields);
  };

  const handleOpenJSONPreview = () => {
    setIsJSONPreviewOpen(true);
  };

  const handleCloseJSONPreview = () => {
    setIsJSONPreviewOpen(false);
  };

  const handleSaveJSON = (updatedJsonSchema, updatedUiSchema) => {
    Object.entries(updatedJsonSchema.properties || {}).forEach(
      ([key, value]) => {
        if (value.enum) {
          const oldEnum = currentSchema.properties[key]?.enum || [];
          logEnumChanges(`JSON Editor - ${key}`, oldEnum, value.enum);
        }
      }
    );
    console.log("Handling JSON save:", { updatedJsonSchema, updatedUiSchema });

    const newFields = Object.entries(updatedJsonSchema.properties || {}).map(
      ([key, value]) => {
        const field = {
          id: key,
          type: value.enum && value.enum.length > 0 ? "enum" : value.type,
          title: value.title || key,
          description: value.description || "",
          required: updatedJsonSchema.required?.includes(key) || false,
          ...value,
          uiOptions: updatedUiSchema[key] || {},
        };

        if (field.enum && Array.isArray(field.enum)) {
          field.enum = cleanEnumArray(field.enum);
        }

        return field;
      }
    );
    console.log("New fields:", newFields);
    console.log("New updatedJsonSchema:", updatedJsonSchema);

    setFields(newFields);
    setCurrentSchema(updatedJsonSchema);
    setCurrentUiSchema(updatedUiSchema);
    onChange(updatedJsonSchema, updatedUiSchema);
  };

  const handleFieldTypeChange = (id, newType) => {
    const newFields = fields.map((field) => {
      if (field.id === id) {
        const updatedField = { ...field, type: newType };
        if (newType === "enum") {
          updatedField.enum = updatedField.enum || [];
        } else {
          delete updatedField.enum;
        }
        return updatedField;
      }
      return field;
    });
    setFields(newFields);
    updateSchema(newFields);
  };

  return (
    <Box sx={{ height: "100%", display: "flex", flexDirection: "column" }}>
      <DragDropContext onDragEnd={onDragEnd}>
        <Droppable droppableId="form-fields">
          {(provided) => (
            <Box
              {...provided.droppableProps}
              ref={provided.innerRef}
              sx={{ flexGrow: 1, overflowY: "auto" }}
            >
              {fields.map((field, index) => (
                <Draggable key={field.id} draggableId={field.id} index={index}>
                  {(provided) => (
                    <Paper
                      ref={provided.innerRef}
                      {...provided.draggableProps}
                      {...provided.dragHandleProps}
                      elevation={2}
                      sx={{
                        mb: 2,
                        p: 2,
                        display: "flex",
                        alignItems: "center",
                      }}
                    >
                      <DragIcon sx={{ mr: 2 }} />
                      <Box flexGrow={1}>
                        <TextField
                          value={field.title}
                          onChange={(e) =>
                            handleFieldChange(field.id, "title", e.target.value)
                          }
                          variant="standard"
                          fullWidth
                        />
                      </Box>
                      <IconButton onClick={() => openAdvancedSettings(field)}>
                        <SettingsIcon />
                      </IconButton>
                      <IconButton onClick={() => deleteField(field.id)}>
                        <DeleteIcon />
                      </IconButton>
                    </Paper>
                  )}
                </Draggable>
              ))}
              {provided.placeholder}
            </Box>
          )}
        </Droppable>
      </DragDropContext>
      <Box sx={{ mt: 2, display: "flex", justifyContent: "space-between" }}>
        <Button startIcon={<AddIcon />} onClick={addField} variant="outlined">
          Add Field
        </Button>
        <Button
          onClick={addMissingJinjaVariables}
          variant="outlined"
          color="secondary"
        >
          Add Missing Jinja Variables
        </Button>
        <Button
          onClick={handleOpenJSONPreview}
          variant="contained"
          color="primary"
        >
          Preview JSON
        </Button>
      </Box>
      {editingField && (
        <AdvancedSettingsDialog
          open={isAdvancedSettingsOpen}
          onClose={handleAdvancedSettingsClose}
          field={editingField}
          unassignedJinjaVariables={getUnassignedJinjaVariables()}
        />
      )}
      <JSONPreviewDialog
        open={isJSONPreviewOpen}
        onClose={handleCloseJSONPreview}
        jsonSchema={currentSchema}
        uiSchema={currentUiSchema}
        formData={{}} // You might want to provide some sample form data here
        onSave={handleSaveJSON}
      />
    </Box>
  );
};

export default CustomFormBuilder;
