import { ReactFlowProvider } from "reactflow";
import WorkflowEditor from "./WorkflowEditor";

const Workflow = () => {
  return (
    <ReactFlowProvider>
      <WorkflowEditor />
    </ReactFlowProvider>
  );
};

export default Workflow;
