import {
  Autocomplete,
  Box,
  Button,
  Slider,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";
import React from "react";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const StyledBox = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: "100%",
  overflow: "hidden",
}));

const ScrollableContent = styled(Box)(({ theme }) => ({
  overflowY: "auto",
  flexGrow: 1,
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(10),
}));

const ButtonContainer = styled(Box)(({ theme }) => ({
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderTop: `1px solid ${theme.palette.divider}`,
}));

const ErrorMessage = styled(Typography)(({ theme }) => ({
  color: theme.palette.error.main,
  marginBottom: theme.spacing(2),
}));

const EnhancedSelectWidget = ({
  id,
  schema,
  label,
  required,
  disabled,
  readonly,
  value,
  onChange,
  options,
  uiSchema = {},
}) => {
  const allowCustomValues = uiSchema["ui:options"]?.allowCustomValues || false;
  const [inputValue, setInputValue] = React.useState("");
  const [error, setError] = React.useState(null);

  const enumOptions =
    schema.enum || options.enumOptions?.map((o) => o.value) || [];

  const handleChange = (event, newValue) => {
    if (allowCustomValues && newValue && !enumOptions.includes(newValue)) {
      // For custom values, we create an object with an additional property
      onChange({
        [schema.title || id]: newValue,
        __isCustomValue: true,
      });
      setError("This is a custom value.");
    } else {
      onChange(newValue);
      setError(null);
    }
  };

  return (
    <Box>
      <Autocomplete
        id={id}
        options={enumOptions}
        value={
          value && value.__isCustomValue ? value[schema.title || id] : value
        }
        onChange={handleChange}
        inputValue={inputValue}
        onInputChange={(event, newInputValue) => {
          setInputValue(newInputValue);
        }}
        disabled={disabled || readonly}
        freeSolo={allowCustomValues}
        renderInput={(params) => (
          <TextField
            {...params}
            label={label || schema.title}
            required={required}
            variant="outlined"
            error={!!error}
            helperText={error}
          />
        )}
      />
    </Box>
  );
};

const RichTextWidget = ({ id, value, onChange, readonly }) => (
  <ReactQuill
    value={value || ""}
    onChange={onChange}
    readOnly={readonly}
    theme="snow"
  />
);

const EnumSliderWidget = ({
  id,
  schema,
  value,
  required,
  disabled,
  readonly,
  onChange,
  onBlur,
  onFocus,
  label,
}) => {
  const enumOptions = schema.enum;
  const valueIndex = enumOptions.indexOf(value);
  const defaultValue = schema.default;

  const handleSliderChange = (event, newValue) => {
    onChange(enumOptions[newValue]);
  };

  const getMarks = () => {
    const marks = [];
    const lastIndex = enumOptions.length - 1;

    marks.push({
      value: 0,
      label: enumOptions[0],
      style: {
        left: "0%",
        transform: "translateX(0%)",
        whiteSpace: "nowrap",
      },
    });

    if (
      defaultValue &&
      defaultValue !== enumOptions[0] &&
      defaultValue !== enumOptions[lastIndex]
    ) {
      const defaultIndex = enumOptions.indexOf(defaultValue);
      marks.push({
        value: defaultIndex,
        label: defaultValue,
        style: {
          transform: "translateX(-50%)",
          whiteSpace: "nowrap",
        },
      });
    }

    marks.push({
      value: lastIndex,
      label: enumOptions[lastIndex],
      style: {
        left: "100%",
        transform: "translateX(-100%)",
        whiteSpace: "nowrap",
      },
    });

    return marks;
  };

  return (
    <Box sx={{ width: "80%", mt: 2, mb: 2, ml: 3 }}>
      <Typography variant="body1" gutterBottom>
        {label} : <strong>{value}</strong>
      </Typography>
      <Box sx={{ px: 1 }}>
        {" "}
        {/* Add padding to prevent label cutoff */}
        <Slider
          value={valueIndex}
          onChange={handleSliderChange}
          step={1}
          marks={getMarks()}
          min={0}
          max={enumOptions.length - 1}
          valueLabelDisplay="auto"
          valueLabelFormat={(index) => enumOptions[index]}
          disabled={disabled || readonly}
        />
      </Box>
    </Box>
  );
};

const InputForm = ({
  schema,
  uiSchema,
  formData,
  onSubmit,
  readOnly,
  customValidate,
  isCompactLayout,
  isPreview = false,
}) => {
  const [error, setError] = React.useState(null);

  const handleSubmit = ({ formData }, event) => {
    event.preventDefault();
    if (!readOnly) {
      try {
        // Process custom values before validation and submission
        const processedFormData = processCustomValues(formData);

        if (customValidate) {
          customValidate(processedFormData);
        }
        setError(null);
        onSubmit(processedFormData);
      } catch (err) {
        setError(err.message);
      }
    }
  };

  const processCustomValues = (data) => {
    const processed = { ...data };
    Object.keys(processed).forEach((key) => {
      if (processed[key] && processed[key].__isCustomValue) {
        processed[key] = processed[key][key];
      }
    });
    return processed;
  };

  const handleError = (errors) => {
    console.log("Form Errors:", errors);
    setError("There are errors in the form. Please check your inputs.");
  };

  const customWidgets = {
    "rich-text": RichTextWidget,
    enumSlider: EnumSliderWidget,
    SelectWidget: EnhancedSelectWidget,
  };

  const compactUiSchema = {
    ...uiSchema,
    "ui:readonly": readOnly,
    "ui:options": {
      ...uiSchema["ui:options"],
    },
    ...(isCompactLayout && {
      "ui:ObjectFieldTemplate": (props) =>
        CustomObjectFieldTemplate({ ...props, isCompactLayout }),
      "ui:FieldTemplate": CustomFieldTemplate,
    }),
  };

  return (
    <StyledBox>
      <ScrollableContent>
        {error && <ErrorMessage>{error}</ErrorMessage>}
        <Form
          schema={schema}
          uiSchema={compactUiSchema}
          formData={formData}
          onSubmit={handleSubmit}
          onError={handleError}
          validator={validator}
          disabled={readOnly}
          showErrorList={false}
          widgets={customWidgets}
          customValidate={(formData, errors) => {
            // Custom validation to allow custom values
            Object.keys(formData).forEach((key) => {
              if (formData[key] && formData[key].__isCustomValue) {
                delete errors[key];
              }
            });
            return errors;
          }}
        >
          <Button type="submit" style={{ display: "none" }} />
        </Form>
      </ScrollableContent>
      <ButtonContainer>
        <Button
          type="submit"
          variant="contained"
          color="primary"
          fullWidth
          disabled={readOnly}
          onClick={() =>
            document.querySelector('button[type="submit"]').click()
          }
        >
          Submit
        </Button>
      </ButtonContainer>
    </StyledBox>
  );
};

const CustomObjectFieldTemplate = ({
  properties,
  title,
  description,
  isCompactLayout,
}) => (
  <Box>
    {title && <Typography variant="h6">{title}</Typography>}
    {!isCompactLayout && description && (
      <Typography variant="body2">{description}</Typography>
    )}
    {properties.map((prop) => (
      <Box key={prop.name} mb={1}>
        {prop.content}
      </Box>
    ))}
  </Box>
);

const CustomFieldTemplate = ({ id, label, children, errors, help }) => (
  <Box mb={1}>
    <Typography variant="body2" component="label" htmlFor={id}>
      {label}
    </Typography>
    {children}
    {errors}
    {help}
  </Box>
);

export default InputForm;
