import React, { useContext, useState } from "react";
import {
  Typography,
  Container,
  Box,
  useTheme,
  Drawer,
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import PersonIcon from "@mui/icons-material/Person";
import AdminPanelSettingsIcon from "@mui/icons-material/AdminPanelSettings";
import PaletteIcon from "@mui/icons-material/Palette";
import DescriptionIcon from "@mui/icons-material/Description";
import UserSettings from "./UserSettings";
import AccountSettings from "./AccountSettings";
import AppearanceSettings from "./AppearanceSettings";
import ContentSettings from "./ContentSettings";

const drawerWidth = 240;

const Root = styled(Box)(({ theme }) => ({
  display: "flex",
  height: "100%",
}));

const Main = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  marginTop: 112, // Height of AppBar (64px) + MenuBar (48px)
  height: `calc(100vh - 112px)`,
  overflowY: "auto",
}));

const StyledDrawer = styled(Drawer)(({ theme }) => ({
  width: drawerWidth,
  flexShrink: 0,
  "& .MuiDrawer-paper": {
    width: drawerWidth,
    boxSizing: "border-box",
    top: 112, // Height of AppBar (64px) + MenuBar (48px)
    height: `calc(100% - 112px)`,
  },
}));

function Settings() {
  const [selectedSetting, setSelectedSetting] = useState("user");

  const renderSettingsContent = () => {
    switch (selectedSetting) {
      case "user":
        return <UserSettings />;
      case "account":
        return <AccountSettings />;
      case "appearance":
        return <AppearanceSettings />;
      case "content":
        return <ContentSettings />;
      default:
        return <UserSettings />;
    }
  };

  return (
    <Root>
      <StyledDrawer variant="permanent" anchor="left">
        <List>
          <ListItem button onClick={() => setSelectedSetting("user")}>
            <ListItemIcon>
              <PersonIcon />
            </ListItemIcon>
            <ListItemText primary="User Settings" />
          </ListItem>
          <ListItem button onClick={() => setSelectedSetting("account")}>
            <ListItemIcon>
              <AdminPanelSettingsIcon />
            </ListItemIcon>
            <ListItemText primary="Account Settings" />
          </ListItem>
          <ListItem button onClick={() => setSelectedSetting("appearance")}>
            <ListItemIcon>
              <PaletteIcon />
            </ListItemIcon>
            <ListItemText primary="Appearance Settings" />
          </ListItem>
          <ListItem button onClick={() => setSelectedSetting("content")}>
            <ListItemIcon>
              <DescriptionIcon />
            </ListItemIcon>
            <ListItemText primary="Content Settings" />
          </ListItem>
        </List>
      </StyledDrawer>
      <Main>{renderSettingsContent()}</Main>
    </Root>
  );
}

export default Settings;
