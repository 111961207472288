import React, { useState } from "react";
import { Typography, Box, TextField, Button } from "@mui/material";
import { styled } from "@mui/material/styles";
import ReactQuill from "react-quill";
import "react-quill/dist/quill.snow.css";

const Root = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const Section = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const StyledReactQuill = styled(ReactQuill)(({ theme }) => ({
  "& .ql-container": {
    borderBottomLeftRadius: 8,
    borderBottomRightRadius: 8,
  },
  "& .ql-toolbar": {
    borderTopLeftRadius: 8,
    borderTopRightRadius: 8,
  },
}));

function UserSettings() {
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");
  const [emailSignature, setEmailSignature] = useState("");

  const handleSave = () => {
    console.log("Saving user settings:", {
      firstName,
      lastName,
      emailSignature,
    });
  };

  return (
    <Root>
      <Typography variant="h4" gutterBottom>
        User Settings
      </Typography>
      <Section>
        <TextField
          fullWidth
          label="First Name"
          variant="outlined"
          margin="normal"
          value={firstName}
          onChange={(e) => setFirstName(e.target.value)}
        />
        <TextField
          fullWidth
          label="Last Name"
          variant="outlined"
          margin="normal"
          value={lastName}
          onChange={(e) => setLastName(e.target.value)}
        />
      </Section>
      <Section>
        <Typography variant="h6" gutterBottom>
          Email Signature
        </Typography>
        <StyledReactQuill
          theme="snow"
          value={emailSignature}
          onChange={setEmailSignature}
          modules={{
            toolbar: [
              [{ header: "1" }, { header: "2" }, { font: [] }],
              [{ size: [] }],
              ["bold", "italic", "underline", "strike", "blockquote"],
              [
                { list: "ordered" },
                { list: "bullet" },
                { indent: "-1" },
                { indent: "+1" },
              ],
              ["link", "image"],
              ["clean"],
            ],
          }}
        />
      </Section>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        style={{ marginTop: 16 }}
      >
        Save User Settings
      </Button>
    </Root>
  );
}

export default UserSettings;
