import React from "react";
import { useSelector } from "react-redux";
import { Navigate, Route, Routes } from "react-router-dom";
import AppLayout from "./components/AppLayout";
import ProtectedRoute from "./components/ProtectedRoute";
import ChatOverview from "./features/chat/components/ChatOverview";
import ChatWindow from "./features/chat/components/ChatWindow";
import Dashboard from "./features/dashboard/components/Dashboard";
import Tasks from "./features/jobs/components/Jobs";
import AuthCallback from "./features/login/AuthCallback";
import Login from "./features/login/components/Login";
import Settings from "./features/settings/components/Settings";
import CreateTask from "./features/tasks/components/CreateTask";
import TaskPage from "./features/tasks/components/TaskPage";
import TaskTemplates from "./features/taskTemplates/components/TaskTemplates";
import TemplateEditor from "./features/taskTemplates/components/TemplateEditor";
import Workflow from "./features/workflow/components/Workflow";

function AppRoutes() {
  const { isAuthenticated } = useSelector((state) => state.user);

  return (
    <Routes>
      <Route path="/auth/callback" element={<AuthCallback />} />
      <Route
        path="/"
        element={
          isAuthenticated ? (
            <Navigate to="/app/dashboard" replace />
          ) : (
            <Navigate to="/login" replace />
          )
        }
      />
      <Route path="/login" element={<Login />} />
      <Route element={<ProtectedRoute />}>
        <Route path="/app" element={<AppLayout />}>
          <Route
            index
            element={<Navigate to="/app/task-templates" replace />}
          />
          <Route path="task-templates" element={<TaskTemplates />} />
          <Route path="task/:templateId" element={<TaskPage />} />
          <Route path="task/:templateId/:taskId" element={<TaskPage />} />
          <Route
            path="task-templates/editor/:id?"
            element={<TemplateEditor />}
          />
          <Route path="dashboard" element={<Dashboard />} />
          <Route path="tasks" element={<Tasks />} />
          <Route path="task/:jobId" element={<TaskPage />} />
          <Route path="create-task" element={<CreateTask />} />
          <Route path="settings" element={<Settings />} />
          <Route path="chat" element={<ChatOverview />} />
          <Route path="chat/:conversationId" element={<ChatWindow />} />
          <Route path="workflow" element={<Workflow />} />
        </Route>
      </Route>
    </Routes>
  );
}

export default AppRoutes;
