import React, { useState, useRef, useEffect } from "react";
import { Box, Button, Typography } from "@mui/material";
import { styled } from "@mui/material/styles";

const ColorPicker = styled("div")({
  width: "30px",
  height: "30px",
  border: "2px solid #fff",
  boxShadow: "0 0 0 1px rgba(0,0,0,0.3)",
  borderRadius: "50%",
  cursor: "pointer",
  position: "absolute",
});

const ImageContainer = styled(Box)({
  position: "relative",
  marginTop: "20px",
  "& canvas": {
    maxWidth: "100%",
    maxHeight: "400px",
  },
});

const ImageColorPicker = ({ onColorChange }) => {
  const [image, setImage] = useState(() => {
    const savedImage = sessionStorage.getItem("uploadedImage");
    return savedImage || null;
  });
  const [color, setColor] = useState(null);
  const [position, setPosition] = useState({ x: 10, y: 10 });
  const canvasRef = useRef(null);

  const handleImageUpload = (event) => {
    const file = event.target.files[0];
    const reader = new FileReader();

    reader.onload = (e) => {
      const imageData = e.target.result;
      setImage(imageData);
      sessionStorage.setItem("uploadedImage", imageData);
    };

    if (file) {
      reader.readAsDataURL(file);
    }
  };

  const handleCanvasClick = (event) => {
    const canvas = canvasRef.current;
    if (!canvas) return;

    const rect = canvas.getBoundingClientRect();
    const scaleX = canvas.width / rect.width;
    const scaleY = canvas.height / rect.height;

    const x = (event.clientX - rect.left) * scaleX;
    const y = (event.clientY - rect.top) * scaleY;

    const ctx = canvas.getContext("2d");
    const imageData = ctx.getImageData(x, y, 1, 1);
    const [r, g, b] = imageData.data;
    const selectedColor = `#${((1 << 24) + (r << 16) + (g << 8) + b)
      .toString(16)
      .slice(1)}`;
    setColor(selectedColor);
    setPosition({ x: event.clientX - rect.left, y: event.clientY - rect.top });
    onColorChange(selectedColor);
  };

  useEffect(() => {
    if (image && canvasRef.current) {
      const canvas = canvasRef.current;
      const ctx = canvas.getContext("2d");
      const img = new Image();
      img.onload = () => {
        canvas.width = img.width;
        canvas.height = img.height;
        ctx.drawImage(img, 0, 0);
      };
      img.src = image;
    }
  }, [image]);

  return (
    <Box>
      <input
        accept="image/*"
        style={{ display: "none" }}
        id="raised-button-file"
        type="file"
        onChange={handleImageUpload}
      />
      <label htmlFor="raised-button-file">
        <Button variant="contained" component="span">
          Upload Image
        </Button>
      </label>
      {image && (
        <ImageContainer>
          <canvas
            ref={canvasRef}
            onClick={handleCanvasClick}
            style={{ cursor: "crosshair" }}
          />
          {color && (
            <ColorPicker
              style={{
                backgroundColor: color,
                left: `${position.x}px`,
                top: `${position.y}px`,
              }}
            />
          )}
        </ImageContainer>
      )}
      {color && (
        <Box mt={2}>
          <Typography variant="body1">Selected color: {color}</Typography>
        </Box>
      )}
    </Box>
  );
};

export default ImageColorPicker;
