import CircularProgress from "@mui/material/CircularProgress";
import CssBaseline from "@mui/material/CssBaseline";
import React, { createContext, useEffect, useMemo, useState } from "react";
import { BrowserRouter as Router } from "react-router-dom";
import { CustomThemeProvider } from "./context/ThemeContext"; // Adjust the import path as necessary

import { useDispatch, useSelector } from "react-redux";
import { checkAuth } from "./slices/userSlice";

import AppRoutes from "./AppRoutes";
import "./fonts.css";

export const ColorModeContext = createContext({ toggleColorMode: () => {} });

function App() {
  const dispatch = useDispatch();
  const [isLoading, setIsLoading] = useState(true);
  const authStatus = useSelector((state) => state.user.status);

  const [mode, setMode] = useState(() => {
    const savedMode = localStorage.getItem("colorMode");
    return savedMode || "dark";
  });

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === "light" ? "dark" : "light";
          localStorage.setItem("colorMode", newMode);
          return newMode;
        });
      },
    }),
    []
  );

  useEffect(() => {
    dispatch(checkAuth()).finally(() => setIsLoading(false));
  }, [dispatch]);

  if (isLoading || authStatus === "loading") {
    return (
      <CustomThemeProvider>
        <CssBaseline />
        <div
          style={{
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            height: "100vh",
          }}
        >
          <CircularProgress />
        </div>
      </CustomThemeProvider>
    );
  }

  return (
    <ColorModeContext.Provider value={colorMode}>
      <CustomThemeProvider>
        <CssBaseline />
        <Router>
          <main>
            <AppRoutes />
          </main>
        </Router>
      </CustomThemeProvider>
    </ColorModeContext.Provider>
  );
}

export default App;
