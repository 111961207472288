import {
  Alert,
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Paper,
  Snackbar,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { cloneDeep } from "lodash";
import React, { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import ErrorBoundary from "../../../components/ErrorBoundary";
import InputForm from "../../tasks/components/InputForm";
import OutputView from "../../tasks/components/OutputView";
import { useTaskTemplates } from "../hooks/useTaskTemplates";
import {
  createAndActivateVersion,
  createNewVersion,
  createTemplateWithInitialVersion,
  fetchTaskTemplateById,
  updateTaskTemplateMetadata,
} from "../services/taskTemplateService";
import {
  extractJinjaVariables,
  validateTemplate,
} from "../services/validationService";
import CustomFormBuilder from "./CustomFormBuilder";
import CustomStepper from "./CustomStepper";
import DiffView from "./DiffView";
import VersionDialog from "./VersionDialog";

const steps = ["Metadata", "Prompt", "Input", "Output"];

const ROOT_HEIGHT = "calc(100vh - 64px)"; // Adjust 64px if your AppBar height is different

const RootContainer = styled(Box)(({ theme }) => ({
  height: ROOT_HEIGHT,
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
}));

const ScrollableContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: "auto",
  padding: theme.spacing(2),
  paddingBottom: theme.spacing(10), // Extra padding to ensure content isn't hidden behind buttons
}));

const FixedBottomButtons = styled(Box)(({ theme }) => ({
  position: "fixed",
  bottom: 0,
  left: 0,
  right: 0,
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderTop: `1px solid ${theme.palette.divider}`,
  display: "flex",
  justifyContent: "space-between",
}));

const SectionHeader = styled(Typography)(({ theme }) => ({
  fontWeight: "bold",
  marginBottom: theme.spacing(2),
}));

const ConfigurationItem = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  justifyContent: "space-between",
  padding: theme.spacing(1),
  marginBottom: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  height: "100%",
  overflow: "auto",
  boxShadow: theme.shadows[2],
}));

const deepMerge = (target, source) => {
  for (const key in source) {
    if (source.hasOwnProperty(key)) {
      if (source[key] instanceof Object && key in target) {
        target[key] = deepMerge(target[key], source[key]);
      } else {
        target[key] = source[key];
      }
    }
  }
  return target;
};

const TemplateEditor = () => {
  const { id } = useParams();
  const navigate = useNavigate();
  const {
    getTaskTemplate,
    currentTemplate,
    templateError,
    status,
    activeVersion,
  } = useTaskTemplates();
  const [activeStep, setActiveStep] = useState(0);
  const [template, setTemplate] = useState(null);
  const [errors, setErrors] = useState({});
  const [hasChanges, setHasChanges] = useState(false);
  const [isVersionDialogOpen, setIsVersionDialogOpen] = useState(false);
  const [isNewTemplate, setIsNewTemplate] = useState(!id);
  const [isDiffDialogOpen, setIsDiffDialogOpen] = useState(false);
  const [originalTemplate, setOriginalTemplate] = useState(null);
  const [isLoading, setIsLoading] = useState(false);
  const dispatch = useDispatch();
  const user = useSelector((state) => state.user.user);
  const [errorMessage, setErrorMessage] = useState("");
  const [inputSchema, setInputSchema] = useState(
    currentTemplate?.input_json_schema || {}
  );
  const [inputUiSchema, setInputUiSchema] = useState(
    currentTemplate?.input_ui_schema || {}
  );
  const [metadataChanged, setMetadataChanged] = useState(false);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");

  useEffect(() => {
    if (id) {
      getTaskTemplate(id);
    } else {
      setTemplate({
        title: "",
        description: "",
        icon: "default-icon",
        color: "#000000",
        is_favorite: false,
        prompt: "",
        input: {
          schema: { type: "object", properties: {} },
          uiSchema: {},
          formData: {},
        },
        output: {
          schema: {
            type: "object",
            required: [],
            properties: {
              output: {
                type: "string",
                title: "Output",
                description: "Field description",
              },
            },
          },
          uiSchema: {
            output: {
              "ui:description": "Field description",
            },
            "ui:order": ["output"],
          },
          formData: {},
        },
        preferred_vendor: "",
        preferred_model: "",
        min_model_class: 0,
      });
    }
  }, [id, getTaskTemplate]);

  useEffect(() => {
    if (activeVersion) {
      const versionData = {
        title: activeVersion.title,
        description: activeVersion.description,
        icon: activeVersion.icon || "default-icon",
        color: activeVersion.color || "#000000",
        is_favorite: activeVersion.is_favorite || false,
        prompt: activeVersion.prompt,
        input: {
          schema: activeVersion.input_json_schema,
          uiSchema: activeVersion.input_ui_schema,
          formData: activeVersion.input_sample_data,
        },
        output: {
          schema: activeVersion.output_json_schema,
          uiSchema: activeVersion.output_ui_schema,
          formData: activeVersion.output_sample_data,
        },
        preferred_vendor: activeVersion.preferred_vendor,
        preferred_model: activeVersion.preferred_model,
        min_model_class: activeVersion.min_model_class,
      };
      setTemplate(versionData);
      setOriginalTemplate(cloneDeep(versionData));

      setInputSchema(activeVersion.input_json_schema || {});
      setInputUiSchema(activeVersion.input_ui_schema || {});
    }
  }, [activeVersion]);

  useEffect(() => {
    if (template) {
      const validationErrors = validateTemplate(template);
      setErrors(validationErrors);
    }
  }, [template]);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleMetadataChange = useCallback((field, value) => {
    setTemplate((prevTemplate) => ({
      ...prevTemplate,
      [field]: value,
    }));
    setMetadataChanged(true);
  }, []);

  const handlePromptChange = useCallback((content) => {
    setTemplate((prevTemplate) => ({
      ...prevTemplate,
      prompt: content,
    }));
  }, []);

  const handleInputChange = (newSchema, newUiSchema) => {
    console.warn("New schema:", newSchema);
    console.warn("New ui schema:", newUiSchema);

    setInputSchema(newSchema);
    setInputUiSchema(newUiSchema);

    setTemplate((prev) => ({
      ...prev,
      input: {
        ...prev.input,
        schema: newSchema,
        uiSchema: newUiSchema,
      },
    }));
    setHasChanges(true);
  };

  const handleOutputChange = (newSchema, newUiSchema) => {
    handleChange("output", {
      ...template.output,
      schema: newSchema,
      uiSchema: newUiSchema,
    });
    setTemplate((prev) => ({
      ...prev,
      output: { ...prev.output, schema: newSchema, uiSchema: newUiSchema },
    }));
  };

  const handleSave = async () => {
    if (Object.keys(errors).length === 0) {
      setIsLoading(true);
      setErrorMessage("");

      console.warn(`Creating template with data:`);
      console.warn(template);

      try {
        if (isNewTemplate) {
          const newTemplateData = {
            title: template.title,
            description: template.description,
            icon: template.icon,
            color: template.color,
            is_favorite: template.is_favorite,
            input_json_schema: template.input.schema,
            input_ui_schema: template.input.uiSchema,
            input_sample_data: template.input.formData,
            output_json_schema: template.output.schema,
            output_ui_schema: template.output.uiSchema,
            output_sample_data: template.output.formData,
            created_by: user.id,
            prompt: template.prompt,
            preferred_vendor: template.preferred_vendor,
            preferred_model: template.preferred_model,
            min_model_class: template.min_model_class,
          };
          await dispatch(
            createTemplateWithInitialVersion(newTemplateData)
          ).unwrap();
          navigate("/app/task-templates");
        } else if (hasChanges) {
          setIsDiffDialogOpen(true);
        }
      } catch (error) {
        console.error("Error saving template:", error);
        setErrorMessage(
          error.message ||
            "An error occurred while saving the template. Please try again."
        );
      } finally {
        setIsLoading(false);
      }
    } else {
      setErrorMessage("Please correct the errors before saving.");
    }
  };

  if (status === "loading") {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (templateError) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography color="error">{templateError}</Typography>
      </Box>
    );
  }

  const handleSaveMetadata = async () => {
    try {
      const updatedMetadata = await dispatch(
        updateTaskTemplateMetadata({
          templateId: id,
          metadata: {
            title: template.title,
            description: template.description,
          },
        })
      ).unwrap();

      // Update the local state with the new metadata
      setTemplate((prevTemplate) => ({
        ...prevTemplate,
        ...updatedMetadata,
      }));

      // Fetch the updated template to ensure all components have the latest data
      await dispatch(fetchTaskTemplateById(id)).unwrap();

      setMetadataChanged(false);
      setSnackbarMessage("Metadata saved successfully");
      setSnackbarOpen(true);
    } catch (error) {
      console.error("Error updating metadata:", error);
      setSnackbarMessage("Failed to save metadata. Please try again.");
      setSnackbarOpen(true);
    }
  };

  const renderStepContent = (step) => {
    if (!template) return null;

    switch (step) {
      case 0:
        return (
          <Box>
            <TextField
              fullWidth
              label="Title"
              value={template.title}
              onChange={(e) => handleMetadataChange("title", e.target.value)}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Description"
              value={template.description}
              onChange={(e) =>
                handleMetadataChange("description", e.target.value)
              }
              margin="normal"
              multiline
              rows={4}
            />
            <Button
              onClick={handleSaveMetadata}
              variant="contained"
              color="primary"
              disabled={!metadataChanged}
              sx={{ mt: 2 }}
            >
              Save Metadata
            </Button>
          </Box>
        );
      case 1:
        return (
          <TextField
            fullWidth
            label="Prompt"
            value={template.prompt}
            onChange={(e) => handlePromptChange(e.target.value)}
            margin="normal"
            multiline
            rows={15}
          />
        );
      case 2:
        return (
          <Box display="flex" gap={3} height="calc(100vh - 200px)">
            <StyledPaper elevation={3} sx={{ width: "50%" }}>
              <SectionHeader variant="h6">Configuration</SectionHeader>
              <CustomFormBuilder
                schema={inputSchema}
                uiSchema={inputUiSchema}
                onChange={handleInputChange}
                jinjaVariables={extractJinjaVariables(template.prompt)}
                prompt={template.prompt}
              />
            </StyledPaper>
            <StyledPaper elevation={3} sx={{ width: "50%" }}>
              <SectionHeader variant="h6">Preview</SectionHeader>
              <Box sx={{ "& > form": { margin: 0 } }}>
                <InputForm
                  key={JSON.stringify({
                    schema: inputSchema,
                    uiSchema: inputUiSchema,
                  })}
                  schema={inputSchema}
                  uiSchema={inputUiSchema}
                  formData={template.input.formData}
                  onChange={(e) => {
                    setTemplate((prev) => ({
                      ...prev,
                      input: {
                        ...prev.input,
                        formData: e.formData,
                      },
                    }));
                  }}
                />
              </Box>
            </StyledPaper>
          </Box>
        );
      case 3:
        return (
          <Box display="flex">
            <Box width="50%" pr={1}>
              <CustomFormBuilder
                schema={template.output.schema}
                uiSchema={template.output.uiSchema}
                onChange={handleOutputChange}
              />
            </Box>
            <Box width="50%" pl={1}>
              <OutputView
                schema={template.output.schema}
                uiSchema={template.output.uiSchema}
                formData={template.output.formData}
              />
            </Box>
          </Box>
        );
      default:
        return null;
    }
  };

  const handleChange = (field, value) => {
    setTemplate((prevTemplate) => {
      const updatedTemplate = { ...prevTemplate };
      if (field.includes(".")) {
        const [section, subfield] = field.split(".");
        updatedTemplate[section] = {
          ...updatedTemplate[section],
          [subfield]: value,
        };
      } else {
        updatedTemplate[field] = value;
      }
      return updatedTemplate;
    });
    setHasChanges(true);
  };

  const handleCreateVersion = async (description, activateImmediately) => {
    setIsLoading(true);
    try {
      console.warn(`Creating new version with data:`);
      console.warn(template);
      const versionData = {
        prompt: template.prompt,
        input_json_schema: template.input.schema,
        input_ui_schema: template.input.uiSchema,
        input_sample_data: template.input.formData,
        output_json_schema: template.output.schema,
        output_ui_schema: template.output.uiSchema,
        output_sample_data: template.output.formData,
        preferred_vendor: template.preferred_vendor,
        preferred_model: template.preferred_model,
        min_model_class: template.min_model_class,
        parent_version_id: activeVersion?.version_id,
        created_by: user.id,
        description: description,
      };

      if (activateImmediately) {
        await dispatch(
          createAndActivateVersion({ templateId: id, versionData })
        ).unwrap();
      } else {
        await dispatch(
          createNewVersion({ templateId: id, versionData })
        ).unwrap();
      }

      setHasChanges(false);
      await getTaskTemplate(id);
      navigate("/app/task-templates");
    } catch (error) {
      console.error("Error creating new version:", error);
      setErrorMessage("Failed to create new version. Please try again.");
    } finally {
      setIsLoading(false);
    }
  };

  const renderActionButton = () => {
    if (activeStep === steps.length - 1) {
      if (isNewTemplate) {
        return (
          <Button onClick={handleSave} variant="contained" color="primary">
            Save
          </Button>
        );
      } else if (hasChanges) {
        return (
          <Button onClick={handleSave} variant="contained" color="primary">
            Review Changes
          </Button>
        );
      }
      // If there are no changes and it's not a new template, don't render a button
      return null;
    } else {
      return (
        <Button
          onClick={handleNext}
          variant={hasChanges ? "contained" : "outlined"}
          color={hasChanges ? "primary" : "primary"}
        >
          Next
        </Button>
      );
    }
  };

  if (!template) {
    return <div>Loading...</div>;
  }

  return (
    <ErrorBoundary>
      <RootContainer>
        <ScrollableContent>
          <Box>
            <Typography variant="h4" gutterBottom>
              {isNewTemplate ? "Create New Template" : currentTemplate?.title}
            </Typography>
            {activeVersion && !isNewTemplate && (
              <Typography variant="subtitle1" gutterBottom>
                Version: {activeVersion.version_number}
              </Typography>
            )}
            {hasChanges && (
              <Typography variant="subtitle1" color="primary" gutterBottom>
                You have unsaved changes
              </Typography>
            )}
            <CustomStepper
              steps={steps}
              activeStep={activeStep}
              errors={errors}
            />
            <Paper elevation={3} sx={{ mt: 2, p: 2 }}>
              {renderStepContent(activeStep)}
            </Paper>
            <VersionDialog
              open={isVersionDialogOpen}
              onClose={() => setIsVersionDialogOpen(false)}
              onCreateVersion={handleCreateVersion}
            />
            <Snackbar
              open={!!errorMessage}
              autoHideDuration={6000}
              onClose={() => setErrorMessage("")}
            >
              <Alert
                onClose={() => setErrorMessage("")}
                severity="error"
                sx={{ width: "100%" }}
              >
                {errorMessage}
              </Alert>
            </Snackbar>
            {isLoading && (
              <Box
                position="fixed"
                top={0}
                left={0}
                right={0}
                bottom={0}
                display="flex"
                alignItems="center"
                justifyContent="center"
                bgcolor="rgba(0, 0, 0, 0.5)"
                zIndex={9999}
              >
                <CircularProgress />
              </Box>
            )}
            <Dialog
              open={isDiffDialogOpen}
              onClose={() => setIsDiffDialogOpen(false)}
              maxWidth="lg"
              fullWidth
            >
              <DialogTitle>Review Changes</DialogTitle>
              <DialogContent>
                {originalTemplate && (
                  <DiffView
                    oldVersion={originalTemplate}
                    newVersion={cloneDeep(template)}
                  />
                )}
              </DialogContent>
              <DialogActions>
                <Button onClick={() => setIsDiffDialogOpen(false)}>
                  Cancel
                </Button>
                <Button
                  onClick={() => {
                    setIsDiffDialogOpen(false);
                    setIsVersionDialogOpen(true);
                  }}
                  color="primary"
                >
                  Create New Version
                </Button>
              </DialogActions>
            </Dialog>
          </Box>
        </ScrollableContent>
        <FixedBottomButtons>
          <Box sx={{ display: "flex", flexDirection: "row", width: "100%" }}>
            <Button
              color="inherit"
              disabled={activeStep === 0}
              onClick={handleBack}
              sx={{ mr: 1 }}
            >
              Back
            </Button>
            <Box sx={{ flex: "1 1 auto" }} />
            {renderActionButton()}
          </Box>
        </FixedBottomButtons>
      </RootContainer>
    </ErrorBoundary>
  );
};

export default TemplateEditor;
