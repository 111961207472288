import React, { useEffect, useState } from "react";
import { useSelector, useDispatch } from "react-redux";
import { useNavigate } from "react-router-dom";
import { fetchJobs } from "../services/jobService";
import {
  Typography,
  Paper,
  Table,
  TableBody,
  TableCell,
  TableContainer,
  TableHead,
  TableRow,
  TablePagination,
  IconButton,
  Tooltip,
  CircularProgress,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  CheckCircle,
  Error,
  Autorenew,
  HourglassEmpty,
} from "@mui/icons-material";
import { formatDistanceToNow, format } from "date-fns";

const StyledPaper = styled(Paper)(({ theme }) => ({
  width: "100%",
  marginBottom: theme.spacing(2),
}));

const StyledTable = styled(Table)(({ theme }) => ({
  minWidth: 750,
}));

const StyledIconButton = styled(IconButton)(({ theme }) => ({
  padding: 0,
}));

const StyledStatusIcon = styled("span")(({ theme, status }) => ({
  fontSize: 20,
  color:
    status === "completed"
      ? theme.palette.success.main
      : status === "failed"
      ? theme.palette.error.main
      : status === "processing"
      ? theme.palette.info.main
      : theme.palette.warning.main,
}));

const LoadingOverlay = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  width: "100%",
  height: "100%",
  backgroundColor: "rgba(255, 255, 255, 0.7)",
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  zIndex: 1,
}));

const ClickableTableRow = styled(TableRow)(({ theme }) => ({
  cursor: "pointer",
  "&:hover": {
    backgroundColor: theme.palette.action.hover,
  },
}));

const statusIcons = {
  completed: CheckCircle,
  failed: Error,
  processing: Autorenew,
  pending: HourglassEmpty,
};

function Tasks() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { jobs, status, error, totalCount } = useSelector(
    (state) => state.jobs
  );
  const [page, setPage] = useState(0);
  const [rowsPerPage, setRowsPerPage] = useState(10);
  const [isLoading, setIsLoading] = useState(false);

  useEffect(() => {
    setIsLoading(true);
    dispatch(fetchJobs({ page, rowsPerPage })).finally(() =>
      setIsLoading(false)
    );
  }, [dispatch, page, rowsPerPage]);

  const handleChangePage = (event, newPage) => {
    setPage(newPage);
  };

  const handleChangeRowsPerPage = (event) => {
    setRowsPerPage(parseInt(event.target.value, 10));
    setPage(0);
  };

  const handleRowClick = (jobId, templateId) => {
    navigate(`/app/task/${templateId}/${jobId}`);
  };

  const renderStatusIcon = (status, error) => {
    const Icon = statusIcons[status] || Error;
    return (
      <Tooltip title={status === "failed" ? error : status}>
        <StyledIconButton size="small">
          <StyledStatusIcon status={status}>
            <Icon />
          </StyledStatusIcon>
        </StyledIconButton>
      </Tooltip>
    );
  };

  const formatRelativeTime = (date) => {
    return formatDistanceToNow(new Date(date), { addSuffix: true });
  };

  const formatExactTime = (date) => {
    return format(new Date(date), "yyyy-MM-dd HH:mm:ss");
  };

  if (status === "failed") {
    return <Typography color="error">Error: {error}</Typography>;
  }

  return (
    <Box sx={{ width: "100%" }}>
      <Typography variant="h4" gutterBottom>
        Tasks
      </Typography>
      <StyledPaper>
        <TableContainer>
          {isLoading && (
            <LoadingOverlay>
              <CircularProgress />
            </LoadingOverlay>
          )}
          <StyledTable aria-label="tasks table">
            <TableHead>
              <TableRow>
                <TableCell>Template Name</TableCell>
                <TableCell>Created</TableCell>
                <TableCell>Status</TableCell>
                <TableCell>Input Tokens</TableCell>
                <TableCell>Output Tokens</TableCell>
                <TableCell>Cost</TableCell>
                <TableCell>Latency</TableCell>
              </TableRow>
            </TableHead>
            <TableBody>
              {jobs && jobs.length > 0 ? (
                jobs.map((job) => (
                  <ClickableTableRow
                    key={job.id}
                    onClick={() => handleRowClick(job.id, job.task_template_id)}
                  >
                    <TableCell>{job.template_name || "N/A"}</TableCell>
                    <TableCell>
                      <Tooltip title={formatExactTime(job.created_at)}>
                        <span>{formatRelativeTime(job.created_at)}</span>
                      </Tooltip>
                    </TableCell>
                    <TableCell>{renderStatusIcon(job.status, job.error)}</TableCell>
                    <TableCell>{job.input_tokens || "N/A"}</TableCell>
                    <TableCell>{job.output_tokens || "N/A"}</TableCell>
                    <TableCell>
                      {job.cost ? `$${job.cost.toFixed(3)}` : "N/A"}
                    </TableCell>
                    <TableCell>
                      {job.execution_time ? `${job.execution_time.toFixed(2)}s` : "N/A"}
                    </TableCell>
                  </ClickableTableRow>
                ))
              ) : (
                <TableRow>
                  <TableCell colSpan={7} align="center">
                    No tasks found
                  </TableCell>
                </TableRow>
              )}
            </TableBody>
          </StyledTable>
        </TableContainer>
        <TablePagination
          rowsPerPageOptions={[5, 10, 25]}
          component="div"
          count={totalCount || 0}
          rowsPerPage={rowsPerPage}
          page={page}
          onPageChange={handleChangePage}
          onRowsPerPageChange={handleChangeRowsPerPage}
        />
      </StyledPaper>
    </Box>
  );
}

export default Tasks;