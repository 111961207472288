import React, { useEffect, useState, useMemo } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate } from "react-router-dom";
import {
  fetchConversations,
  createConversation,
  bulkUpdateConversations,
  bulkDeleteConversations,
} from "../slices/chatSlice";
import {
  List,
  ListItem,
  ListItemText,
  ListItemIcon,
  Checkbox,
  Button,
  TextField,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  FormControlLabel,
  Switch,
  Typography,
  Box,
  Tooltip,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { formatDistanceToNow, format, parseISO } from "date-fns";

const ActionButtons = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  "& > *": {
    marginRight: theme.spacing(1),
  },
}));

const StyledListItem = styled(ListItem)(({ theme }) => ({
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const ListItemTextWrapper = styled(Box)(({ theme }) => ({
  flex: "1 1 auto",
}));

const DateInfo = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  alignItems: "flex-end",
  minWidth: "200px",
}));

const ChatOverview = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { conversations } = useSelector((state) => state.chat);
  const [open, setOpen] = useState(false);
  const [deleteModalOpen, setDeleteModalOpen] = useState(false);
  const [newConversationName, setNewConversationName] = useState("");
  const [selectedConversations, setSelectedConversations] = useState([]);
  const [showArchived, setShowArchived] = useState(false);
  const [selectAll, setSelectAll] = useState(false);

  useEffect(() => {
    dispatch(fetchConversations(showArchived));
  }, [dispatch, showArchived]);

  const handleCreateConversation = async () => {
    const resultAction = await dispatch(createConversation());
    if (createConversation.fulfilled.match(resultAction)) {
      navigate(`/app/chat/${resultAction.payload.id}`);
    }
  };

  const handleSelectConversation = (id) => {
    setSelectedConversations((prev) =>
      prev.includes(id) ? prev.filter((convId) => convId !== id) : [...prev, id]
    );
  };

  const handleSelectAll = () => {
    setSelectAll(!selectAll);
    setSelectedConversations(
      selectAll ? [] : conversations.map((conv) => conv.id)
    );
  };

  const handleArchive = async () => {
    await dispatch(
      bulkUpdateConversations({
        conversation_ids: selectedConversations,
        update_data: { archive: true },
      })
    );
    dispatch(fetchConversations(showArchived));
    setSelectedConversations([]);
  };

  const handleUnarchive = async () => {
    await dispatch(
      bulkUpdateConversations({
        conversation_ids: selectedConversations,
        update_data: { archive: false },
      })
    );
    dispatch(fetchConversations(showArchived));
    setSelectedConversations([]);
  };

  const handleDelete = async () => {
    setDeleteModalOpen(true);
  };

  const confirmDelete = async () => {
    await dispatch(
      bulkDeleteConversations({ conversation_ids: selectedConversations })
    );
    dispatch(fetchConversations(showArchived));
    setSelectedConversations([]);
    setDeleteModalOpen(false);
  };

  const formatDate = (dateString) => {
    if (!dateString) return "N/A";
    const date = parseISO(dateString);
    return formatDistanceToNow(date, { addSuffix: true });
  };

  const formatFullDate = (dateString) => {
    if (!dateString) return "N/A";
    return format(parseISO(dateString), "yyyy-MM-dd HH:mm:ss");
  };

  const { canArchive, canUnarchive } = useMemo(() => {
    const selectedConvs = conversations.filter((conv) =>
      selectedConversations.includes(conv.id)
    );
    return {
      canArchive: selectedConvs.some((conv) => !conv.archived_at),
      canUnarchive: selectedConvs.some((conv) => conv.archived_at),
    };
  }, [conversations, selectedConversations]);

  return (
    <Box>
      <Typography variant="h4" gutterBottom>
        Your Conversations
      </Typography>
      <Button
        variant="contained"
        color="primary"
        onClick={handleCreateConversation}
        sx={{ marginBottom: 2 }}
      >
        New Conversation
      </Button>
      <FormControlLabel
        control={
          <Switch
            checked={showArchived}
            onChange={() => setShowArchived(!showArchived)}
          />
        }
        label="Show Archived"
        sx={{ marginLeft: 2 }}
      />
      <ActionButtons>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleArchive}
          disabled={!canArchive}
        >
          Archive Selected
        </Button>
        <Button
          variant="outlined"
          color="primary"
          onClick={handleUnarchive}
          disabled={!canUnarchive}
        >
          Unarchive Selected
        </Button>
        <Button
          variant="outlined"
          color="secondary"
          onClick={handleDelete}
          disabled={selectedConversations.length === 0}
        >
          Delete Selected
        </Button>
      </ActionButtons>
      <List>
        <ListItem>
          <ListItemIcon>
            <Checkbox
              edge="start"
              checked={selectAll}
              onChange={handleSelectAll}
              tabIndex={-1}
              disableRipple
            />
          </ListItemIcon>
          <ListItemText primary="Select All" />
        </ListItem>
        {conversations.map((conversation) => (
          <StyledListItem
            button
            key={conversation.id}
            onClick={() => navigate(`/app/chat/${conversation.id}`)}
          >
            <ListItemIcon>
              <Checkbox
                edge="start"
                checked={selectedConversations.includes(conversation.id)}
                onChange={() => handleSelectConversation(conversation.id)}
                onClick={(e) => e.stopPropagation()}
                tabIndex={-1}
                disableRipple
              />
            </ListItemIcon>
            <ListItemTextWrapper>
              <ListItemText primary={conversation.name} />
            </ListItemTextWrapper>
            <DateInfo>
              <Tooltip
                title={`Created: ${formatFullDate(conversation.created_at)}`}
              >
                <Typography variant="body2" color="textSecondary">
                  Created: {formatDate(conversation.created_at)}
                </Typography>
              </Tooltip>
              <Tooltip
                title={
                  conversation.archived_at
                    ? `Archived: ${formatFullDate(conversation.archived_at)}`
                    : `Updated: ${formatFullDate(conversation.updated_at)}`
                }
              >
                <Typography variant="body2" color="textSecondary">
                  {conversation.archived_at
                    ? `Archived: ${formatDate(conversation.archived_at)}`
                    : `Updated: ${formatDate(conversation.updated_at)}`}
                </Typography>
              </Tooltip>
            </DateInfo>
          </StyledListItem>
        ))}
      </List>
      <Dialog open={open} onClose={() => setOpen(false)}>
        <DialogTitle>Create New Conversation</DialogTitle>
        <DialogContent>
          <TextField
            autoFocus
            margin="dense"
            label="Conversation Name"
            type="text"
            fullWidth
            value={newConversationName}
            onChange={(e) => setNewConversationName(e.target.value)}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={handleCreateConversation} color="primary">
            Create
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={deleteModalOpen}
        onClose={() => setDeleteModalOpen(false)}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Confirm Deletion"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            Are you sure you want to delete the selected conversations? This
            action cannot be undone.
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={() => setDeleteModalOpen(false)} color="primary">
            Cancel
          </Button>
          <Button onClick={confirmDelete} color="secondary" autoFocus>
            Delete
          </Button>
        </DialogActions>
      </Dialog>
    </Box>
  );
};

export default ChatOverview;
