import React, { useState, useEffect, useMemo } from "react";
import { useSelector, useDispatch } from "react-redux";
import { fetchTasksTimeseries } from "../../../features/tasks/services/taskService";
import {
  Typography,
  Paper,
  FormControl,
  InputLabel,
  Select,
  MenuItem,
  Grid,
  Box,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(3),
  marginBottom: theme.spacing(3),
}));

const StyledFormControl = styled(FormControl)(({ theme }) => ({
  minWidth: 120,
  marginRight: theme.spacing(2),
}));

const Dashboard = () => {
  const dispatch = useDispatch();
  const { timeseriesData, status, error } = useSelector((state) => state.tasks);
  const [timeWindow, setTimeWindow] = useState("7d");
  const [resolution, setResolution] = useState("3h");

  useEffect(() => {
    dispatch(fetchTasksTimeseries({ timeWindow, resolution }));
  }, [dispatch, timeWindow, resolution]);

  const formatData = (data) => {
    return data.map((item) => ({
      timestamp: new Date(item.timestamp).toLocaleString(),
      taskCount: item.task_count,
      inputTokens: item.input_tokens,
      outputTokens: item.output_tokens,
      cost: parseFloat(item.cost).toFixed(2),
    }));
  };

  const formattedData = useMemo(
    () => formatData(timeseriesData || []),
    [timeseriesData]
  );

  const renderLineChart = (data, dataKey, label, color) => (
    <ResponsiveContainer width="100%" height={300}>
      <LineChart
        data={data}
        margin={{ top: 5, right: 30, left: 20, bottom: 5 }}
      >
        <CartesianGrid strokeDasharray="3 3" />
        <XAxis dataKey="timestamp" />
        <YAxis />
        <Tooltip />
        <Line type="monotone" dataKey={dataKey} stroke={color} name={label} />
      </LineChart>
    </ResponsiveContainer>
  );

  return (
    <Box sx={{ flexGrow: 1, p: 3 }}>
      <Typography variant="h4" gutterBottom>
        Dashboard
      </Typography>
      <StyledPaper>
        <Box sx={{ mb: 3 }}>
          <StyledFormControl>
            <InputLabel>Time Window</InputLabel>
            <Select
              value={timeWindow}
              onChange={(e) => setTimeWindow(e.target.value)}
            >
              {[
                "15min",
                "30min",
                "1h",
                "3h",
                "6h",
                "12h",
                "1d",
                "7d",
                "14d",
                "30d",
              ].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
          <StyledFormControl>
            <InputLabel>Resolution</InputLabel>
            <Select
              value={resolution}
              onChange={(e) => setResolution(e.target.value)}
            >
              {[
                "15min",
                "30min",
                "1h",
                "3h",
                "6h",
                "12h",
                "1d",
                "7d",
                "14d",
                "30d",
              ].map((option) => (
                <MenuItem key={option} value={option}>
                  {option}
                </MenuItem>
              ))}
            </Select>
          </StyledFormControl>
        </Box>
        {status === "loading" && <Typography>Loading...</Typography>}
        {status === "failed" && (
          <Typography color="error">Error: {error}</Typography>
        )}
        {status === "succeeded" && (
          <Grid container spacing={3}>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Task Count
              </Typography>
              {renderLineChart(
                formattedData,
                "taskCount",
                "Task Count",
                "#8884d8"
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Input Tokens
              </Typography>
              {renderLineChart(
                formattedData,
                "inputTokens",
                "Input Tokens",
                "#82ca9d"
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Output Tokens
              </Typography>
              {renderLineChart(
                formattedData,
                "outputTokens",
                "Output Tokens",
                "#ffc658"
              )}
            </Grid>
            <Grid item xs={12} md={6}>
              <Typography variant="h6" gutterBottom>
                Cost (USD)
              </Typography>
              {renderLineChart(formattedData, "cost", "Cost", "#ff7300")}
            </Grid>
          </Grid>
        )}
      </StyledPaper>
    </Box>
  );
};

export default Dashboard;
