import React, { useState, useRef } from "react";
import {
  Typography,
  Box,
  TextField,
  Button,
  IconButton,
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Snackbar,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import AddIcon from "@mui/icons-material/Add";
import DeleteIcon from "@mui/icons-material/Delete";
import EditIcon from "@mui/icons-material/Edit";
import UploadIcon from "@mui/icons-material/Upload";
import DownloadIcon from "@mui/icons-material/Download";
import { Form } from "@rjsf/mui";
import validator from "@rjsf/validator-ajv8";

const Root = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const Section = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const ToneOfVoiceItem = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  padding: theme.spacing(2),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
}));

const schema = {
  type: "object",
  properties: {
    tone_of_voice: {
      type: "object",
      properties: {
        adjectives: {
          type: "array",
          items: {
            type: "string",
          },
        },
        intended_emotion: {
          type: "string",
        },
        sentence_structure: {
          type: "string",
        },
        vocabulary: {
          type: "string",
        },
        punctuation: {
          type: "string",
        },
        example_phrases: {
          type: "array",
          items: {
            type: "string",
          },
        },
        contextual_application: {
          type: "string",
        },
        voice_qualities: {
          type: "object",
          properties: {
            spoken: {
              type: "string",
            },
          },
          required: ["spoken"],
        },
      },
      required: [
        "adjectives",
        "intended_emotion",
        "sentence_structure",
        "vocabulary",
        "punctuation",
        "example_phrases",
        "contextual_application",
        "voice_qualities",
      ],
    },
  },
  required: ["tone_of_voice"],
};

const uiSchema = {
  tone_of_voice: {
    adjectives: {
      "ui:options": {
        addable: true,
        orderable: true,
        removable: true,
      },
    },
    example_phrases: {
      "ui:options": {
        addable: true,
        orderable: true,
        removable: true,
      },
    },
    voice_qualities: {
      "ui:options": {
        collapsed: true,
      },
    },
  },
};

function ContentSettings() {
  const [companyBackground, setCompanyBackground] = useState("");
  const [productDescription, setProductDescription] = useState("");
  const [toneOfVoice, setToneOfVoice] = useState([
    { name: "Default", formData: { tone_of_voice: {} } },
  ]);
  const [openToneDialog, setOpenToneDialog] = useState(false);
  const [currentEditIndex, setCurrentEditIndex] = useState(null);
  const [snackbarOpen, setSnackbarOpen] = useState(false);
  const [snackbarMessage, setSnackbarMessage] = useState("");
  const [importDialogOpen, setImportDialogOpen] = useState(false);
  const [exportDialogOpen, setExportDialogOpen] = useState(false);
  const [importText, setImportText] = useState("");
  const [exportText, setExportText] = useState("");

  const handleAddToneOfVoice = () => {
    if (toneOfVoice.length < 5) {
      setToneOfVoice([
        ...toneOfVoice,
        { name: "", formData: { tone_of_voice: {} } },
      ]);
    }
  };

  const handleRemoveToneOfVoice = (index) => {
    const newToneOfVoice = toneOfVoice.filter((_, i) => i !== index);
    setToneOfVoice(newToneOfVoice);
  };

  const handleToneOfVoiceNameChange = (index, value) => {
    const newToneOfVoice = [...toneOfVoice];
    newToneOfVoice[index].name = value;
    setToneOfVoice(newToneOfVoice);
  };

  const handleOpenToneDialog = (index) => {
    setCurrentEditIndex(index);
    setOpenToneDialog(true);
  };

  const handleCloseToneDialog = () => {
    setOpenToneDialog(false);
    setCurrentEditIndex(null);
  };

  const handleSaveTone = ({ formData }) => {
    const newToneOfVoice = [...toneOfVoice];
    newToneOfVoice[currentEditIndex].formData = formData;
    setToneOfVoice(newToneOfVoice);
    handleCloseToneDialog();
  };

  const handleSave = () => {
    console.log("Saving content settings:", {
      companyBackground,
      productDescription,
      toneOfVoice,
    });
  };

  const handleImportOpen = () => {
    setImportDialogOpen(true);
  };

  const handleImportClose = () => {
    setImportDialogOpen(false);
    setImportText("");
  };

  const handleImportSubmit = () => {
    try {
      const importedData = JSON.parse(importText);
      if (importedData.tone_of_voice) {
        const newToneOfVoice = [...toneOfVoice];
        newToneOfVoice[currentEditIndex] = {
          name: toneOfVoice[currentEditIndex].name,
          formData: { tone_of_voice: importedData.tone_of_voice },
        };
        setToneOfVoice(newToneOfVoice);
        setSnackbarMessage("Tone of voice imported successfully");
        setSnackbarOpen(true);
        handleImportClose();
        handleCloseToneDialog();
      } else {
        throw new Error("Invalid tone of voice data");
      }
    } catch (error) {
      setSnackbarMessage("Error importing tone of voice: " + error.message);
      setSnackbarOpen(true);
    }
  };

  const handleExportOpen = () => {
    setExportText(
      JSON.stringify(toneOfVoice[currentEditIndex].formData, null, 2)
    );
    setExportDialogOpen(true);
  };

  const handleExportClose = () => {
    setExportDialogOpen(false);
  };

  return (
    <Root>
      <Typography variant="h4" gutterBottom>
        Content Settings
      </Typography>
      <Section>
        <TextField
          fullWidth
          label="Company Background"
          variant="outlined"
          margin="normal"
          multiline
          rows={4}
          value={companyBackground}
          onChange={(e) => setCompanyBackground(e.target.value)}
        />
        <TextField
          fullWidth
          label="Product Descriptions"
          variant="outlined"
          margin="normal"
          multiline
          rows={4}
          value={productDescription}
          onChange={(e) => setProductDescription(e.target.value)}
        />
      </Section>
      <Section>
        <Typography variant="h6" gutterBottom>
          Tone of Voice Settings
        </Typography>
        {toneOfVoice.map((tone, index) => (
          <ToneOfVoiceItem key={index}>
            <TextField
              fullWidth
              label={`Tone of Voice Name ${index + 1}`}
              variant="outlined"
              margin="normal"
              value={tone.name}
              onChange={(e) =>
                handleToneOfVoiceNameChange(index, e.target.value)
              }
            />
            <Box mt={1}>
              <Button
                onClick={() => handleOpenToneDialog(index)}
                startIcon={<EditIcon />}
              >
                Edit Tone of Voice
              </Button>
              <IconButton onClick={() => handleRemoveToneOfVoice(index)}>
                <DeleteIcon />
              </IconButton>
            </Box>
          </ToneOfVoiceItem>
        ))}
        {toneOfVoice.length < 5 && (
          <Button
            startIcon={<AddIcon />}
            onClick={handleAddToneOfVoice}
            variant="outlined"
            color="primary"
          >
            Add Tone of Voice
          </Button>
        )}
      </Section>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        style={{ marginTop: 16 }}
      >
        Save Content Settings
      </Button>
      <Dialog
        open={openToneDialog}
        onClose={handleCloseToneDialog}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Edit Tone of Voice</DialogTitle>
        <DialogContent>
          {currentEditIndex !== null && (
            <>
              <Box display="flex" justifyContent="space-between" mb={2}>
                <Button startIcon={<UploadIcon />} onClick={handleImportOpen}>
                  Import
                </Button>
                <Button startIcon={<DownloadIcon />} onClick={handleExportOpen}>
                  Export
                </Button>
              </Box>
              <Form
                schema={schema}
                uiSchema={uiSchema}
                formData={toneOfVoice[currentEditIndex].formData}
                validator={validator}
                onSubmit={handleSaveTone}
              >
                <Button type="submit" style={{ display: "none" }} />
              </Form>
            </>
          )}
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCloseToneDialog} color="primary">
            Cancel
          </Button>
          <Button
            onClick={() =>
              document.querySelector('button[type="submit"]').click()
            }
            color="primary"
          >
            Save
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={importDialogOpen}
        onClose={handleImportClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Import Tone of Voice</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            multiline
            rows={10}
            value={importText}
            onChange={(e) => setImportText(e.target.value)}
            variant="outlined"
            margin="normal"
            placeholder="Paste your JSON here"
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleImportClose} color="primary">
            Cancel
          </Button>
          <Button onClick={handleImportSubmit} color="primary">
            Import
          </Button>
        </DialogActions>
      </Dialog>
      <Dialog
        open={exportDialogOpen}
        onClose={handleExportClose}
        maxWidth="md"
        fullWidth
      >
        <DialogTitle>Export Tone of Voice</DialogTitle>
        <DialogContent>
          <TextField
            fullWidth
            multiline
            rows={10}
            value={exportText}
            variant="outlined"
            margin="normal"
            InputProps={{
              readOnly: true,
            }}
          />
        </DialogContent>
        <DialogActions>
          <Button onClick={handleExportClose} color="primary">
            Close
          </Button>
          <Button
            onClick={() => {
              navigator.clipboard.writeText(exportText);
              setSnackbarMessage("Exported JSON copied to clipboard");
              setSnackbarOpen(true);
            }}
            color="primary"
          >
            Copy to Clipboard
          </Button>
        </DialogActions>
      </Dialog>
      <Snackbar
        open={snackbarOpen}
        autoHideDuration={6000}
        onClose={() => setSnackbarOpen(false)}
        message={snackbarMessage}
      />
    </Root>
  );
}

export default ContentSettings;
