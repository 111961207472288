import EditIcon from "@mui/icons-material/Edit";
import {
  Box,
  Button,
  CircularProgress,
  FormControlLabel,
  IconButton,
  Modal,
  Switch,
  TextField,
  Tooltip,
  Typography,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useRef, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { useNavigate, useParams } from "react-router-dom";
import {
  clearConversationData,
  fetchConversationDetails,
  fetchConversationHistory,
  sendMessage,
  sendStreamingMessage,
  updateConversationName,
} from "../slices/chatSlice";
import AssistantMessage from "./AssistantMessage";
import UserMessage from "./UserMessage";

const Root = styled(Box)(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  height: `calc(100vh - ${theme.mixins.toolbar.minHeight}px - 48px)`,
  width: "100%",
  maxWidth: "720px",
  margin: "0 auto",
  position: "relative",
  backgroundColor: theme.palette.background.default,
  color: theme.palette.text.primary,
  overflow: "hidden",
}));

const Header = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: 0,
  left: 0,
  right: 0,
  zIndex: 1,
  backgroundColor: theme.palette.background.paper,
  borderBottom: `1px solid ${theme.palette.divider}`,
  padding: theme.spacing(2),
  display: "flex",
  alignItems: "center",
  height: 64,
}));

const TitleContainer = styled(Box)({
  display: "flex",
  alignItems: "center",
  flexGrow: 1,
  marginLeft: "16px",
});

const TruncatedTypography = styled(Typography)(({ theme }) => ({
  whiteSpace: "nowrap",
  overflow: "hidden",
  textOverflow: "ellipsis",
  maxWidth: "200px",
  [theme.breakpoints.up("sm")]: {
    maxWidth: "300px",
  },
}));

const ChatContent = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  overflowY: "auto",
  padding: theme.spacing(2),
  marginTop: 64,
  marginBottom: 64,
  backgroundColor: theme.palette.background.default,
}));

const Footer = styled(Box)(({ theme }) => ({
  position: "absolute",
  bottom: 0,
  left: 0,
  right: 0,
  padding: theme.spacing(2),
  backgroundColor: theme.palette.background.paper,
  borderTop: `1px solid ${theme.palette.divider}`,
  display: "flex",
  alignItems: "center",
  height: 64,
}));

const LoadingContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "center",
  alignItems: "center",
  height: "100%",
  flexDirection: "column",
}));

const ChatWindow = () => {
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { conversationId } = useParams();
  const { messages, status, error, streamedMessage, currentConversation } =
    useSelector((state) => state.chat);
  const [input, setInput] = useState("");
  const [isStreaming, setIsStreaming] = useState(true);
  const [isRenameModalOpen, setIsRenameModalOpen] = useState(false);
  const [newName, setNewName] = useState("");
  const [currentTitle, setCurrentTitle] = useState("Chat");
  const messagesEndRef = useRef(null);
  const [isInitialDataLoaded, setIsInitialDataLoaded] = useState(false);

  useEffect(() => {
    if (conversationId) {
      dispatch(clearConversationData());
      setIsInitialDataLoaded(false);
      dispatch(fetchConversationHistory(conversationId)).then(() => {
        setIsInitialDataLoaded(true);
      });
      dispatch(fetchConversationDetails(conversationId));
    }
  }, [dispatch, conversationId]);

  useEffect(() => {
    if (currentConversation) {
      setCurrentTitle(currentConversation.name || "Chat");
    } else {
      setCurrentTitle("Chat");
    }
  }, [currentConversation]);

  useEffect(() => {
    if (status === "succeeded" && messages.length > 0) {
      setIsInitialDataLoaded(true);
    }
  }, [status, messages]);

  const handleSend = async () => {
    if (input.trim() && conversationId) {
      if (isStreaming) {
        dispatch(
          sendStreamingMessage({
            content: input,
            conversation_id: conversationId,
          })
        );
      } else {
        dispatch(
          sendMessage({ content: input, conversation_id: conversationId })
        );
      }
      setInput("");
    }
  };

  const handleRenameOpen = () => {
    setNewName(currentTitle);
    setIsRenameModalOpen(true);
  };

  const handleRenameClose = () => {
    setIsRenameModalOpen(false);
    setNewName("");
  };

  const handleRename = async () => {
    if (newName.trim() && currentConversation) {
      await dispatch(
        updateConversationName({
          conversationId: currentConversation.id,
          newName: newName.trim(),
        })
      );
      setCurrentTitle(newName.trim());
      handleRenameClose();
    }
  };

  return (
    <Root>
      <Header>
        <TitleContainer>
          <Tooltip title={currentTitle} arrow>
            <TruncatedTypography variant="h6" sx={{ marginRight: 1 }}>
              {currentTitle}
            </TruncatedTypography>
          </Tooltip>
          <IconButton onClick={handleRenameOpen} size="small">
            <EditIcon fontSize="small" />
          </IconButton>
        </TitleContainer>
        <FormControlLabel
          control={
            <Switch
              checked={isStreaming}
              onChange={() => setIsStreaming(!isStreaming)}
              color="primary"
            />
          }
          label="Streaming Mode"
        />
      </Header>
      <ChatContent>
        {status === "loading" && !isInitialDataLoaded && (
          <LoadingContainer>
            <CircularProgress size={40} thickness={4} />
            <Typography variant="body1" style={{ marginTop: "1rem" }}>
              Loading conversation...
            </Typography>
          </LoadingContainer>
        )}
        {status === "failed" && (
          <Typography color="error">Error: {error}</Typography>
        )}
        { isInitialDataLoaded && (
          <>
            {messages.length === 0 && !streamedMessage ? (
              <Typography>
                This conversation is empty. Send a message to get started.
              </Typography>
            ) : (
              <>
                {messages.map((message) =>
                  message.sender === "user" ? (
                    <UserMessage key={message.uuid} message={message} />
                  ) : (
                    <AssistantMessage
                      key={message.uuid}
                      message={message}
                      isStreaming={false}
                    />
                  )
                )}
                {streamedMessage && (
                  <AssistantMessage
                    message={{
                      uuid: "streaming",
                      text: streamedMessage,
                      sender: "assistant",
                    }}
                    isStreaming={true}
                  />
                )}
              </>
            )}
          </>
        )}
        <div ref={messagesEndRef} />
      </ChatContent>
      <Footer>
        <TextField
          variant="outlined"
          value={input}
          onChange={(e) => setInput(e.target.value)}
          onKeyPress={(e) => e.key === "Enter" && handleSend()}
          placeholder="Type a message..."
          fullWidth
          size="small"
          sx={{
            backgroundColor: theme.palette.background.paper,
            "& .MuiOutlinedInput-root": {
              "& fieldset": {
                borderColor: theme.palette.divider,
              },
              "&:hover fieldset": {
                borderColor: theme.palette.primary.main,
              },
              "&.Mui-focused fieldset": {
                borderColor: theme.palette.primary.main,
              },
            },
          }}
        />
        <Button
          variant="contained"
          color="primary"
          onClick={handleSend}
          size={isMobile ? "small" : "medium"}
          sx={{ marginLeft: 1 }}
        >
          Send
        </Button>
      </Footer>
      <Modal
        open={isRenameModalOpen}
        onClose={handleRenameClose}
        aria-labelledby="rename-modal-title"
      >
        <Box
          sx={{
            position: "absolute",
            top: "50%",
            left: "50%",
            transform: "translate(-50%, -50%)",
            width: 400,
            bgcolor: "background.paper",
            boxShadow: 24,
            p: 4,
          }}
        >
          <Typography id="rename-modal-title" variant="h6" component="h2">
            Rename Conversation
          </Typography>
          <TextField
            autoFocus
            margin="dense"
            label="New Name"
            type="text"
            fullWidth
            value={newName}
            onChange={(e) => setNewName(e.target.value)}
            inputProps={{ maxLength: 40 }}
            helperText={`${newName.length}/40 characters`}
          />
          <Button onClick={handleRename} color="primary">
            Rename
          </Button>
          <Button onClick={handleRenameClose} color="secondary">
            Cancel
          </Button>
        </Box>
      </Modal>
    </Root>
  );
};

export default ChatWindow;
