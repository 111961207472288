// src/features/taskTemplates/slices/taskTemplateSlice.js

import { createSlice } from "@reduxjs/toolkit";
import {
  createTaskTemplate,
  fetchTaskTemplateById,
  fetchTaskTemplates,
  toggleTaskTemplateFavorite,
  updateTaskTemplate,
  updateTaskTemplateMetadata,
} from "../services/taskTemplateService";

const taskTemplateSlice = createSlice({
  name: "taskTemplates",
  initialState: {
    taskTemplates: [],
    currentTemplate: null,
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaskTemplates.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTaskTemplates.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.taskTemplates = action.payload;
      })
      .addCase(fetchTaskTemplates.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(toggleTaskTemplateFavorite.fulfilled, (state, action) => {
        const index = state.taskTemplates.findIndex(
          (template) => template.id === action.payload.id
        );
        if (index !== -1) {
          state.taskTemplates[index] = action.payload;
        }
      })
      .addCase(fetchTaskTemplateById.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTaskTemplateById.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.currentTemplate = {
          ...action.payload,
          input_schema: action.payload.input_json_schema,
          output_schema: action.payload.output_json_schema,
        };
      })
      .addCase(fetchTaskTemplateById.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(createTaskTemplate.fulfilled, (state, action) => {
        state.taskTemplates.push(action.payload);
        state.currentTemplate = action.payload;
      })
      .addCase(updateTaskTemplate.fulfilled, (state, action) => {
        const index = state.taskTemplates.findIndex(
          (template) => template.id === action.payload.id
        );
        if (index !== -1) {
          state.taskTemplates[index] = {
            ...state.taskTemplates[index],
            ...action.payload,
          };
        }
        if (
          state.currentTemplate &&
          state.currentTemplate.id === action.payload.id
        ) {
          state.currentTemplate = {
            ...state.currentTemplate,
            ...action.payload,
          };
        }
      })
      .addCase(updateTaskTemplateMetadata.fulfilled, (state, action) => {
        if (
          state.currentTemplate &&
          state.currentTemplate.id === action.payload.id
        ) {
          state.currentTemplate = {
            ...state.currentTemplate,
            ...action.payload,
          };
        }
        const index = state.taskTemplates.findIndex(
          (template) => template.id === action.payload.id
        );
        if (index !== -1) {
          state.taskTemplates[index] = {
            ...state.taskTemplates[index],
            ...action.payload,
          };
        }
      });
  },
});

export default taskTemplateSlice.reducer;
