import api from "../../../common/utils/api";
import { createAsyncThunk } from "@reduxjs/toolkit";

export const createTask = createAsyncThunk(
  "tasks/createTask",
  async (taskData) => {
    const response = await api.post("/tasks/", taskData);
    return response.data;
  }
);

export const fetchTasks = createAsyncThunk(
  "tasks/fetchTasks",
  async ({ startDate, endDate, skip = 0, limit = 100 } = {}) => {
    let url = `/tasks/?skip=${skip}&limit=${limit}`;
    if (startDate && endDate) {
      url += `&start_date=${startDate}&end_date=${endDate}`;
    }
    const response = await api.get(url);
    return response.data;
  }
);

export const fetchTasksTimeseries = createAsyncThunk(
  "tasks/fetchTasksTimeseries",
  async ({ timeWindow, resolution }) => {
    const response = await api.get(
      `/tasks/timeseries/?time_window=${timeWindow}&resolution=${resolution}`
    );
    return response.data;
  }
);
