import DeleteIcon from "@mui/icons-material/Delete";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  Divider,
  FormControl,
  IconButton,
  InputLabel,
  List,
  ListItem,
  MenuItem,
  Select,
  Step,
  StepLabel,
  Stepper,
  TextField,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";


const logEnumChanges = (source, oldEnum, newEnum) => {
  console.log(`Enum changed in ${source}:`);
  console.log('Old enum:', oldEnum);
  console.log('New enum:', newEnum);
  if (newEnum.length !== new Set(newEnum).size) {
    console.warn('WARNING: New enum contains duplicates!');
    console.log('Duplicate values:', newEnum.filter((item, index) => newEnum.indexOf(item) !== index));
  }
};


const AdvancedSettingsDialog = ({
  open,
  onClose,
  field,
  unassignedJinjaVariables,
}) => {
  const [localField, setLocalField] = useState(field);
  const [showTypeWarning, setShowTypeWarning] = useState(false);
  const [newEnumValue, setNewEnumValue] = useState("");
  const [enumDialogOpen, setEnumDialogOpen] = useState(false);

  useEffect(() => {
    setLocalField(field);
    if (field.enum) {
      setLocalField((prev) => ({
        ...prev,
        type: "enum",
      }));
    }
  }, [field]);

  const handleChange = (key, value) => {
    if (key === "type" && value !== localField.type) {
      if (hasNonDefaultSettings(localField)) {
        setShowTypeWarning(true);
      }
    }
    setLocalField((prev) => ({
      ...prev,
      [key]: value,
      ...(key === "type" && value === "enum" ? { enum: [] } : {}),
    }));
  };

  const handleEnumChange = (newEnumValues) => {
    logEnumChanges('AdvancedSettingsDialog', localField.enum, newEnumValues);
    const uniqueEnum = [...new Set(newEnumValues)];
    const cleanEnum = uniqueEnum.filter((value) => value.trim() !== "");
    setLocalField((prev) => ({
      ...prev,
      enum: cleanEnum,
      type: cleanEnum.length > 0 ? "string" : prev.type,
    }));
  };

  const handleAddEnum = () => {
    if (
      newEnumValue.trim() &&
      !localField.enum?.includes(newEnumValue.trim())
    ) {
      const newEnum = [...(localField.enum || []), newEnumValue.trim()];
      handleEnumChange(newEnum);
      setNewEnumValue("");
    }
  };

  const handleRemoveEnum = (indexToRemove) => {
    setLocalField((prevField) => {
      const newEnum = prevField.enum.filter(
        (_, index) => index !== indexToRemove
      );
      const uniqueEnum = [...new Set(newEnum)];
      const cleanEnum = uniqueEnum.filter((value) => value.trim() !== "");

      return {
        ...prevField,
        enum: cleanEnum,
        type: cleanEnum.length > 0 ? "string" : prevField.type,
      };
    });
  };

  const handleSave = () => {
    const fieldToSave = { ...localField };
    if (fieldToSave.enum) {
      fieldToSave.type = "string";
      fieldToSave.enum = [...new Set(fieldToSave.enum)].filter(
        (value) => value.trim() !== ""
      );
    }
    onClose(fieldToSave);
  };

  const handleUiOptionsChange = (key, value) => {
    setLocalField((prev) => ({
      ...prev,
      uiOptions: { ...prev.uiOptions, [key]: value },
    }));
  };

  const hasNonDefaultSettings = (field) => {
    const defaultSettings = {
      string: ["minLength", "maxLength", "pattern"],
      integer: ["minimum", "maximum", "multipleOf"],
      number: ["minimum", "maximum", "multipleOf"],
      enum: ["enum"],
    };

    const fieldType = field.enum ? "enum" : field.type;

    return (
      defaultSettings[fieldType]?.some(
        (setting) =>
          field[setting] !== undefined &&
          field[setting] !== null &&
          field[setting] !== ""
      ) ||
      (field.enum && field.enum.length > 0)
    );
  };

  const renderTypeSpecificSettings = () => {
    if (localField.enum) {
      return (
        <Box>
          <Button
            variant="outlined"
            onClick={() => setEnumDialogOpen(true)}
            sx={{ mb: 2 }}
          >
            Manage Enum Values
          </Button>
          <TextField
            fullWidth
            label="Default Value"
            value={localField.default || ""}
            onChange={(e) => handleChange("default", e.target.value)}
            margin="normal"
            select
          >
            {localField.enum?.map((value, index) => (
              <MenuItem key={index} value={value}>
                {value}
              </MenuItem>
            ))}
          </TextField>
        </Box>
      );
    }
    switch (localField.type) {
      case "string":
        return (
          <>
            <TextField
              fullWidth
              label="Default Value"
              value={localField.default || ""}
              onChange={(e) => handleChange("default", e.target.value)}
              margin="normal"
            />
            <TextField
              fullWidth
              label="Min Length"
              type="number"
              value={localField.minLength || ""}
              onChange={(e) =>
                handleChange("minLength", parseInt(e.target.value))
              }
              margin="normal"
            />
            <TextField
              fullWidth
              label="Max Length"
              type="number"
              value={localField.maxLength || ""}
              onChange={(e) =>
                handleChange("maxLength", parseInt(e.target.value))
              }
              margin="normal"
            />
            <TextField
              fullWidth
              label="Pattern"
              value={localField.pattern || ""}
              onChange={(e) => handleChange("pattern", e.target.value)}
              margin="normal"
              helperText="Regular expression pattern"
            />
          </>
        );
      case "integer":
      case "number":
        return (
          <>
            <TextField
              fullWidth
              label="Default Value"
              type="number"
              value={localField.default || ""}
              onChange={(e) =>
                handleChange("default", parseFloat(e.target.value))
              }
              margin="normal"
            />
            <TextField
              fullWidth
              label="Minimum"
              type="number"
              value={localField.minimum || ""}
              onChange={(e) =>
                handleChange("minimum", parseFloat(e.target.value))
              }
              margin="normal"
            />
            <TextField
              fullWidth
              label="Maximum"
              type="number"
              value={localField.maximum || ""}
              onChange={(e) =>
                handleChange("maximum", parseFloat(e.target.value))
              }
              margin="normal"
            />
            <TextField
              fullWidth
              label="Multiple Of"
              type="number"
              value={localField.multipleOf || ""}
              onChange={(e) =>
                handleChange("multipleOf", parseFloat(e.target.value))
              }
              margin="normal"
            />
          </>
        );
      default:
        return null;
    }
  };

  const renderUiOptions = () => {
    if (localField.enum) {
      return (
        <FormControl fullWidth margin="normal">
          <InputLabel>UI Widget</InputLabel>
          <Select
            value={localField.uiOptions["ui:widget"] || ""}
            onChange={(e) => handleUiOptionsChange("ui:widget", e.target.value)}
          >
            <MenuItem value="">Default</MenuItem>
            <MenuItem value="select">Select</MenuItem>
            <MenuItem value="radio">Radio</MenuItem>
            <MenuItem value="enumSlider">Enum Slider</MenuItem>
          </Select>
        </FormControl>
      );
    }

    switch (localField.type) {
      case "string":
        return (
          <FormControl fullWidth margin="normal">
            <InputLabel>UI Widget</InputLabel>
            <Select
              value={localField.uiOptions["ui:widget"] || ""}
              onChange={(e) =>
                handleUiOptionsChange("ui:widget", e.target.value)
              }
            >
              <MenuItem value="">Default</MenuItem>
              <MenuItem value="textarea">Textarea</MenuItem>
              <MenuItem value="password">Password</MenuItem>
              <MenuItem value="rich-text">Rich Text</MenuItem>
            </Select>
          </FormControl>
        );
      case "integer":
      case "number":
        return (
          <FormControl fullWidth margin="normal">
            <InputLabel>UI Widget</InputLabel>
            <Select
              value={localField.uiOptions["ui:widget"] || ""}
              onChange={(e) =>
                handleUiOptionsChange("ui:widget", e.target.value)
              }
            >
              <MenuItem value="">Default</MenuItem>
              <MenuItem value="updown">Up/Down</MenuItem>
              <MenuItem value="range">Range</MenuItem>
            </Select>
          </FormControl>
        );
      default:
        return null;
    }
  };

  const renderEnumDialog = () => (
    <Dialog
      open={enumDialogOpen}
      onClose={() => setEnumDialogOpen(false)}
      maxWidth="sm"
      fullWidth
    >
      <DialogTitle>Enum Values</DialogTitle>
      <DialogContent>
        <Box>
          <Typography variant="h6">Enum Values</Typography>
          <Box display="flex" alignItems="center" mb={2}>
            <TextField
              fullWidth
              label="Add Enum Value"
              value={newEnumValue}
              onChange={(e) => setNewEnumValue(e.target.value)}
              onKeyPress={(e) => {
                if (e.key === "Enter") {
                  handleAddEnum();
                }
              }}
            />
            <Button onClick={handleAddEnum}>Add</Button>
          </Box>
          {localField.enum?.length !== new Set(localField.enum).size && (
            <Alert severity="warning" sx={{ mb: 2 }}>
              Duplicate values detected. They will be automatically removed upon
              saving.
            </Alert>
          )}
          <List>
            {localField.enum?.map((value, index) => (
              <ListItem key={index}>
                <TextField
                  fullWidth
                  value={value}
                  onChange={(e) => {
                    const newEnum = [...localField.enum];
                    newEnum[index] = e.target.value;
                    handleEnumChange(newEnum);
                  }}
                />
                <IconButton onClick={() => handleRemoveEnum(index)}>
                  <DeleteIcon />
                </IconButton>
              </ListItem>
            ))}
          </List>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={() => setEnumDialogOpen(false)}>Cancel</Button>
        <Button onClick={handleSave} color="primary">
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );

  const handleStep = (step) => () => {
    setActiveStep(step);
  };

  const steps = [
    {
      label: "Metadata",
      content: (
        <>
          {/* <TextField
            fullWidth
            label="Field Name (Key)"
            value={localField.id}
            onChange={(e) => handleChange("id", e.target.value)}
            margin="normal"
            required
          /> */}
          <TextField
            fullWidth
            label="Title"
            value={localField.title}
            onChange={(e) => handleChange("title", e.target.value)}
            margin="normal"
          />
          <TextField
            fullWidth
            label="Description"
            value={localField.description || ""}
            onChange={(e) => handleChange("description", e.target.value)}
            margin="normal"
            multiline
            rows={2}
          />
        </>
      ),
    },
    {
      label: "Type",
      content: (
        <>
          <FormControl fullWidth margin="normal">
            <InputLabel>Type</InputLabel>
            <Select
              value={localField.enum ? "enum" : localField.type}
              onChange={(e) => {
                const newType = e.target.value;
                if (newType === "enum") {
                  handleChange("type", "enum");
                } else {
                  handleChange("type", newType);
                  handleChange("enum", undefined);
                }
              }}
            >
              <MenuItem value="string">String</MenuItem>
              <MenuItem value="integer">Integer</MenuItem>
              <MenuItem value="number">Number</MenuItem>
              <MenuItem value="enum">Enum</MenuItem>
            </Select>
          </FormControl>
          {showTypeWarning && (
            <Alert severity="warning" sx={{ mt: 2 }}>
              Changing the field type may reset some of the current settings.
            </Alert>
          )}
          <Divider sx={{ my: 2 }} />
          <Typography variant="subtitle1">Type-Specific Settings</Typography>
          {renderTypeSpecificSettings()}
        </>
      ),
    },
    {
      label: "UI",
      content: (
        <>
          <Typography variant="subtitle1">UI Options</Typography>
          {renderUiOptions()}
          <TextField
            fullWidth
            label="UI:Description"
            value={localField.uiOptions["ui:description"] || ""}
            onChange={(e) =>
              handleUiOptionsChange("ui:description", e.target.value)
            }
            margin="normal"
          />
          <TextField
            fullWidth
            label="UI:Placeholder"
            value={localField.uiOptions["ui:placeholder"] || ""}
            onChange={(e) =>
              handleUiOptionsChange("ui:placeholder", e.target.value)
            }
            margin="normal"
          />
        </>
      ),
    },
  ];

  const [activeStep, setActiveStep] = useState(0);

  const handleNext = () => {
    setActiveStep((prevActiveStep) => prevActiveStep + 1);
  };

  const handleBack = () => {
    setActiveStep((prevActiveStep) => prevActiveStep - 1);
  };

  const handleReset = () => {
    setActiveStep(0);
  };

  return (
    <Dialog open={open} onClose={() => onClose(null)} maxWidth="sm" fullWidth>
      <DialogTitle>Advanced Settings: {localField.title}</DialogTitle>
      <DialogContent>
        <Stepper activeStep={activeStep} sx={{ mb: 2 }} alternativeLabel>
          {steps.map((step, index) => (
            <Step key={step.label}>
              <StepLabel
                StepIconProps={{
                  sx: {
                    color: index === activeStep ? "primary.main" : "grey",
                    "& .MuiStepIcon-root": {
                      color: index === activeStep ? "primary.main" : "grey",
                    },
                    "& .MuiStepIcon-text": {
                      fontWeight: index === activeStep ? "bold" : "normal",
                    },
                  },
                }}
                sx={{
                  "& .MuiStepLabel-label": {
                    fontWeight: index === activeStep ? "bold" : "normal",
                  },
                }}
                onClick={handleStep(index)}
              >
                {step.label}
              </StepLabel>
            </Step>
          ))}
        </Stepper>
        {steps[activeStep].content}
      </DialogContent>
      <DialogActions>
        <Button onClick={() => onClose(null)}>Cancel</Button>
        <Button
          onClick={() => {
            const fieldToSave = { ...localField };
            if (fieldToSave.enum) {
              fieldToSave.type = "string";
            }
            onClose(fieldToSave);
          }}
          color="primary"
        >
          Save
        </Button>
      </DialogActions>
      {renderEnumDialog()}
    </Dialog>
  );
};

export default AdvancedSettingsDialog;
