import React, { useState } from "react";
import {
  AppBar,
  Toolbar,
  IconButton,
  Typography,
  Drawer,
  List,
  ListItem,
  ListItemText,
  useMediaQuery,
  useTheme,
  Button,
  Menu,
  MenuItem,
  Box,
  ButtonGroup,
  Popper,
  Grow,
  Paper,
  ClickAwayListener,
  MenuList,
  Divider,
} from "@mui/material";
import MenuIcon from "@mui/icons-material/Menu";
import AccountCircle from "@mui/icons-material/AccountCircle";
import AddIcon from "@mui/icons-material/Add";
import ArrowDropDownIcon from "@mui/icons-material/ArrowDropDown";
import { Link, useNavigate } from "react-router-dom";

const MenuItemWithDropdown = ({ item, openDropdown, onDropdownToggle }) => {
  const anchorRef = React.useRef(null);
  const isOpen = openDropdown === item.id;

  const handleToggle = () => {
    onDropdownToggle(item.id); // Toggle the dropdown
  };

  const handleClose = (event) => {
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    if (isOpen) {
      onDropdownToggle(null); // Close the dropdown if it's open
    }
  };

  return (
    <Box sx={{ display: "flex", alignItems: "center" }}>
      <Button
        color="inherit"
        component={Link}
        to={item.link}
        ref={anchorRef}
        aria-controls={isOpen ? "menu-list-grow" : undefined}
        aria-haspopup="true"
        onClick={() => {
          // Close dropdown when navigating
          if (isOpen) {
            onDropdownToggle(null);
          }
        }} // Handles navigation and closes dropdown if open
        sx={{ mx: 1, display: "flex", alignItems: "center" }} // Ensures proper alignment
      >
        {item.text}
      </Button>
      <IconButton
        onClick={(event) => {
          event.stopPropagation(); // Prevents triggering navigation
          handleToggle(); // Toggles dropdown
        }}
        aria-label="toggle menu"
        size="small"
        sx={{ ml: 1 }} // Space between text and dropdown icon
      >
        <ArrowDropDownIcon />
      </IconButton>
      <Popper
        open={isOpen}
        anchorEl={anchorRef.current}
        role={undefined}
        transition
        disablePortal
      >
        {({ TransitionProps, placement }) => (
          <Grow
            {...TransitionProps}
            style={{
              transformOrigin:
                placement === "bottom" ? "center top" : "center bottom",
            }}
          >
            <Paper>
              <ClickAwayListener onClickAway={handleClose}>
                <MenuList autoFocusItem={isOpen} id="menu-list-grow">
                  {item.quickLinks.map((quickLink, index) => (
                    <MenuItem
                      key={index}
                      onClick={() => {
                        handleClose(); // Close dropdown on menu item click
                      }}
                      component={Link}
                      to={quickLink.link}
                    >
                      {quickLink.text}
                    </MenuItem>
                  ))}
                </MenuList>
              </ClickAwayListener>
            </Paper>
          </Grow>
        )}
      </Popper>
    </Box>
  );
};

const ResponsiveAppBar = ({ onLogout, onSettingsClick }) => {
  const [mobileOpen, setMobileOpen] = useState(false);
  const [newTaskMenuAnchorEl, setNewTaskMenuAnchorEl] = useState(null);
  const [userMenuAnchorEl, setUserMenuAnchorEl] = useState(null);
  const [openDropdown, setOpenDropdown] = useState(null); // Manage currently open dropdown
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));
  const navigate = useNavigate();

  const handleDrawerToggle = () => {
    setMobileOpen(!mobileOpen);
  };

  const handleNewTask = () => {
    navigate("/app/task-templates");
  };

  const handleNewTaskMenuClick = (event) => {
    setNewTaskMenuAnchorEl(event.currentTarget);
  };

  const handleNewTaskMenuClose = () => {
    setNewTaskMenuAnchorEl(null);
  };

  const handleUserMenuClick = (event) => {
    setUserMenuAnchorEl(event.currentTarget);
  };

  const handleUserMenuClose = () => {
    setUserMenuAnchorEl(null);
  };

  const handleNewTaskOption = (option) => {
    handleNewTaskMenuClose();
    switch (option) {
      case "task-templates":
        navigate("/app/task-templates");
        break;
      case "document-chat":
        navigate("/app/chat");
        break;
      case "chat":
        navigate("/app/chat");
        break;
      default:
        navigate("/app/create-task");
    }
  };

  const handleDropdownToggle = (dropdownId) => {
    setOpenDropdown((prevId) => (prevId === dropdownId ? null : dropdownId)); // Toggle dropdown
  };

  const menuItems = [
    {
      id: "chat",
      text: "Chat",
      link: "/app/chat",
      quickLinks: [
        { text: "New Conversation", link: "/app/chat/new" },
        { text: "Saved Chats", link: "/app/chat" },
      ],
    },
    {
      id: "templates",
      text: "Templates",
      link: "/app/task-templates",
      quickLinks: [
        { text: "Browse Templates", link: "/app/task-templates/browse" },
        { text: "Create Template", link: "/app/task-templates/create" },
      ],
    },
    {
      id: "tasks",
      text: "Tasks",
      link: "/app/tasks",
      quickLinks: [
        { text: "Active Jobs", link: "/app/tasks/active" },
        { text: "Completed Jobs", link: "/app/tasks/completed" },
      ],
    },
    {
      id: "dashboard",
      text: "Dashboard",
      link: "/app/dashboard",
      quickLinks: [
        { text: "Overview", link: "/app/dashboard/overview" },
        { text: "Analytics", link: "/app/dashboard/analytics" },
      ],
    },
    {
      id: "workflows",
      text: "Workflows",
      link: "/app/workflow",
      quickLinks: [
        { text: "Manage", link: "/app/workflow" },
        { text: "Execute", link: "/app/workflow" },
      ],
    },
  ];

  const drawer = (
    <Box onClick={handleDrawerToggle} sx={{ textAlign: "center" }}>
      <List>
        <ListItem button component={Link} to="/app/chat">
          <ListItemText primary="Chat" />
        </ListItem>
        <Divider />
        <ListItem button onClick={onSettingsClick}>
          <ListItemText primary="Settings" />
        </ListItem>
        <ListItem button onClick={onLogout}>
          <ListItemText primary="Logout" />
        </ListItem>
      </List>
    </Box>
  );

  return (
    <>
      <AppBar position="fixed">
        <Toolbar>
          {isMobile && (
            <IconButton
              color="inherit"
              aria-label="open drawer"
              edge="start"
              onClick={handleDrawerToggle}
              sx={{ mr: 2 }}
            >
              <MenuIcon />
            </IconButton>
          )}
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            {window.location.hostname}
          </Typography>
          {!isMobile && (
            <>
              <ButtonGroup
                variant="contained"
                color="secondary"
                size="small"
                sx={{ mr: 2 }}
              >
                <Button startIcon={<AddIcon />} onClick={handleNewTask}>
                  New Task
                </Button>
                <Button size="small" onClick={handleNewTaskMenuClick}>
                  <ArrowDropDownIcon />
                </Button>
              </ButtonGroup>
              <IconButton
                size="large"
                aria-label="account of current user"
                aria-controls="menu-appbar"
                aria-haspopup="true"
                onClick={handleUserMenuClick}
                color="inherit"
              >
                <AccountCircle />
              </IconButton>
            </>
          )}
        </Toolbar>
        {!isMobile && (
          <Toolbar
            variant="dense"
            sx={{ backgroundColor: theme.palette.primary.dark }}
          >
            {menuItems.map((item) => (
              <MenuItemWithDropdown
                key={item.id}
                item={item}
                openDropdown={openDropdown}
                onDropdownToggle={handleDropdownToggle}
              />
            ))}
          </Toolbar>
        )}
      </AppBar>
      <Drawer
        variant="temporary"
        open={mobileOpen}
        onClose={handleDrawerToggle}
        ModalProps={{
          keepMounted: true,
        }}
        sx={{
          display: { xs: "block", sm: "none" },
          "& .MuiDrawer-paper": { boxSizing: "border-box", width: 240 },
        }}
      >
        {drawer}
      </Drawer>
      <Menu
        anchorEl={newTaskMenuAnchorEl}
        open={Boolean(newTaskMenuAnchorEl)}
        onClose={handleNewTaskMenuClose}
      >
        <MenuItem onClick={() => handleNewTaskOption("task-templates")}>
          Template
        </MenuItem>
        <MenuItem onClick={() => handleNewTaskOption("document-chat")}>
          Document Chat
        </MenuItem>
        <MenuItem onClick={() => handleNewTaskOption("chat")}>Chat</MenuItem>
      </Menu>
      <Menu
        anchorEl={userMenuAnchorEl}
        open={Boolean(userMenuAnchorEl)}
        onClose={handleUserMenuClose}
      >
        <MenuItem
          onClick={() => {
            handleUserMenuClose();
            onSettingsClick();
          }}
        >
          Settings
        </MenuItem>
        <MenuItem
          onClick={() => {
            handleUserMenuClose();
            onLogout();
          }}
        >
          Logout
        </MenuItem>
      </Menu>
    </>
  );
};

export default ResponsiveAppBar;
