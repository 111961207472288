import axios from "axios";
import { supabase } from "../../common/utils/supabase";

const api = axios.create({
  baseURL: process.env.REACT_APP_API_URL || "https://api.plot-twist.ai/api/v1",
});

// Add a request interceptor
api.interceptors.request.use(
  async (config) => {
    const session = await supabase.auth.getSession();
    if (session && session.data.session) {
      config.headers.Authorization = `Bearer ${session.data.session.access_token}`;
    }
    return config;
  },
  (error) => {
    return Promise.reject(error);
  }
);

// Add a response interceptor to handle token expiration
api.interceptors.response.use(
  (response) => response,
  async (error) => {
    const originalRequest = error.config;
    if (error.response.status === 401 && !originalRequest._retry) {
      originalRequest._retry = true;

      // Refresh the session
      const { data, error: refreshError } =
        await supabase.auth.refreshSession();

      if (refreshError) {
        // Handle refresh error (e.g., redirect to login)
        return Promise.reject(refreshError);
      }

      // Retry the original request with the new token
      if (data && data.session) {
        originalRequest.headers.Authorization = `Bearer ${data.session.access_token}`;
        return api(originalRequest);
      }
    }
    return Promise.reject(error);
  }
);

export default api;
