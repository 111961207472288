import React, {
  createContext,
  useState,
  useContext,
  useMemo,
  useEffect,
} from "react";
import {
  createTheme,
  ThemeProvider as MuiThemeProvider,
} from "@mui/material/styles";
import darkTheme from "../theme/darkTheme";
import lightTheme from "../theme/lightTheme";

const ThemeContext = createContext();

export const useTheme = () => useContext(ThemeContext);

export const CustomThemeProvider = ({ children }) => {
  const [mode, setMode] = useState(() => {
    const savedMode = localStorage.getItem("colorMode");
    return savedMode || "light";
  });

  const [colors, setColors] = useState({
    primary: "#3f51b5",
    secondary: "#f50057",
    background: "#fafafa",
  });

  const colorMode = useMemo(
    () => ({
      toggleColorMode: () => {
        setMode((prevMode) => {
          const newMode = prevMode === "light" ? "dark" : "light";
          localStorage.setItem("colorMode", newMode);
          return newMode;
        });
      },
    }),
    []
  );

  const theme = useMemo(() => {
    const baseTheme = mode === "light" ? lightTheme : darkTheme;
    return createTheme({
      ...baseTheme,
      palette: {
        ...baseTheme.palette,
        primary: {
          main: colors.primary,
        },
        secondary: {
          main: colors.secondary,
        },
        background: {
          ...baseTheme.palette.background,
          default: colors.background,
        },
        mode,
      },
    });
  }, [mode, colors]);

  const updateColor = (colorType, newColor) => {
    setColors((prevColors) => ({
      ...prevColors,
      [colorType]: newColor,
    }));
  };

  return (
    <ThemeContext.Provider value={{ colors, updateColor, mode, colorMode }}>
      <MuiThemeProvider theme={theme}>{children}</MuiThemeProvider>
    </ThemeContext.Provider>
  );
};
