export const extractJinjaVariables = (prompt) => {
  if (!prompt || typeof prompt !== "string") return [];
  const regex = /{{([^}]+)}}/g;
  const matches = prompt.match(regex) || [];
  return matches.map((match) => match.slice(2, -2).trim().toLowerCase());
};

const validateTemplate = (template) => {
  const errors = {};

  if (!template || typeof template !== "object") {
    return { general: "Invalid template structure" };
  }

  // Validate Metadata
  if (
    !template.title ||
    typeof template.title !== "string" ||
    template.title.trim() === ""
  ) {
    errors.title = "Title is required";
  }
  if (
    !template.description ||
    typeof template.description !== "string" ||
    template.description.trim() === ""
  ) {
    errors.description = "Description is required";
  }

  // Validate Prompt
  if (
    !template.prompt ||
    typeof template.prompt !== "string" ||
    template.prompt.trim() === ""
  ) {
    errors.prompt = "Prompt is required";
  }

  // Extract Jinja variables from prompt
  const jinjaVariables = extractJinjaVariables(template.prompt);

  // Validate Input
  if (!template.input || typeof template.input !== "object") {
    errors.input = "Invalid input structure";
  } else {
    const inputSchemaProperties = Object.keys(
      template.input.schema?.properties || {}
    );

    // Check if input_json_schema.properties has a key for each Jinja variable
    const missingVariables = jinjaVariables.filter(
      (variable) => !inputSchemaProperties.includes(variable)
    );
    if (missingVariables.length > 0) {
      errors.input = errors.input || {};
      errors.input.missingVariables = `Missing schema properties for Jinja variables: ${missingVariables.join(
        ", "
      )}`;
    }

    // Check for extra properties in JSON schema not in Jinja variables
    const extraProperties = inputSchemaProperties.filter(
      (prop) => !jinjaVariables.includes(prop)
    );
    if (extraProperties.length > 0) {
      errors.input = errors.input || {};
      errors.input.extraProperties = `Extra schema properties not in Jinja variables: ${extraProperties.join(
        ", "
      )}`;
    }

    // Validate that all UI schema keys exist in JSON schema
    if (
      template.input.uiSchema &&
      typeof template.input.uiSchema === "object"
    ) {
      const uiSchemaKeys = Object.keys(template.input.uiSchema).filter(
        (key) => key !== "ui:order" && !key.startsWith("ui:")
      );
      const invalidUiSchemaKeys = uiSchemaKeys.filter(
        (key) => !inputSchemaProperties.includes(key)
      );
      if (invalidUiSchemaKeys.length > 0) {
        errors.input = errors.input || {};
        errors.input.invalidUiSchemaKeys = `UI schema contains keys not in JSON schema: ${invalidUiSchemaKeys.join(
          ", "
        )}`;
      }
    }
  }

  // Validate Output
  if (!template.output || typeof template.output !== "object") {
    errors.output = "Invalid output structure";
  } else {
    const outputSchemaProperties = Object.keys(
      template.output.schema?.properties || {}
    );

    // Validate that all UI schema keys exist in JSON schema for output
    if (
      template.output.uiSchema &&
      typeof template.output.uiSchema === "object"
    ) {
      const outputUiSchemaKeys = Object.keys(template.output.uiSchema).filter(
        (key) => key !== "ui:order" && !key.startsWith("ui:")
      );
      const invalidOutputUiSchemaKeys = outputUiSchemaKeys.filter(
        (key) => !outputSchemaProperties.includes(key)
      );
      if (invalidOutputUiSchemaKeys.length > 0) {
        errors.output = errors.output || {};
        errors.output.invalidUiSchemaKeys = `Output UI schema contains keys not in JSON schema: ${invalidOutputUiSchemaKeys.join(
          ", "
        )}`;
      }
    }

    // Validate that output schema is not empty
    if (outputSchemaProperties.length === 0) {
      errors.output = errors.output || {};
      errors.output.emptySchema = "Output schema cannot be empty";
    }
  }

  return errors;
};

const validateField = (field) => {
  const errors = {};

  if (!field || typeof field !== "object") {
    return { general: "Invalid field structure" };
  }

  if (
    !field.title ||
    typeof field.title !== "string" ||
    field.title.trim() === ""
  ) {
    errors.title = "Title is required";
  }

  if (field.type === "string") {
    if (
      field.minLength != null &&
      field.maxLength != null &&
      field.minLength > field.maxLength
    ) {
      errors.length = "Minimum length cannot be greater than maximum length";
    }
  }

  if (field.type === "number") {
    if (
      field.minimum != null &&
      field.maximum != null &&
      field.minimum > field.maximum
    ) {
      errors.range = "Minimum value cannot be greater than maximum value";
    }
  }

  if (field.type === "array") {
    if (
      field.minItems != null &&
      field.maxItems != null &&
      field.minItems > field.maxItems
    ) {
      errors.items = "Minimum items cannot be greater than maximum items";
    }
  }

  return errors;
};

export { validateField, validateTemplate };
