import { createSlice } from "@reduxjs/toolkit";
import { fetchTasks, fetchTasksTimeseries } from "../services/taskService";

const taskSlice = createSlice({
  name: "tasks",
  initialState: {
    tasks: [],
    summary: null,
    timeseriesData: [],
    status: "idle",
    error: null,
  },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTasks.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTasks.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.tasks = action.payload.tasks || [];
        state.summary = action.payload.summary || null;
      })
      .addCase(fetchTasks.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(fetchTasksTimeseries.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTasksTimeseries.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.timeseriesData = action.payload;
      })
      .addCase(fetchTasksTimeseries.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      });
  },
});

export default taskSlice.reducer;
