import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import ErrorIcon from "@mui/icons-material/Error";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import { Step, StepLabel, Stepper, Tooltip, styled } from "@mui/material";
import React from "react";

const StyledStep = styled(Step)(({ theme }) => ({
  "& .MuiStepLabel-iconContainer": {
    paddingRight: theme.spacing(1),
  },
}));

const CustomStepIcon = ({ active, completed, error }) => {
  if (error) {
    return <ErrorIcon color="error" />;
  }
  if (completed) {
    return <CheckCircleIcon color="success" />;
  }
  if (active) {
    return <RadioButtonUncheckedIcon color="primary" />;
  }
  return <RadioButtonUncheckedIcon color="disabled" />;
};

const CustomStepper = ({ steps, activeStep, errors }) => {
  return (
    <Stepper activeStep={activeStep}>
      {steps.map((label, index) => {
        const stepErrors = errors[label.toLowerCase()];
        const hasError = stepErrors && Object.keys(stepErrors).length > 0;

        return (
          <StyledStep key={label}>
            <Tooltip
              title={
                hasError
                  ? Object.entries(stepErrors).map(([key, value]) => (
                      <div key={key}>
                        {key}: {value}
                      </div>
                    ))
                  : ""
              }
              arrow
            >
              <StepLabel
                StepIconComponent={(props) => (
                  <CustomStepIcon {...props} error={hasError} />
                )}
              >
                {label}
              </StepLabel>
            </Tooltip>
          </StyledStep>
        );
      })}
    </Stepper>
  );
};

export default CustomStepper;
