// src/features/login/AuthCallback.js

import { Box, Button, CircularProgress, Typography } from "@mui/material";
import React, { useEffect, useState } from "react";
import { useNavigate } from "react-router-dom";
import { supabase } from "../../common/utils/supabase";

const AuthCallback = () => {
  const navigate = useNavigate();
  const [error, setError] = useState(null);
  const [loading, setLoading] = useState(true);

  useEffect(() => {
    const handleAuthCallback = async () => {
      try {
        const { data, error } = await supabase.auth.getSession();

        if (error) throw error;

        if (data?.session) {
          // Session exists, navigate to dashboard
          navigate("/app/task-templates");
        } else {
          // No session found
          throw new Error("No session found. Please try logging in again.");
        }
      } catch (error) {
        console.error("Error during auth callback:", error.message);
        setError(error.message);
      } finally {
        setLoading(false);
      }
    };

    handleAuthCallback();
  }, [navigate]);

  const handleRetry = () => {
    navigate("/login");
  };

  if (loading) {
    return (
      <Box
        display="flex"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <CircularProgress />
      </Box>
    );
  }

  if (error) {
    return (
      <Box
        display="flex"
        flexDirection="column"
        justifyContent="center"
        alignItems="center"
        height="100vh"
      >
        <Typography color="error" gutterBottom>
          {error}
        </Typography>
        <Typography variant="body2" gutterBottom>
          This could be due to an unauthorized email domain or a temporary
          issue.
        </Typography>
        <Button variant="contained" color="primary" onClick={handleRetry}>
          Return to Login
        </Button>
      </Box>
    );
  }

  return null;
};

export default AuthCallback;
