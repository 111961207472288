import React from "react";
import { Typography, Paper, IconButton, Box } from "@mui/material";
import { Edit as EditIcon } from "@mui/icons-material";
import { styled } from "@mui/material/styles";

const MessageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  marginBottom: theme.spacing(2),
  width: "100%",
}));

const Message = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  borderRadius: "18px",
  backgroundColor: theme.palette.background.paper,
  width: "calc(100% - 48px)", // Subtract the width of the avatar (32px) plus its right margin (16px)
  marginLeft: "4px", // Width of the avatar (32px) plus its right margin (16px)
}));

const MessageContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
});

const MessageText = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontFamily: "'Tiempos Text', serif",
  fontSize: "16px",
}));

const MessageActions = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: theme.spacing(1),
}));

const Avatar = styled(Box)(({ theme }) => ({
  width: 32,
  height: 32,
  borderRadius: "50%",
  backgroundColor: theme.palette.primary.main,
  display: "flex",
  alignItems: "center",
  justifyContent: "center",
  marginRight: theme.spacing(1),
}));

const EditButton = styled(IconButton)(({ theme }) => ({
  padding: 4,
  color: theme.palette.text.secondary,
}));

const UserMessage = ({ message, onEdit }) => {
  return (
    <MessageContainer>
      <Avatar>
        <Typography variant="body2">SV</Typography>
      </Avatar>
      <Message elevation={1}>
        <MessageContent>
          <MessageText variant="body1">{message.text}</MessageText>
          <MessageActions>
            <EditButton size="small" onClick={() => onEdit(message)}>
              <EditIcon fontSize="small" />
            </EditButton>
          </MessageActions>
        </MessageContent>
      </Message>
    </MessageContainer>
  );
};

export default UserMessage;
