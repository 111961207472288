// src/features/login/components/GoogleSignInButton.js

import React, { useState } from "react";
import { Button, Snackbar } from "@mui/material";
import { Google as GoogleIcon } from "@mui/icons-material";
import { supabase } from "../../../common/utils/supabase";

const GoogleSignInButton = () => {
  const [error, setError] = useState(null);

  const handleGoogleSignIn = async () => {
    try {
      const { error } = await supabase.auth.signInWithOAuth({
        provider: "google",
        options: {
          redirectTo: `${window.location.origin}/auth/callback`,
          queryParams: {
            access_type: "offline",
            prompt: "consent",
          },
        },
      });

      if (error) throw error;
    } catch (error) {
      console.error("Error signing in with Google:", error.message);
      setError(
        "Sign-in failed. Please try again or contact support if the issue persists."
      );
    }
  };

  return (
    <>
      <Button
        variant="contained"
        startIcon={<GoogleIcon />}
        onClick={handleGoogleSignIn}
        fullWidth
        sx={{
          backgroundColor: "#4285F4",
          color: "white",
          "&:hover": {
            backgroundColor: "#357ae8",
          },
        }}
      >
        Sign in with Google
      </Button>
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        message={error}
      />
    </>
  );
};

export default GoogleSignInButton;
