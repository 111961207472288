// src/features/workflow/components/nodes/StartNode.js
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import { Box, Typography } from "@mui/material";
import React from "react";
import { Handle, Position } from "reactflow";

const StartNode = ({ data }) => {
  return (
    <Box
      sx={{
        background: "#fff",
        border: "1px solid #bbb",
        borderRadius: "12px",
        padding: "10px",
        width: "250px",
        textAlign: "left",
      }}
    >
      <Box sx={{ display: "flex", alignItems: "center", marginBottom: "10px" }}>
        <Box
          sx={{
            background: "#4285F4",
            borderRadius: "50%",
            width: "30px",
            height: "30px",
            display: "flex",
            justifyContent: "center",
            alignItems: "center",
            marginRight: "10px",
          }}
        >
          <PlayArrowIcon sx={{ color: "#fff" }} />
        </Box>
        <Typography variant="subtitle1" sx={{ fontWeight: "bold" }}>
          START
        </Typography>
      </Box>
      {data.fields &&
        data.fields.map((field, index) => (
          <Box
            key={index}
            sx={{ display: "flex", alignItems: "center", marginBottom: "5px" }}
          >
            <Typography
              variant="body2"
              sx={{ marginRight: "5px", color: "#666" }}
            >
              {field.icon}
            </Typography>
            <Typography variant="body2">{field.name}</Typography>
            {field.required && (
              <Typography
                variant="caption"
                sx={{ marginLeft: "5px", color: "#999" }}
              >
                REQUIRED
              </Typography>
            )}
          </Box>
        ))}
      <Handle
        type="source"
        position={Position.Right}
        style={{ top:20, background: "#4285F4" }}
      />
    </Box>
  );
};

export default StartNode;
