import { useCallback, useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import {
  fetchActiveTemplateVersion,
  fetchTaskTemplateById,
  fetchTaskTemplates,
  toggleTaskTemplateFavorite,
  updateTaskTemplateMetadata,
} from "../services/taskTemplateService";

export const useTaskTemplates = () => {
  const dispatch = useDispatch();
  const taskTemplates = useSelector(
    (state) => state.taskTemplates.taskTemplates
  );
  const status = useSelector((state) => state.taskTemplates.status);
  const error = useSelector((state) => state.taskTemplates.error);
  const [currentTemplate, setCurrentTemplate] = useState(null);
  const [activeVersion, setActiveVersion] = useState(null);
  const [templateError, setTemplateError] = useState(null);

  useEffect(() => {
    if (status === "idle") {
      console.log("Dispatching fetchTaskTemplates from hook");
      dispatch(fetchTaskTemplates());
    }
  }, [status, dispatch]);

  const toggleFavorite = useCallback(
    (templateId) => {
      dispatch(toggleTaskTemplateFavorite(templateId));
    },
    [dispatch]
  );

  const getTaskTemplate = useCallback(
    async (templateId) => {
      try {
        setTemplateError(null);
        const template = await dispatch(
          fetchTaskTemplateById(templateId)
        ).unwrap();
        setCurrentTemplate(template);

        const activeVersionData = await dispatch(
          fetchActiveTemplateVersion(templateId)
        ).unwrap();
        setActiveVersion(activeVersionData);

        return { template, activeVersion: activeVersionData };
      } catch (err) {
        console.error("Failed to fetch task template:", err);
        setTemplateError(err.message || "Failed to fetch task template");
        return null;
      }
    },
    [dispatch]
  );

  const updateTemplateMetadata = useCallback(
    async (templateId, metadata) => {
      try {
        const updatedTemplate = await dispatch(
          updateTaskTemplateMetadata({ templateId, metadata })
        ).unwrap();

        // Update the current template with the new metadata
        setCurrentTemplate((prevTemplate) => ({
          ...prevTemplate,
          ...updatedTemplate,
        }));

        // Fetch the updated template to ensure all data is in sync
        await getTaskTemplate(templateId);

        return updatedTemplate;
      } catch (err) {
        console.error("Failed to update template metadata:", err);
        throw err;
      }
    },
    [dispatch, getTaskTemplate]
  );

  return {
    taskTemplates,
    status,
    error,
    toggleFavorite,
    getTaskTemplate,
    currentTemplate,
    activeVersion,
    templateError,
    updateTemplateMetadata,
  };
};
