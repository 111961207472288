import React from "react";
import ReactDOM from "react-dom/client";
import { Provider } from "react-redux";
import App from "./App";
import { store } from "./store";

const root = ReactDOM.createRoot(document.getElementById("root"));
const renderApp = () => (
  <Provider store={store}>
    <App />
  </Provider>
);

if (process.env.NODE_ENV === "production") {
  root.render(<React.StrictMode>{renderApp()}</React.StrictMode>);
} else {
  root.render(renderApp());
}
