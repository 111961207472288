import AutoFixHighIcon from "@mui/icons-material/AutoFixHigh";
import CenterFocusStrongIcon from "@mui/icons-material/CenterFocusStrong";
import RedoIcon from "@mui/icons-material/Redo";
import UndoIcon from "@mui/icons-material/Undo";
import ZoomInIcon from "@mui/icons-material/ZoomIn";
import ZoomOutIcon from "@mui/icons-material/ZoomOut";
import { Box, IconButton, Slider, Tooltip, Typography } from "@mui/material";
import React from "react";
import { useReactFlow } from "reactflow";

const CustomControls = ({
  onAutoLayout,
  blockDistance,
  onBlockDistanceChange,
}) => {
  const { zoomIn, zoomOut, fitView } = useReactFlow();

  const handleSliderChange = (_, newValue) => {
    console.log("Slider value changed:", newValue);
    onBlockDistanceChange(newValue);
  };

  console.log(
    "CustomControls rendered. Current block distance:",
    blockDistance
  );

  return (
    <Box
      sx={{
        position: "absolute",
        left: 10,
        bottom: 10,
        zIndex: 4,
        display: "flex",
        flexDirection: "column",
        backgroundColor: "white",
        borderRadius: "4px",
        boxShadow: "0 0 2px 1px rgba(0, 0, 0, 0.1)",
        padding: "10px",
      }}
    >
      <Box sx={{ display: "flex", marginBottom: "10px" }}>
        <Tooltip title="Zoom In">
          <IconButton onClick={() => zoomIn()}>
            <ZoomInIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Zoom Out">
          <IconButton onClick={() => zoomOut()}>
            <ZoomOutIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Fit View">
          <IconButton onClick={() => fitView()}>
            <CenterFocusStrongIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Undo">
          <IconButton>
            <UndoIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Redo">
          <IconButton>
            <RedoIcon />
          </IconButton>
        </Tooltip>
        <Tooltip title="Auto Layout">
          <IconButton
            onClick={() => {
              console.log("Auto Layout button clicked");
              onAutoLayout();
            }}
          >
            <AutoFixHighIcon />
          </IconButton>
        </Tooltip>
      </Box>
      {/* <Box sx={{ width: "100%", paddingX: "10px" }}>
        <Typography id="block-distance-slider" gutterBottom>
          Block Distance: {blockDistance}px
        </Typography>
        <Slider
          value={blockDistance}
          onChange={handleSliderChange}
          onChangeCommitted={(event, newValue) => {
            console.log("Slider change committed:", newValue);
            onBlockDistanceChange(newValue);
          }}
          aria-labelledby="block-distance-slider"
          valueLabelDisplay="auto"
          step={50}
          marks
          min={100}
          max={500}
        />
      </Box> */}
    </Box>
  );
};

export default CustomControls;
