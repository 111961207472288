import {
  Box,
  FormControl,
  InputLabel,
  MenuItem,
  Select,
  Typography,
} from "@mui/material";
import React, { useEffect, useState } from "react";
import ReactDiffViewer from "react-diff-viewer";

const DiffView = ({ oldVersion, newVersion }) => {
  const [diffType, setDiffType] = useState("metadata");
  const [diffData, setDiffData] = useState({ oldValue: "", newValue: "" });

  useEffect(() => {
    console.log("Old Version:", oldVersion);
    console.log("New Version:", newVersion);
  }, [oldVersion, newVersion]);

  useEffect(() => {
    console.log("DiffView props:", { oldVersion, newVersion });
  }, [oldVersion, newVersion]);

  const stringifyAndSort = (obj) => {
    if (typeof obj !== "object" || obj === null) {
      return JSON.stringify(obj);
    }
  
    if (Array.isArray(obj)) {
      // If it's an array, we need to sort each element
      return JSON.stringify(
        obj.map((item) => (typeof item === "object" && item !== null 
          ? JSON.parse(stringifyAndSort(item)) 
          : item))
      , null, 2);
    }
  
    const sortedObj = Object.keys(obj)
      .sort()
      .reduce((result, key) => {
        result[key] = typeof obj[key] === "object" && obj[key] !== null 
          ? JSON.parse(stringifyAndSort(obj[key]))
          : obj[key];
        return result;
      }, {});
  
    return JSON.stringify(sortedObj, null, 2);
  };

  const getDiff = (type) => {
    console.log("Getting diff for type:", type);
    try {
      switch (type) {
        case "metadata":
          return {
            oldValue: stringifyAndSort({
              title: oldVersion.title,
              description: oldVersion.description,
            }),
            newValue: stringifyAndSort({
              title: newVersion.title,
              description: newVersion.description,
            }),
          };
        case "prompt":
          return {
            oldValue: oldVersion.prompt || "",
            newValue: newVersion.prompt || "",
          };
        case "input_json_schema":
          return {
            oldValue: stringifyAndSort(oldVersion.input.schema || {}),
            newValue: stringifyAndSort(newVersion.input.schema || {}),
          };
        case "input_ui_schema":
          return {
            oldValue: stringifyAndSort(oldVersion.input.uiSchema || {}),
            newValue: stringifyAndSort(newVersion.input.uiSchema || {}),
          };
        case "output_json_schema":
          return {
            oldValue: stringifyAndSort(oldVersion.output.schema || {}),
            newValue: stringifyAndSort(newVersion.output.schema || {}),
          };
        case "output_ui_schema":
          return {
            oldValue: stringifyAndSort(oldVersion.output.uiSchema || {}),
            newValue: stringifyAndSort(newVersion.output.uiSchema || {}),
          };
        default:
          return { oldValue: "", newValue: "" };
      }
    } catch (error) {
      console.error("Error getting diff:", error);
      return { oldValue: "Error", newValue: "Error" };
    }
  };

  useEffect(() => {
    const newDiffData = getDiff(diffType);
    console.log("New diff data:", newDiffData);
    setDiffData(newDiffData);
  }, [diffType, oldVersion, newVersion]);

  return (
    <Box>
      <FormControl fullWidth sx={{ mb: 2 }}>
        <InputLabel id="diff-type-label">Diff Type</InputLabel>
        <Select
          labelId="diff-type-label"
          value={diffType}
          onChange={(e) => setDiffType(e.target.value)}
          label="Diff Type"
        >
          <MenuItem value="metadata">Metadata</MenuItem>
          <MenuItem value="prompt">Prompt</MenuItem>
          <MenuItem value="input_json_schema">Input JSON Schema</MenuItem>
          <MenuItem value="input_ui_schema">Input UI Schema</MenuItem>
          <MenuItem value="output_json_schema">Output JSON Schema</MenuItem>
          <MenuItem value="output_ui_schema">Output UI Schema</MenuItem>
        </Select>
      </FormControl>
      <Typography variant="h6" gutterBottom>
        {diffType.replace(/_/g, " ").replace(/\b\w/g, (l) => l.toUpperCase())}{" "}
        Diff
      </Typography>
      <ReactDiffViewer
        oldValue={diffData.oldValue}
        newValue={diffData.newValue}
        splitView={true}
        showDiffOnly={false}
        compareMethod="diffWords"
      />
    </Box>
  );
};

export default DiffView;
