// src/features/workflow/workflowSlice.js
import { createSlice } from '@reduxjs/toolkit';

const initialState = {
  nodes: [],
  edges: [],
  selectedNodeId: null,
  workflowTitle: 'Untitled Workflow',
  isRunning: false,
  runHistory: [],
};

const workflowSlice = createSlice({
  name: 'workflow',
  initialState,
  reducers: {
    setNodes: (state, action) => {
      state.nodes = action.payload;
    },
    setEdges: (state, action) => {
      state.edges = action.payload;
    },
    setSelectedNodeId: (state, action) => {
      state.selectedNodeId = action.payload;
    },
    setWorkflowTitle: (state, action) => {
      state.workflowTitle = action.payload;
    },
    setIsRunning: (state, action) => {
      state.isRunning = action.payload;
    },
    addRunToHistory: (state, action) => {
      state.runHistory.unshift(action.payload);
    },
  },
});

export const { 
  setNodes, 
  setEdges, 
  setSelectedNodeId, 
  setWorkflowTitle, 
  setIsRunning, 
  addRunToHistory 
} = workflowSlice.actions;

export default workflowSlice.reducer;