import React, { useState } from "react";
import {
  Typography,
  Box,
  TextField,
  Button,
  Grid,
  Tab,
  Tabs,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { SketchPicker } from "react-color";
import { useTheme } from "../../../context/ThemeContext"; // Adjust the import path as necessary
import ImageColorPicker from "./ImageColorPicker"; // Adjust the import path as necessary

const Root = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const Section = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

const ColorPickerContainer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const ColorPreview = styled(Box)(({ color, theme }) => ({
  width: "100%",
  height: "50px",
  backgroundColor: color,
  marginBottom: theme.spacing(1),
  border: `1px solid ${theme.palette.divider}`,
  borderRadius: theme.shape.borderRadius,
}));

function AccountSettings() {
  const { colors, updateColor } = useTheme();
  const [localColors, setLocalColors] = useState(colors);
  const [companyName, setCompanyName] = useState("");
  const [adminEmail, setAdminEmail] = useState("");
  const [tabValue, setTabValue] = useState(0);
  const [selectedImageColor, setSelectedImageColor] = useState(null);

  const handleColorChange = (colorType) => (color) => {
    const newColor = color.hex;
    setLocalColors((prevColors) => ({
      ...prevColors,
      [colorType]: newColor,
    }));
    updateColor(colorType, newColor);
  };

  const handleImageColorChange = (color) => {
    setSelectedImageColor(color);
  };

  const handleSetColorFromImage = (colorType) => {
    if (selectedImageColor) {
      setLocalColors((prevColors) => ({
        ...prevColors,
        [colorType]: selectedImageColor,
      }));
      updateColor(colorType, selectedImageColor);
    }
  };

  const handleSave = () => {
    console.log("Saving account settings:", {
      companyName,
      adminEmail,
      colors: localColors,
    });
  };

  const handleTabChange = (event, newValue) => {
    setTabValue(newValue);
  };

  return (
    <Root>
      <Typography variant="h4" gutterBottom>
        Account Settings
      </Typography>
      <Section>
        <Typography variant="h6" gutterBottom>
          Admin Controls
        </Typography>
        <TextField
          fullWidth
          label="Company Name"
          variant="outlined"
          margin="normal"
          value={companyName}
          onChange={(e) => setCompanyName(e.target.value)}
        />
        <TextField
          fullWidth
          label="Admin Email"
          variant="outlined"
          margin="normal"
          value={adminEmail}
          onChange={(e) => setAdminEmail(e.target.value)}
        />
      </Section>
      <Section>
        <Typography variant="h6" gutterBottom>
          Color Scheme
        </Typography>
        <Tabs value={tabValue} onChange={handleTabChange}>
          <Tab label="Color Picker" />
          <Tab label="Image Color Picker" />
        </Tabs>
        <Box mt={2}>
          {tabValue === 0 && (
            <Grid container spacing={3}>
              {Object.entries(localColors).map(([colorType, colorValue]) => (
                <Grid item xs={12} sm={4} key={colorType}>
                  <Typography variant="subtitle1" gutterBottom>
                    {colorType.charAt(0).toUpperCase() + colorType.slice(1)}
                  </Typography>
                  <ColorPreview color={colorValue} />
                  <ColorPickerContainer>
                    <SketchPicker
                      color={colorValue}
                      onChangeComplete={handleColorChange(colorType)}
                    />
                  </ColorPickerContainer>
                </Grid>
              ))}
            </Grid>
          )}
          {tabValue === 1 && (
            <Box>
              <ImageColorPicker onColorChange={handleImageColorChange} />
              <Grid container spacing={3} mt={2}>
                {Object.entries(localColors).map(([colorType, colorValue]) => (
                  <Grid item xs={12} sm={4} key={colorType}>
                    <Typography variant="subtitle1" gutterBottom>
                      {colorType.charAt(0).toUpperCase() + colorType.slice(1)}
                    </Typography>
                    <ColorPreview color={colorValue} />
                    <Button
                      variant="outlined"
                      onClick={() => handleSetColorFromImage(colorType)}
                      fullWidth
                      disabled={!selectedImageColor}
                    >
                      Set {colorType} color
                    </Button>
                  </Grid>
                ))}
              </Grid>
            </Box>
          )}
        </Box>
      </Section>
      <Button
        variant="contained"
        color="primary"
        onClick={handleSave}
        style={{ marginTop: 16 }}
      >
        Save Changes
      </Button>
    </Root>
  );
}

export default AccountSettings;
