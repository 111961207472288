import React from "react";
import {
  Typography,
  Box,
  Select,
  MenuItem,
  FormControl,
  InputLabel,
  Switch,
  FormControlLabel,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import { useTheme } from "../../../context/ThemeContext"; // Update this import path as necessary

const Root = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(4),
}));

const Section = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(3),
}));

function AppearanceSettings() {
  const [font, setFont] = React.useState("");
  const [size, setSize] = React.useState("");
  const { mode, colorMode } = useTheme();

  return (
    <Root>
      <Typography variant="h4" gutterBottom>
        Appearance Settings
      </Typography>
      <Section>
        <FormControlLabel
          control={
            <Switch
              checked={mode === "dark"}
              onChange={colorMode.toggleColorMode}
              name="darkMode"
              color="primary"
            />
          }
          label="Dark Mode"
        />
      </Section>
      <Section>
        <FormControl fullWidth margin="normal">
          <InputLabel>Font</InputLabel>
          <Select
            value={font}
            onChange={(e) => setFont(e.target.value)}
            label="Font"
          >
            <MenuItem value="roboto">Roboto</MenuItem>
            <MenuItem value="openSans">Open Sans</MenuItem>
            <MenuItem value="lato">Lato</MenuItem>
          </Select>
        </FormControl>
        <FormControl fullWidth margin="normal">
          <InputLabel>Font Size</InputLabel>
          <Select
            value={size}
            onChange={(e) => setSize(e.target.value)}
            label="Font Size"
          >
            <MenuItem value="small">Small</MenuItem>
            <MenuItem value="medium">Medium</MenuItem>
            <MenuItem value="large">Large</MenuItem>
          </Select>
        </FormControl>
      </Section>
    </Root>
  );
}

export default AppearanceSettings;
