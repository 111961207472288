import * as MuiIcons from "@mui/icons-material";
import { Settings, Star, StarBorder } from "@mui/icons-material";
import {
  Box,
  Button,
  Card,
  CardContent,
  Checkbox,
  FormControlLabel,
  Grid,
  IconButton,
  TextField,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useEffect, useState } from "react";
import { useDispatch, useSelector } from "react-redux";
import { Link, useNavigate } from "react-router-dom";
import { useTaskTemplates } from "../hooks/useTaskTemplates";
import { fetchTaskTemplates } from "../services/taskTemplateService";
import { selectUserRole } from "../../../slices/userSlice"; 


const StyledCard = styled(Card)(({ theme }) => ({
  height: "100%",
  display: "flex",
  flexDirection: "column",
  cursor: "pointer",
  transition: "transform 0.2s",
  position: "relative",
  "&:hover": {
    transform: "scale(1.03)",
  },
}));

const StyledCardContent = styled(CardContent)(({ theme }) => ({
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  paddingTop: theme.spacing(6), // Increased top padding to accommodate icons
}));

const IconContainer = styled(Box)(({ theme }) => ({
  position: "absolute",
  top: theme.spacing(1),
  left: theme.spacing(1),
  right: theme.spacing(1),
  display: "flex",
  justifyContent: "space-between",
  alignItems: "center",
}));

const StyledIcon = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
  "& > svg": {
    fontSize: 24, // Set a consistent size for all icons
  },
}));

const StyledFavoriteButton = styled(IconButton)(({ theme }) => ({
  padding: theme.spacing(1),
  "& > svg": {
    fontSize: 24, // Match the size of the template icon
  },
}));

// Create a mapping of database icon names to MUI icon components
const iconMapping = Object.keys(MuiIcons).reduce((acc, key) => {
  acc[key.toLowerCase()] = MuiIcons[key];
  return acc;
}, {});

const TaskTemplates = () => {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const { taskTemplates, status, error, toggleFavorite } = useTaskTemplates();
  const [filteredTemplates, setFilteredTemplates] = useState([]);
  const [searchTerm, setSearchTerm] = useState("");
  const [showFavoritesOnly, setShowFavoritesOnly] = useState(false);
  const userRole = useSelector(selectUserRole);

  useEffect(() => {
    if (status === "idle") {
      dispatch(fetchTaskTemplates());
    }
  }, [status, dispatch]);

  useEffect(() => {
    const filtered = taskTemplates.filter((template) => {
      const matchesSearch =
        template.title.toLowerCase().includes(searchTerm.toLowerCase()) ||
        template.description.toLowerCase().includes(searchTerm.toLowerCase());
      const matchesFavorite = showFavoritesOnly ? template.is_favorite : true;
      return matchesSearch && matchesFavorite;
    });
    setFilteredTemplates(filtered);
  }, [taskTemplates, searchTerm, showFavoritesOnly]);

  const handleEditClick = (e, templateId) => {
    e.stopPropagation(); // Prevent the card click event from firing
    navigate(`/app/task-templates/editor/${templateId}`);
  };

  const handleTemplateClick = (template) => {
    navigate(`/app/task/${template.id}`);
  };

  const renderIcon = (iconName) => {
    const IconComponent = iconMapping[iconName.toLowerCase()] || MuiIcons.Help;
    return <IconComponent />;
  };

  return (
    <Box sx={{ width: "100%", p: 3 }}>
      {userRole === "admin" && (
        <Button
          component={Link}
          to="/app/task-templates/editor"
          variant="contained"
          color="primary"
          sx={{ mb: 2 }}
        >
          Create New Template
        </Button>
      )}
      <TextField
        label="Search templates"
        variant="outlined"
        fullWidth
        value={searchTerm}
        onChange={(e) => setSearchTerm(e.target.value)}
        sx={{ mb: 2 }}
      />
      <FormControlLabel
        control={
          <Checkbox
            checked={showFavoritesOnly}
            onChange={(e) => setShowFavoritesOnly(e.target.checked)}
            name="showFavoritesOnly"
          />
        }
        label="Show favorites only"
        sx={{ mb: 2 }}
      />

      <Grid container spacing={4}>
        {filteredTemplates.map((template) => (
          <Grid item key={template.id} xs={12} sm={6} md={4}>
            <StyledCard onClick={() => handleTemplateClick(template)}>
              <IconContainer>
                <StyledIcon>{renderIcon(template.icon)}</StyledIcon>
                <Box>
                  {userRole === "admin" && (
                    <StyledIcon
                      onClick={(e) => handleEditClick(e, template.id)}
                    >
                      <Settings />
                    </StyledIcon>
                  )}
                  <StyledFavoriteButton
                    onClick={(e) => {
                      e.stopPropagation();
                      toggleFavorite(template.id);
                    }}
                  >
                    {template.is_favorite ? (
                      <Star color="primary" />
                    ) : (
                      <StarBorder />
                    )}
                  </StyledFavoriteButton>
                </Box>
              </IconContainer>
              <StyledCardContent>
                <Typography variant="h6" component="h2" gutterBottom>
                  {template.title}
                </Typography>
                <Typography variant="body2" color="text.secondary">
                  {template.description}
                </Typography>
              </StyledCardContent>
            </StyledCard>
          </Grid>
        ))}
      </Grid>
    </Box>
  );
};

export default TaskTemplates;
