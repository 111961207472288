import { createTheme } from "@mui/material/styles";
import baseTheme from "./baseTheme";

const darkTheme = createTheme({
  ...baseTheme,
  palette: {
    mode: "dark",
    primary: {
      main: "#262c85",
    },
    secondary: {
      main: "#3235cd",
    },
    background: {
      default: "#121212", // Updated dark background color
      paper: "#1E1E1E",
    },
    text: {
      primary: "#FFFFFF",
      secondary: "#B0B0B0",
    },
  },
  components: {
    MuiCssBaseline: {
      styleOverrides: {
        body: {
          backgroundColor: "#121212", // Ensure body background is also set
        },
      },
    },
  },
});

export default darkTheme;
