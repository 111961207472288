import { createSlice, createAsyncThunk } from "@reduxjs/toolkit";
import api from "../common/utils/api";

export const fetchTaskTemplates = createAsyncThunk(
  "taskTemplates/fetchTaskTemplates",
  async () => {
    const response = await api.get("/task-templates/");
    return response.data;
  }
);

export const toggleTaskTemplateFavorite = createAsyncThunk(
  "taskTemplates/toggleTaskTemplateFavorite/",
  async (templateId, { getState }) => {
    const state = getState();
    const template = state.taskTemplates.taskTemplates.find(t => t.id === templateId);
    const newFavoriteStatus = !template.is_favorite;
    
    const response = await api.put(`/task-templates/${templateId}/favorite/`, {
      is_favorite: newFavoriteStatus,
    });
    return response.data;
  }
);

const taskTemplateSlice = createSlice({
  name: "taskTemplates",
  initialState: { taskTemplates: [], status: "idle", error: null },
  reducers: {},
  extraReducers: (builder) => {
    builder
      .addCase(fetchTaskTemplates.pending, (state) => {
        state.status = "loading";
      })
      .addCase(fetchTaskTemplates.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.taskTemplates = action.payload;
      })
      .addCase(fetchTaskTemplates.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.error.message;
      })
      .addCase(toggleTaskTemplateFavorite.fulfilled, (state, action) => {
        const index = state.taskTemplates.findIndex(
          (template) => template.id === action.payload.id
        );
        if (index !== -1) {
          state.taskTemplates[index] = action.payload;
        }
      });
  },
});

export default taskTemplateSlice.reducer;