import React, { useEffect } from "react";
import { useSelector, useDispatch } from "react-redux";
import { Navigate, Outlet } from "react-router-dom";
import { checkAuth } from "../slices/userSlice";
import { CircularProgress } from "@mui/material";

function ProtectedRoute() {
  const { isAuthenticated, status } = useSelector((state) => state.user);
  const dispatch = useDispatch();

  useEffect(() => {
    if (status === "idle") {
      dispatch(checkAuth());
    }
  }, [status, dispatch]);

  if (status === "loading") {
    return (
      <div style={{ display: "flex", justifyContent: "center", alignItems: "center", height: "100vh" }}>
        <CircularProgress />
      </div>
    );
  }

  return isAuthenticated ? <Outlet /> : <Navigate to="/login" />;
}

export default ProtectedRoute;