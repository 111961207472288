import React from "react";
import { Paper, IconButton, Box } from "@mui/material";
import { FileCopy, Replay, Edit } from "@mui/icons-material";
import { styled } from "@mui/material/styles";
import ReactMarkdown from "react-markdown";
import { Prism as SyntaxHighlighter } from "react-syntax-highlighter";
import { tomorrow } from "react-syntax-highlighter/dist/esm/styles/prism";

const MessageContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-start",
  marginBottom: theme.spacing(2),
  maxWidth: "100%", // Add this line
}));

const Message = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(1, 2),
  borderRadius: "18px",
  backgroundColor: theme.palette.background.paper,
  maxWidth: "100%", // Change this from 688px to 100%
  width: "100%", // Add this line
  overflow: "hidden", // Add this line
}));

const MessageContent = styled(Box)({
  display: "flex",
  flexDirection: "column",
  width: "100%", // Add this line
  overflow: "hidden", // Add this line
});

const MessageText = styled(Box)(({ theme }) => ({
  marginBottom: theme.spacing(1),
  fontFamily: "'Tiempos Text', serif",
  fontSize: "16px",
  width: "100%", // Add this line
  overflow: "hidden", // Add this line
  "& > *": {
    fontFamily: "'Tiempos Text', serif",
    fontSize: "16px",
    maxWidth: "100%", // Add this line
    overflow: "auto", // Change from overflow-x to overflow
  },
  "& code": {
    fontFamily: "monospace",
    fontSize: "14px",
    backgroundColor: theme.palette.background.default,
    padding: theme.spacing(0.5),
    borderRadius: 4,
    whiteSpace: "pre-wrap", // Add this line
    wordBreak: "break-word", // Add this line
  },
}));

const MessageActions = styled(Box)(({ theme }) => ({
  display: "flex",
  justifyContent: "flex-end",
  marginTop: theme.spacing(1),
}));

const ActionButton = styled(IconButton)(({ theme }) => ({
  padding: 4,
  color: theme.palette.text.secondary,
}));

const StreamingIndicator = styled("span")(({ theme }) => ({
  animation: "blink 1s linear infinite",
  "@keyframes blink": {
    "0%": { opacity: 1 },
    "50%": { opacity: 0 },
    "100%": { opacity: 1 },
  },
}));

const MarkdownComponents = {
  code: ({ node, inline, className, children, ...props }) => {
    const match = /language-(\w+)/.exec(className || "");
    return !inline && match ? (
      <div style={{ width: '100%', overflow: 'auto' }}>
        <SyntaxHighlighter
          style={tomorrow}
          language={match[1]}
          PreTag="div"
          customStyle={{
            fontFamily: "'Tiempos Text', Courier, monospace",
            fontSize: "14px",
            maxWidth: "100%", // Change this line
            width: "100%", // Add this line
            overflow: "auto", // Change from overflow-x to overflow
          }}
          {...props}
        >
          {String(children).replace(/\n$/, "")}
        </SyntaxHighlighter>
      </div>
    ) : (
      <code className={className} {...props}>
        {children}
      </code>
    );
  },
};

const stripMarkdownDelimiters = (text) => {
  let result = text;
  let markdownBlockStart = result.indexOf("```markdown");

  while (markdownBlockStart !== -1) {
    let stack = [];
    let searchStart = markdownBlockStart + 10; // length of "```markdown"
    let markdownBlockEnd = -1;

    while (searchStart < result.length) {
      let nextOpenTag = result.indexOf("```", searchStart);
      let nextCloseTag = result.indexOf("\n```\n", searchStart);

      if (nextOpenTag === -1 && nextCloseTag === -1) break;

      if (
        nextOpenTag !== -1 &&
        (nextCloseTag === -1 || nextOpenTag < nextCloseTag)
      ) {
        // Check if it's a valid opening tag (followed by a language specifier)
        let potentialOpenTag = result.slice(nextOpenTag, nextOpenTag + 20); // Arbitrary length to capture language
        if (/^```[a-z]+\n/.test(potentialOpenTag)) {
          // Found an opening tag
          stack.push(nextOpenTag);
          searchStart = nextOpenTag + potentialOpenTag.indexOf("\n") + 1;
        } else {
          // It's not a valid opening tag, move past it
          searchStart = nextOpenTag + 3;
        }
      } else {
        // Found a closing tag
        if (stack.length === 0) {
          // This is the closing tag for our markdown block
          markdownBlockEnd = nextCloseTag;
          break;
        }
        stack.pop();
        searchStart = nextCloseTag + 4; // length of "\n```\n"
      }
    }

    if (markdownBlockEnd !== -1) {
      // Remove the ```markdown and ``` delimiters
      let start = result.slice(0, markdownBlockStart);
      let mid = result.slice(markdownBlockStart + 11, markdownBlockEnd);
      let end = result.slice(markdownBlockEnd + 4); // +4 to remove "\n```\n"
      result = start + mid + end;

      // Look for the next ```markdown block
      markdownBlockStart = result.indexOf("```markdown");
    } else {
      // If we couldn't find a matching end, break to avoid an infinite loop
      break;
    }
  }

  return result;
};

const AssistantMessage = ({
  message,
  onCopy,
  onRetry,
  onRefine,
  isStreaming,
}) => {
  const processedText = stripMarkdownDelimiters(message.text);

  return (
    <MessageContainer>
      <Message elevation={1}>
        <MessageContent>
          <MessageText>
            <ReactMarkdown components={MarkdownComponents}>
              {processedText}
            </ReactMarkdown>
            {isStreaming && <StreamingIndicator>▋</StreamingIndicator>}
          </MessageText>
          <MessageActions>
            <ActionButton size="small" onClick={() => onCopy(message)}>
              <FileCopy fontSize="small" />
            </ActionButton>
            <ActionButton size="small" onClick={() => onRetry(message)}>
              <Replay fontSize="small" />
            </ActionButton>
            {!isStreaming && (
              <ActionButton size="small" onClick={() => onRefine(message)}>
                <Edit fontSize="small" />
              </ActionButton>
            )}
          </MessageActions>
        </MessageContent>
      </Message>
    </MessageContainer>
  );
};

export default React.memo(AssistantMessage);
