import { configureStore } from "@reduxjs/toolkit";
import chatReducer from "./features/chat/slices/chatSlice";
import jobReducer from "./features/jobs/slices/jobSlice";
import taskReducer from "./features/tasks/slices/taskSlice";
import taskTemplateReducer from "./features/taskTemplates/slices/taskTemplateSlice";
import workflowReducer from "./features/workflow/slices/workflowSlice";
import contentReducer from "./slices/contentSlice";
import templateReducer from "./slices/templateSlice";
import userReducer from "./slices/userSlice";

export const store = configureStore({
  reducer: {
    user: userReducer,
    content: contentReducer,
    templates: templateReducer,
    tasks: taskReducer,
    taskTemplates: taskTemplateReducer,
    chat: chatReducer,
    jobs: jobReducer,
    workflow: workflowReducer,
  },
});
