// src/features/workflow/components/WorkflowEditor.js
import { Box, Menu, MenuItem, Snackbar } from "@mui/material";
import dagre from "dagre";
import React, { useCallback, useEffect, useState } from "react";
import ReactFlow, {
  addEdge,
  Background,
  MiniMap,
  useEdgesState,
  useNodesState,
  useReactFlow,
} from "reactflow";
import "reactflow/dist/style.css";
import BlockDetails from "./BlockDetails";
import CustomControls from "./CustomControls";
import EndNode from "./nodes/EndNode";
import StartNode from "./nodes/StartNode";
import TaskNode from "./nodes/TaskNode";

import CustomEdge from "./CustomEdge";

const nodeTypes = {
  start: StartNode,
  task: TaskNode,
  end: EndNode,
};

const edgeTypes = {
  default: CustomEdge,
};

const initialNodes = [
  {
    id: "start-1",
    type: "start",
    position: { x: 0, y: 0 },
    data: {
      label: "START",
      fields: [],
    },
  },
  {
    id: "task-1",
    type: "task",
    position: { x: 250, y: 0 },
    data: {
      label: "Create blog post outline",
      fields: [
        { name: "Topic", icon: "📝" },
        { name: "Key talking points", icon: "📝" },
        { name: "Medium", icon: "📝" },
        { name: "Sections", icon: "#️⃣" },
      ],
    },
  },
  {
    id: "task-2",
    type: "task",
    position: { x: 500, y: 0 },
    data: {
      label: "Create blog post content",
      fields: [
        { name: "Blog post outline", icon: "🤖" },
        { name: "Style", icon: "📝" },
        { name: "Tone of voice", icon: "📝" },
        { name: "Complexity", icon: "📝" },
        { name: "Humor", icon: "📝" },
      ],
    },
  },
  {
    id: "task-3",
    type: "task",
    position: { x: 750, y: 0 },
    data: {
      label: "Geneate image(s)",
      fields: [
        { name: "Blog post content", icon: "🤖" },
        { name: "Style", icon: "📝" },
        { name: "Color scheme", icon: "📝" },
        { name: "Quantity", icon: "#️⃣" },
      ],
    },
  },
  {
    id: "task-4",
    type: "task",
    position: { x: 1000, y: 0 },
    data: {
      label: "Render blog post",
      fields: [
        { name: "Blog post content", icon: "🤖" },
        { name: "Selected image(s)", icon: "🤖" },
      ],
    },
  },
  {
    id: "end-1",
    type: "end",
    position: { x: 1250, y: 0 },
    data: {
      label: "END",
      fields: [],
    },
  },
];

const initialEdges = [
  { id: "e1-2", source: "start-1", target: "task-1" },
  { id: "e2-3", source: "task-1", target: "task-2" },
  { id: "e3-4", source: "task-2", target: "task-3" },
  { id: "e5-5", source: "task-3", target: "task-4" },
  { id: "e6-7", source: "task-4", target: "end-1" },
];

const WorkflowEditor = () => {
  const [nodes, setNodes, onNodesChange] = useNodesState(initialNodes);
  const [edges, setEdges, onEdgesChange] = useEdgesState(initialEdges);
  const [error, setError] = React.useState(null);
  const [contextMenu, setContextMenu] = React.useState(null);
  const [selectedNode, setSelectedNode] = React.useState(null);
  const reactFlowInstance = useReactFlow();
  const [initialLayoutApplied, setInitialLayoutApplied] = useState(false);

  const onConnect = useCallback(
    (params) => {
      //   console.log("Connection params:", params);

      setEdges((eds) => {
        // console.log("Current edges:", eds);

        // Remove old outgoing edge from the source node
        const filteredEdges = eds.filter(
          (e) =>
            e.source !== params.source || e.sourceHandle !== params.sourceHandle
        );
        // console.log(
        //   "Filtered edges after removing old outgoing edge:",
        //   filteredEdges
        // );

        // Remove old incoming edge to the target node
        const furtherFilteredEdges = filteredEdges.filter(
          (e) =>
            e.target !== params.target || e.targetHandle !== params.targetHandle
        );
        // console.log(
        //   "Filtered edges after removing old incoming edge:",
        //   furtherFilteredEdges
        // );

        // Add the new edge
        const updatedEdges = addEdge(params, furtherFilteredEdges);
        // console.log("Updated edges after adding new edge:", updatedEdges);

        return updatedEdges;
      });
    },
    [setEdges]
  );

  const onNodeContextMenu = useCallback((event, node) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      node: node,
    });
  }, []);

  const onPaneContextMenu = useCallback((event) => {
    event.preventDefault();
    setContextMenu({
      mouseX: event.clientX - 2,
      mouseY: event.clientY - 4,
      isPane: true,
    });
  }, []);

  const handleContextMenuClose = () => {
    setContextMenu(null);
  };

  const handleAddBlock = () => {
    const newNode = {
      id: `task-${Date.now()}`,
      type: "task",
      position: reactFlowInstance.project({
        x: contextMenu.mouseX,
        y: contextMenu.mouseY,
      }),
      data: { label: "New Task", fields: [] },
    };
    setNodes((nds) => nds.concat(newNode));
    handleContextMenuClose();
  };

  const handleDeleteBlock = () => {
    if (contextMenu?.node) {
      setNodes((nds) => nds.filter((n) => n.id !== contextMenu.node.id));
    }
    handleContextMenuClose();
  };

  const onNodeClick = useCallback((event, node) => {
    setSelectedNode(node);
  }, []);

  const [blockDistance, setBlockDistance] = useState(200); // Configurable distance between blocks

  useEffect(() => {
    // console.log("BlockDistance changed:", blockDistance);
  }, [blockDistance]);

  const onAutoLayout = useCallback(() => {
    // console.log(
    //   "Auto layout triggered. Current block distance:",
    //   blockDistance
    // );

    const dagreGraph = new dagre.graphlib.Graph();
    dagreGraph.setDefaultEdgeLabel(() => ({}));

    const nodeHeight = 36; // This can be a minimum height

    // Use nodesep for horizontal spacing between nodess
    dagreGraph.setGraph({
      rankdir: "LR",
      nodesep: blockDistance,
      ranksep: 50,
      align: "UL",
    });

    // console.log("Current nodes:", nodes);
    // console.log("Current edges:", edges);

    nodes.forEach((node) => {
      dagreGraph.setNode(node.id, {
        width: node.width,
        height: node.height || nodeHeight,
      });
    });

    edges.forEach((edge) => {
      dagreGraph.setEdge(edge.source, edge.target);
    });

    dagre.layout(dagreGraph);

    const layoutedNodes = nodes.map((node) => {
      const nodeWithPosition = dagreGraph.node(node.id);
      return {
        ...node,
        position: {
          x: nodeWithPosition.x - node.width / 2,
          y: 0, // Set all nodes to y: 0 to align them at the top
        },
      };
    });

    // console.log("Layouted nodes:", layoutedNodes);
    setNodes(layoutedNodes);
  }, [nodes, edges, setNodes, blockDistance]);

  const handleBlockDistanceChange = (newValue) => {
    // console.log("Block distance change requested:", newValue);
    setBlockDistance(newValue);
  };

  return (
    <Box sx={{ display: "flex", height: "calc(100vh - 112px)", width: "100%" }}>
      <Box sx={{ flexGrow: 1, position: "relative" }}>
        <ReactFlow
          nodes={nodes}
          edges={edges}
          edgeTypes={edgeTypes}
          onNodesChange={onNodesChange}
          onEdgesChange={onEdgesChange}
          onConnect={onConnect}
          onNodeContextMenu={onNodeContextMenu}
          onPaneContextMenu={onPaneContextMenu}
          onNodeClick={onNodeClick}
          nodeTypes={nodeTypes}
          fitView
        >
          <Background color="#aaa" gap={16} />
          <CustomControls
            onAutoLayout={onAutoLayout}
            blockDistance={blockDistance}
            onBlockDistanceChange={handleBlockDistanceChange}
          />
          <MiniMap />
        </ReactFlow>
        <Menu
          open={contextMenu !== null}
          onClose={handleContextMenuClose}
          anchorReference="anchorPosition"
          anchorPosition={
            contextMenu !== null
              ? { top: contextMenu.mouseY, left: contextMenu.mouseX }
              : undefined
          }
        >
          {contextMenu?.isPane ? (
            [
              <MenuItem key="addBlock" onClick={handleAddBlock}>
                Add Block
              </MenuItem>,
              <MenuItem key="autoLayout" onClick={onAutoLayout}>
                Auto Layout
              </MenuItem>,
            ]
          ) : (
            <MenuItem onClick={handleDeleteBlock}>Delete</MenuItem>
          )}
        </Menu>
      </Box>
      {selectedNode && (
        <BlockDetails
          node={selectedNode}
          onClose={() => setSelectedNode(null)}
        />
      )}
      <Snackbar
        open={!!error}
        autoHideDuration={6000}
        onClose={() => setError(null)}
        message={error}
      />
    </Box>
  );
};

export default WorkflowEditor;
