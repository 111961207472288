import CheckCircleIcon from "@mui/icons-material/CheckCircle";
import {
  Box,
  Button,
  CircularProgress,
  Dialog,
  DialogActions,
  DialogContent,
  DialogContentText,
  DialogTitle,
  Divider,
  FormControlLabel,
  Paper,
  Switch,
  Typography,
} from "@mui/material";
import { styled } from "@mui/material/styles";
import React, { useCallback, useEffect, useState } from "react";
import { useLocation, useNavigate, useParams } from "react-router-dom";
import api from "../../../common/utils/api";
import { useTaskTemplates } from "../../taskTemplates/hooks/useTaskTemplates";
import InputForm from "./InputForm";
import OutputView from "./OutputView";
import StatusOverlay from "./StatusOverlay";

const Root = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3),
  height: "calc(100vh - 100px)",
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
}));

const StyledPaper = styled(Paper)(({ theme }) => ({
  padding: theme.spacing(2),
  display: "flex",
  flexDirection: "column",
  height: "100%",
  overflow: "hidden",
}));

const Title = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(1),
}));

const Description = styled(Typography)(({ theme }) => ({
  marginBottom: theme.spacing(2),
  color: theme.palette.text.secondary,
  marginLeft: "0px",
}));

const StyledDivider = styled(Divider)(({ theme }) => ({
  margin: theme.spacing(2, 0),
}));

const ContentContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  flexGrow: 1,
  overflow: "hidden",
  minHeight: 0,
}));

const InputOutput = styled(Box)(({ theme }) => ({
  flex: 1,
  overflow: "hidden",
  display: "flex",
  flexDirection: "column",
  minWidth: 0,
}));

const Scrollable = styled(Box)(({ theme }) => ({
  overflowY: "auto",
  overflowX: "hidden",
  flexGrow: 1,
}));

const VerticalDivider = styled(Divider)(({ theme }) => ({
  width: 1,
  backgroundColor: theme.palette.divider,
  margin: theme.spacing(0, 2),
}));

const Footer = styled(Box)(({ theme }) => ({
  marginTop: theme.spacing(2),
  display: "flex",
  justifyContent: "space-between",
  color: theme.palette.text.secondary,
  fontSize: "0.875rem",
}));

const FlexEndContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end",
  justifyContent: "space-between", // This will push items to both ends
  height: "auto",
}));

const OutputContainer = styled(Box)(({ theme }) => ({
  position: "relative",
  flexGrow: 1,
  display: "flex",
  flexDirection: "column",
  overflow: "hidden",
}));

const OutputTitleContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "center",
  marginBottom: theme.spacing(2),
}));

const CompletedIcon = styled(CheckCircleIcon)(({ theme }) => ({
  marginLeft: theme.spacing(1),
  color: theme.palette.success.main,
}));

const TitleDescriptionContainer = styled(Box)(({ theme }) => ({
  display: "flex",
  alignItems: "flex-end"
}));

const LayoutToggle = styled(FormControlLabel)(({ theme }) => ({
  marginLeft: "auto", // This will push the toggle to the right
}));

const NewTaskButton = styled(Button)(({ theme }) => ({
  marginTop: theme.spacing(2),
}));

const TaskPage = () => {
  const { templateId, taskId } = useParams();
  const { getTaskTemplate, currentTemplate, templateError } =
    useTaskTemplates();
  const [inputData, setInputData] = useState(null);
  const [outputData, setOutputData] = useState(null);
  const location = useLocation();
  const [isLoading, setIsLoading] = useState(true);
  const [jobId, setJobId] = useState(taskId || null);
  const navigate = useNavigate();
  const [jobStatus, setJobStatus] = useState(null);
  const [isReadOnly, setIsReadOnly] = useState(!!taskId);
  const [executionDetails, setExecutionDetails] = useState({
    inputTokens: 0,
    outputTokens: 0,
    totalCost: 0,
    executionTime: 0,
  });
  const [openConfirmDialog, setOpenConfirmDialog] = useState(false);
  const [currentStatus, setCurrentStatus] = useState(null);
  const [completedStatuses, setCompletedStatuses] = useState([]);
  const [isCompactLayout, setIsCompactLayout] = useState(true);

  useEffect(() => {
    const fetchTemplate = async () => {
      setIsLoading(true);
      await getTaskTemplate(templateId);
      setIsLoading(false);
    };
    fetchTemplate();
  }, [templateId, getTaskTemplate]);

  const handleInputSubmit = useCallback(
    async (formData) => {
      setInputData(formData);
      setIsLoading(true);
      setOutputData(null);
      setJobStatus(null);
      setCurrentStatus(null);
      setCompletedStatuses([]);
      setExecutionDetails({
        inputTokens: 0,
        outputTokens: 0,
        totalCost: 0,
        executionTime: 0,
      });

      try {
        const response = await api.post(
          `/jobs/create-job/?task_template_id=${templateId}`,
          {
            task_template_id: templateId,
            input_data: formData,
            status: "pending",
          }
        );
        const newJobId = response.data.job_id;
        setJobId(newJobId);
        setJobStatus(response.data.status);

        // Start polling for job status
        pollJobStatus(newJobId);
      } catch (error) {
        console.error(
          "Error creating job:",
          error.response?.data || error.message
        );
        setIsLoading(false);
      }
    },
    [templateId]
  );

  const pollJobStatus = useCallback(async (jobId) => {
    const poll = async () => {
      try {
        const response = await api.get(`/jobs/job/${jobId}/`);
        setJobStatus(response.data.status);
        setCurrentStatus(response.data.status);
        setCompletedStatuses(response.data.completed_statuses || []);
        if (response.data.status === "completed") {
          setOutputData(response.data.output_data);
          setExecutionDetails({
            inputTokens: response.data.input_tokens ?? 0,
            outputTokens: response.data.output_tokens ?? 0,
            totalCost: response.data.cost ?? 0,
            executionTime: response.data.execution_time ?? 0,
          });
          setIsLoading(false);
          return true; // Stop polling
        } else if (response.data.status === "failed") {
          console.error("Job failed:", response.data.error);
          setIsLoading(false);
          return true; // Stop polling
        }
      } catch (error) {
        console.error(
          "Error fetching job status:",
          error.response?.data || error.message
        );
        setIsLoading(false);
        return true; // Stop polling on error
      }
      return false; // Continue polling
    };

    const pollInterval = setInterval(async () => {
      const shouldStop = await poll();
      console.log("Polling job status...");
      if (shouldStop) {
        clearInterval(pollInterval);
      }
    }, 500); // Poll every 0.5 seconds

    // Clean up interval on component unmount
    return () => clearInterval(pollInterval);
  }, []);

  useEffect(() => {
    if (taskId) {
      setJobId(taskId);
      setIsReadOnly(true);
      const fetchTask = async () => {
        try {
          const response = await api.get(`/jobs/job/${taskId}/`);
          setInputData(response.data.input_data);
          setOutputData(response.data.output_data);
          setJobStatus(response.data.status);
          setExecutionDetails({
            inputTokens: response.data.input_tokens ?? 0,
            outputTokens: response.data.output_tokens ?? 0,
            totalCost: response.data.cost ?? 0,
            executionTime: response.data.execution_time ?? 0,
          });
          setIsLoading(false);
        } catch (error) {
          console.error(
            "Error fetching task:",
            error.response?.data || error.message
          );
          setIsLoading(false);
        }
      };
      fetchTask();
    } else if (location.state && location.state.inputData) {
      // If we have input data from the previous task, use it
      setInputData(location.state.inputData);
      setOutputData(null); // Clear the output data
      setIsReadOnly(false);
      setIsLoading(false);
      setJobStatus(null);
      setExecutionDetails({
        inputTokens: 0,
        outputTokens: 0,
        totalCost: 0,
        executionTime: 0,
      });
    }
  }, [taskId, location.state, pollJobStatus]);

  if (isLoading && !currentTemplate) {
    return <CircularProgress />;
  }

  if (templateError) {
    return <Typography color="error">{templateError}</Typography>;
  }

  if (!currentTemplate) {
    return <Typography>No template found</Typography>;
  }

  const handleLayoutToggle = (event) => {
    setIsCompactLayout(event.target.checked);
  };

  const handleCreateNewTask = () => {
    setOpenConfirmDialog(true);
  };

  const handleConfirmNewTask = () => {
    setOpenConfirmDialog(false);
    navigate(`/app/task/${templateId}`, { state: { inputData } });
  };

  const handleCancelNewTask = () => {
    setOpenConfirmDialog(false);
  };

  return (
    <Root>
      <StyledPaper>
        <FlexEndContainer>
          <TitleDescriptionContainer>
            <Title variant="h4">{currentTemplate.title}</Title>
            <VerticalDivider orientation="vertical" flexItem />
            <Description variant="subtitle1">
              {currentTemplate.description}
            </Description>
          </TitleDescriptionContainer>
          <LayoutToggle
            control={
              <Switch
                checked={isCompactLayout}
                onChange={handleLayoutToggle}
                name="layoutToggle"
              />
            }
            label="Compact Layout"
          />
        </FlexEndContainer>
        <StyledDivider />
        <ContentContainer>
          <InputOutput>
            <Typography variant="h6" gutterBottom>
              Input
            </Typography>
            <InputForm
              schema={currentTemplate.input_json_schema}
              uiSchema={currentTemplate.input_ui_schema}
              formData={inputData || currentTemplate.input_sample_data}
              onSubmit={handleInputSubmit}
              readOnly={isReadOnly}
              isCompactLayout={isCompactLayout}
            />
            {isReadOnly && (
              <NewTaskButton
                variant="contained"
                color="primary"
                onClick={handleCreateNewTask}
              >
                Create New Task with This Input
              </NewTaskButton>
            )}
          </InputOutput>
          <VerticalDivider orientation="vertical" flexItem />
          <InputOutput>
            <OutputTitleContainer>
              <Typography variant="h6">Output</Typography>
              {jobStatus === "completed" && <CompletedIcon />}
            </OutputTitleContainer>
            <OutputContainer>
              {isLoading && (
                <StatusOverlay
                  currentStatus={currentStatus}
                  completedStatuses={completedStatuses}
                />
              )}
              <Scrollable>
                {outputData ? (
                  <OutputView
                    schema={currentTemplate.output_json_schema}
                    uiSchema={currentTemplate.output_ui_schema}
                    formData={outputData}
                  />
                ) : (
                  <Typography>No output data available</Typography>
                )}
              </Scrollable>
            </OutputContainer>
          </InputOutput>
        </ContentContainer>
        {/* <StyledDivider /> */}
        {/* <Footer>
          <span>Input Tokens: {executionDetails.inputTokens}</span>
          <span>Output Tokens: {executionDetails.outputTokens}</span>
          <span>Total Cost: ${executionDetails.totalCost.toFixed(3)}</span>
          <span>
            Execution Time: {executionDetails.executionTime.toFixed(2)}s
          </span>
        </Footer> */}
      </StyledPaper>
      <Dialog
        open={openConfirmDialog}
        onClose={handleCancelNewTask}
        aria-labelledby="alert-dialog-title"
        aria-describedby="alert-dialog-description"
      >
        <DialogTitle id="alert-dialog-title">{"Create New Task"}</DialogTitle>
        <DialogContent>
          <DialogContentText id="alert-dialog-description">
            This will create a new task with the same inputs. Are you sure you
            want to proceed?
          </DialogContentText>
        </DialogContent>
        <DialogActions>
          <Button onClick={handleCancelNewTask} color="primary">
            Cancel
          </Button>
          <Button onClick={handleConfirmNewTask} color="primary" autoFocus>
            Create New Task
          </Button>
        </DialogActions>
      </Dialog>
    </Root>
  );
};

export default TaskPage;
