import CloseIcon from "@mui/icons-material/Close";
import {
  Alert,
  Box,
  Button,
  Dialog,
  DialogActions,
  DialogContent,
  DialogTitle,
  IconButton,
  Typography,
} from "@mui/material";
import JSONEditor from "jsoneditor";
import "jsoneditor/dist/jsoneditor.css";
import React, { useEffect, useRef, useState } from "react";

const cleanEnumArray = (enumArray) => {
  return [...new Set(enumArray)].filter(
    (item) => item !== null && item !== undefined && item !== ""
  );
};

const JSONPreviewDialog = ({
  open,
  onClose,
  jsonSchema,
  uiSchema,
  formData,
  onSave,
}) => {
  const jsonSchemaRef = useRef(null);
  const uiSchemaRef = useRef(null);
  const formDataRef = useRef(null);
  const [editors, setEditors] = useState({
    jsonSchema: null,
    uiSchema: null,
    formData: null,
  });
  const [hasErrors, setHasErrors] = useState(false);
  const [errorMessages, setErrorMessages] = useState([]);

  useEffect(() => {
    let timeoutId;
    if (open) {
      timeoutId = setTimeout(() => {
        initializeEditors();
      }, 100);
    } else {
      cleanupEditors();
    }

    return () => {
      clearTimeout(timeoutId);
      cleanupEditors();
    };
  }, [open]);

  useEffect(() => {
    if (open && editors.jsonSchema && editors.uiSchema && editors.formData) {
      editors.jsonSchema.set(jsonSchema || {});
      editors.uiSchema.set(uiSchema || {});
      editors.formData.set(formData || {});
    }
  }, [jsonSchema, uiSchema, formData, open]);

  const initializeEditors = () => {
    const options = {
      mode: "code",
      onValidate: (json) => {
        let errors = [];

        if (Array.isArray(json)) {
          errors = json.filter((error) => error);
        } else if (json && typeof json === "object") {
          // Add custom validation for enum fields
          if (json.properties) {
            Object.keys(json.properties).forEach((key) => {
              const property = json.properties[key];
              if (property.enum && Array.isArray(property.enum)) {
                const cleanedEnum = cleanEnumArray(property.enum);
                if (cleanedEnum.length !== property.enum.length) {
                  errors.push({
                    path: [key, "enum"],
                    message:
                      "Enum array contains duplicate or empty values. They will be removed on save.",
                  });
                }
              }
            });
          }
        }

        setHasErrors(errors.length > 0);
        setErrorMessages(errors.map((error) => error.message));
      },
    };

    const newEditors = {};

    if (jsonSchemaRef.current) {
      newEditors.jsonSchema = new JSONEditor(jsonSchemaRef.current, options);
      newEditors.jsonSchema.set(jsonSchema || {});
    }

    if (uiSchemaRef.current) {
      newEditors.uiSchema = new JSONEditor(uiSchemaRef.current, options);
      newEditors.uiSchema.set(uiSchema || {});
    }

    if (formDataRef.current) {
      newEditors.formData = new JSONEditor(formDataRef.current, options);
      newEditors.formData.set(formData || {});
    }

    setEditors(newEditors);
  };

  const cleanupEditors = () => {
    Object.values(editors).forEach((editor) => editor && editor.destroy());
    setEditors({ jsonSchema: null, uiSchema: null, formData: null });
    setHasErrors(false);
    setErrorMessages([]);
  };

  const handleSave = () => {
    if (!hasErrors) {
      let updatedJsonSchema = editors.jsonSchema
        ? editors.jsonSchema.get()
        : jsonSchema;
      const updatedUiSchema = editors.uiSchema
        ? editors.uiSchema.get()
        : uiSchema;
      const updatedFormData = editors.formData
        ? editors.formData.get()
        : formData;

      // Process and clean up enum fields
      Object.keys(updatedJsonSchema.properties).forEach((key) => {
        const property = updatedJsonSchema.properties[key];
        if (property.enum && Array.isArray(property.enum)) {
          property.enum = cleanEnumArray(property.enum);
          property.type = "string";
        }
      });

      console.log("Saving changes:", {
        updatedJsonSchema,
        updatedUiSchema,
        updatedFormData,
      });
      onSave(updatedJsonSchema, updatedUiSchema, updatedFormData);
      onClose();
    }
  };

  return (
    <Dialog open={open} onClose={onClose} maxWidth="xl" fullWidth>
      <DialogTitle>
        JSON Preview
        <IconButton
          aria-label="close"
          onClick={onClose}
          sx={{ position: "absolute", right: 8, top: 8 }}
        >
          <CloseIcon />
        </IconButton>
      </DialogTitle>
      <DialogContent>
        {hasErrors && (
          <Alert severity="error">
            There are errors in the JSON data. Please fix them before saving.
            <ul>
              {errorMessages.map((message, index) => (
                <li key={index}>{message}</li>
              ))}
            </ul>
          </Alert>
        )}
        <Box display="flex" justifyContent="space-between" height="60vh">
          <Box width="32%" height="100%">
            <Typography variant="h6">JSON Schema</Typography>
            <div
              ref={jsonSchemaRef}
              style={{ height: "calc(100% - 40px)", border: "1px solid #ccc" }}
            />
          </Box>
          <Box width="32%" height="100%">
            <Typography variant="h6">UI Schema</Typography>
            <div
              ref={uiSchemaRef}
              style={{ height: "calc(100% - 40px)", border: "1px solid #ccc" }}
            />
          </Box>
          <Box width="32%" height="100%">
            <Typography variant="h6">Form Data</Typography>
            <div
              ref={formDataRef}
              style={{ height: "calc(100% - 40px)", border: "1px solid #ccc" }}
            />
          </Box>
        </Box>
      </DialogContent>
      <DialogActions>
        <Button onClick={onClose} color="primary">
          Cancel
        </Button>
        <Button onClick={handleSave} color="primary" disabled={hasErrors}>
          Save
        </Button>
      </DialogActions>
    </Dialog>
  );
};

export default JSONPreviewDialog;
