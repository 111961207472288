import { createTheme } from "@mui/material/styles";

const baseTheme = {
  typography: {
    fontFamily: '"Roboto", "Helvetica", "Arial", sans-serif',
    h1: {
      fontSize: "3rem",
      fontWeight: 700,
    },
    h2: {
      fontSize: "2.5rem",
      fontWeight: 600,
    },
    h3: {
      fontSize: "2rem",
      fontWeight: 600,
    },
    body1: {
      fontSize: "1rem",
    },
  },
  shape: {
    borderRadius: 8,
  },
  spacing: 8,
  components: {
    MuiAppBar: {
      defaultProps: {
        sx: {
          minHeight: 64,
          maxHeight: 64,
        },
      },
    },
    MuiToolbar: {
      defaultProps: {
        sx: {
          minHeight: 64,
          maxHeight: 64,
        },
      },
    },
  },
};

export default baseTheme;