import { createAsyncThunk, createSlice } from "@reduxjs/toolkit";
import { supabase } from "../common/utils/supabase";

const fetchUserRole = async (userId) => {
  const { data, error } = await supabase
    .from("user_profiles")
    .select("role")
    .eq("id", userId)
    .single();

  if (error) throw error;
  return data.role;
};

export const login = createAsyncThunk(
  "user/login",
  async ({ email, password }, { rejectWithValue }) => {
    try {
      const { data, error } = await supabase.auth.signInWithPassword({
        email,
        password,
      });

      if (error) throw error;

      // Fetch user role
      const role = await fetchUserRole(data.user.id);

      // Store the session in localStorage
      localStorage.setItem("session", JSON.stringify(data.session));

      return { ...data, role };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

export const checkAuth = createAsyncThunk(
  "user/checkAuth",
  async (_, { rejectWithValue }) => {
    try {
      // First, check if we have a session in localStorage
      const storedSession = localStorage.getItem("session");
      if (storedSession) {
        const session = JSON.parse(storedSession);
        // Verify the session with Supabase
        const { data } = await supabase.auth.getUser(session.access_token);
        if (data?.user) {
          const role = await fetchUserRole(data.user.id);
          return { ...data.user, role };
        }
      }

      // If no stored session or invalid, check with Supabase directly
      const {
        data: { user },
      } = await supabase.auth.getUser();
      if (!user) throw new Error("No user found");
      const role = await fetchUserRole(user.id);
      return { ...user, role };
    } catch (error) {
      return rejectWithValue(error.message);
    }
  }
);

const userSlice = createSlice({
  name: "user",
  initialState: {
    user: null,
    isAuthenticated: false,
    status: "idle",
    error: null,
  },
  reducers: {
    logout: (state) => {
      state.user = null;
      state.isAuthenticated = false;
      localStorage.removeItem("session");
      supabase.auth.signOut();
    },
  },
  extraReducers: (builder) => {
    builder
      .addCase(login.pending, (state) => {
        state.status = "loading";
      })
      .addCase(login.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = { ...action.payload.user, role: action.payload.role };
        state.isAuthenticated = true;
      })
      .addCase(login.rejected, (state, action) => {
        state.status = "failed";
        state.error = action.payload;
      })
      .addCase(checkAuth.pending, (state) => {
        state.status = "loading";
      })
      .addCase(checkAuth.fulfilled, (state, action) => {
        state.status = "succeeded";
        state.user = action.payload;
        state.isAuthenticated = true;
      })
      .addCase(checkAuth.rejected, (state, action) => {
        state.status = "failed";
        state.user = null;
        state.isAuthenticated = false;
        state.error = action.payload;
      });
  },
});

export const { logout } = userSlice.actions;

export const selectUserRole = (state) => state.user.user?.role;

export default userSlice.reducer;
