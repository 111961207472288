import React from "react";
import { styled } from "@mui/material/styles";
import {
  Box,
  GlobalStyles,
  useMediaQuery,
  useTheme,
} from "@mui/material";
import { useNavigate, Outlet } from "react-router-dom";
import { useDispatch } from "react-redux";
import { logout } from "../slices/userSlice";
import ResponsiveAppBar from "../features/appBar/components/ResponsiveAppBar";

const Root = styled("div")(({ theme }) => ({
  display: "flex",
  flexDirection: "column",
  minHeight: "100vh",
}));

const Content = styled(Box)(({ theme }) => ({
  flexGrow: 1,
  padding: theme.spacing(3,3,0,3),
  marginTop: 112, // Height of AppBar (64px) + Menu bar (48px)
  [theme.breakpoints.down("sm")]: {
    marginTop: 56, // Height of AppBar on mobile
    padding: theme.spacing(2),
  },
}));

const GlobalStylesComponent = () => (
  <GlobalStyles
    styles={{
      body: { margin: 0 },
      '#root': { display: 'flex', flexDirection: 'column', minHeight: '100vh' },
    }}
  />
);

function AppLayout() {
  const dispatch = useDispatch();
  const navigate = useNavigate();
  const theme = useTheme();
  const isMobile = useMediaQuery(theme.breakpoints.down("sm"));

  const handleLogout = () => {
    dispatch(logout());
    navigate("/login");
  };

  const handleSettingsClick = () => {
    navigate("/app/settings");
  };

  return (
    <Root>
      <GlobalStylesComponent />
      <ResponsiveAppBar 
        onLogout={handleLogout}
        onSettingsClick={handleSettingsClick}
      />
      <Content>
        <Outlet />
      </Content>
    </Root>
  );
}

export default AppLayout;