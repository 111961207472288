import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../common/utils/api";

export const fetchTaskTemplates = createAsyncThunk(
  "taskTemplates/fetchTaskTemplates",
  async () => {
    const response = await api.get("/task-templates/");
    return response.data;
  }
);

export const fetchTaskTemplateById = createAsyncThunk(
  "taskTemplates/fetchTaskTemplateById",
  async (templateId) => {
    const response = await api.get(`/task-templates/${templateId}/`);
    return response.data;
  }
);

export const fetchActiveTemplateVersion = createAsyncThunk(
  "taskTemplates/fetchActiveTemplateVersion",
  async (templateId) => {
    const response = await api.get(
      `/task-templates/${templateId}/active-version/`
    );
    return response.data;
  }
);

export const createTaskTemplate = createAsyncThunk(
  "taskTemplates/createTaskTemplate",
  async (templateData) => {
    const response = await api.post("/task-templates/", templateData);
    return response.data;
  }
);

export const updateTaskTemplate = createAsyncThunk(
  "taskTemplates/updateTaskTemplate",
  async ({ templateId, templateData }) => {
    const response = await api.put(
      `/task-templates/${templateId}/`,
      templateData
    );
    return response.data;
  }
);

export const toggleTaskTemplateFavorite = createAsyncThunk(
  "taskTemplates/toggleTaskTemplateFavorite",
  async (templateId, { getState }) => {
    const state = getState();
    const template = state.taskTemplates.taskTemplates.find(
      (t) => t.id === templateId
    );
    const newFavoriteStatus = !template.is_favorite;

    const response = await api.put(`/task-templates/${templateId}/favorite/`, {
      is_favorite: newFavoriteStatus,
    });
    return response.data;
  }
);

export const activateVersion = createAsyncThunk(
  "taskTemplates/activateVersion",
  async ({ templateId, versionId }) => {
    const response = await api.post(
      `/task-templates/${templateId}/versions/${versionId}/set-active`
    );
    return response.data;
  }
);

export const createNewVersion = createAsyncThunk(
  "taskTemplates/createNewVersion",
  async ({ templateId, versionData }) => {
    const response = await api.post(
      `/task-templates/${templateId}/versions/`,
      versionData
    );
    return response.data;
  }
);

export const createAndActivateVersion = createAsyncThunk(
  "taskTemplates/createAndActivateVersion",
  async ({ templateId, versionData }, { dispatch }) => {
    const newVersion = await dispatch(
      createNewVersion({ templateId, versionData })
    ).unwrap();
    await dispatch(
      activateVersion({ templateId, versionId: newVersion.id })
    ).unwrap();
    return newVersion;
  }
);

export const createTemplateWithInitialVersion = createAsyncThunk(
  "taskTemplates/createTemplateWithInitialVersion",
  async (templateData) => {
    const response = await api.post(
      "/task-templates/create_with_initial_version/",
      templateData
    );
    return response.data;
  }
);

export const updateTaskTemplateMetadata = createAsyncThunk(
  "taskTemplates/updateTaskTemplateMetadata",
  async ({ templateId, metadata }) => {
    const response = await api.put(
      `/task-templates/${templateId}/metadata`,
      metadata
    );
    return response.data;
  }
);
