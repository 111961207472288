// src/features/jobs/services/jobService.js

import { createAsyncThunk } from "@reduxjs/toolkit";
import api from "../../../common/utils/api";

export const fetchJobs = createAsyncThunk(
  "jobs/fetchJobs",
  async ({ page, rowsPerPage, status, startDate, endDate }) => {
    const params = new URLSearchParams({
      skip: page * rowsPerPage,
      limit: rowsPerPage,
    });

    if (status) params.append("status", status);
    if (startDate) params.append("start_date", startDate.toISOString());
    if (endDate) params.append("end_date", endDate.toISOString());

    const response = await api.get(`/jobs/?${params.toString()}`);
    return response.data; // This will now be an object with 'items' and 'total'
  }
);

export const fetchJobDetails = createAsyncThunk(
  "jobs/fetchJobDetails",
  async (jobId) => {
    const response = await api.get(`/jobs/${jobId}`);
    return response.data;
  }
);
